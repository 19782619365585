import { createReducer } from '@reduxjs/toolkit';

import * as STATUSES from '../constants/statuses';
import {
  fetchServerLocationStart,
  fetchServerLocationComplete,
  setServerLocation,
  setSelectedProtocol,
  setSecureUrls,
} from '../actions/advanced-settings';
import {
  ServerLocation,
  ServerLocationResponse,
} from '../network-interfaces/advanced-settings';
import { requestTest } from '../actions/request-test';

const { BUSY, EMPTY, ERROR, READY } = STATUSES;

export interface AdvancedSettingsState {
  statuses: {
    fetchServerLocation: {
      status: UnionOf<typeof STATUSES>;
      error: Error | null;
    };
  };
  previousServerLocation: string | null;
  selectedProtocol: string | null;
  selectedServerLocation: string | null;
  serverLocationData: ServerLocation | null;
  serverLocationSecureIPv4Url: string | null;
  serverLocationSecureIPv6Url: string | null;
  useAdvancedSettingsTestData: boolean;
}

const initialState: AdvancedSettingsState = {
  statuses: {
    fetchServerLocation: {
      status: EMPTY,
      error: null,
    },
  },
  previousServerLocation: null,
  selectedProtocol: null,
  selectedServerLocation: null,
  serverLocationData: null,
  serverLocationSecureIPv4Url: null,
  serverLocationSecureIPv6Url: null,
  useAdvancedSettingsTestData: false,
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(fetchServerLocationStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        fetchServerLocation: {
          ...state.statuses.fetchServerLocation,
          status: BUSY,
        },
      },
    }))
    .addCase(fetchServerLocationComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            fetchServerLocation: {
              status: ERROR,
              error: action.payload as Error, // If error is true, then the payload will only ever be Error
            },
          },
          previousServerLocation: null,
          selectedServerLocation: null,
          serverLocationData: null,
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          fetchServerLocation: {
            status: READY,
            error: null,
          },
        },
        serverLocationData: (action.payload as ServerLocationResponse)[0],
      };
    })
    .addCase(setServerLocation, (state, action) => ({
      ...state,
      previousServerLocation: action.payload.previousServerLocation,
      selectedServerLocation: action.payload.serverLocation,
    }))
    .addCase(setSelectedProtocol, (state, action) => ({
      ...state,
      selectedProtocol: action.payload.protocol,
    }))
    .addCase(setSecureUrls, (state, action) => ({
      ...state,
      serverLocationSecureIPv4Url: action.payload.serverLocationSecureIPv4Url,
      serverLocationSecureIPv6Url: action.payload.serverLocationSecureIPv6Url,
    }))
    .addCase(requestTest, (state, action) => {
      if (action.payload.useAdvancedSettingsData) {
        return { ...state, useAdvancedSettingsTestData: true };
      }
    })
);

export default reducer;
