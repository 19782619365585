import { createReducer } from '@reduxjs/toolkit';

import { AnnouncerType } from '../../components/AccessibilityAnnouncer/AccessibilityAnnouncer';
import * as errorTypes from '../constants/error-types';
import {
  makeScreenReaderAnnouncement,
  blurDeviceSearchInput,
  focusDeviceSearchInputCompleted,
  setDeviceSearchInputValue,
  setErrorType,
  showDeviceSearch,
  hideDeviceSearch,
  showTestingScreen,
  hideTestingScreen,
} from '../actions/ui';

interface UiState {
  announcement:
    | {
        message: any;
        politeness: any;
        type: AnnouncerType;
      }
    | {};
  deviceSearchInputIsFocused: boolean;
  deviceSearchInputValue: string;
  errorType: UnionOf<typeof errorTypes> | null;
  showDeviceSearch: boolean;
  showTestingScreen: boolean;
}

const initialState: UiState = {
  announcement: {},
  deviceSearchInputIsFocused: false,
  deviceSearchInputValue: '',
  errorType: null,
  showDeviceSearch: false,
  showTestingScreen: false,
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(makeScreenReaderAnnouncement, (state, action) => ({
      ...state,
      announcement: action.payload,
    }))
    .addCase(blurDeviceSearchInput, state => ({
      ...state,
      deviceSearchInputIsFocused: false,
    }))
    .addCase(focusDeviceSearchInputCompleted, state => ({
      ...state,
      deviceSearchInputIsFocused: true,
    }))
    .addCase(setDeviceSearchInputValue, (state, action) => ({
      ...state,
      deviceSearchInputValue: action.payload,
    }))
    .addCase(setErrorType, (state, action) => ({
      ...state,
      errorType: action.payload.errorType as UnionOf<typeof errorTypes>,
    }))
    .addCase(showDeviceSearch, state => ({
      ...state,
      showDeviceSearch: true,
    }))
    .addCase(hideDeviceSearch, state => ({
      ...state,
      showDeviceSearch: false,
    }))
    .addCase(showTestingScreen, state => ({
      ...state,
      showTestingScreen: true,
    }))
    .addCase(hideTestingScreen, state => ({
      ...state,
      showTestingScreen: false,
    }))
);

export default reducer;
