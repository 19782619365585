import React, { ReactNode } from 'react';

interface Props {
  term: string;
  description: ReactNode;
}

/**
 * This is a Description List Row. It's intended to be used inside the
 * description list element, `<dl>`. Each row contains a term (`<dt>`) and
 * a description for the term (`<dd>`).
 * For more information on `<dl>`, `<dt>`, and `<dd>`, see
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dl
 */
const DLRow = ({ term, description }: Props) => {
  return (
    <div className="flex justify-between pb-6">
      <dt>{term}</dt>
      <dd className="md:font-400">{description}</dd>
    </div>
  );
};

export default DLRow;
