export const A11Y_ANNOUNCER_FOCUS = 'A11Y_ANNOUNCER_FOCUS';
export const APP_FULLY_LOADED = 'APP_FULLY_LOADED';
export const APP_READY = 'APP_READY';

export const DEVICE_DETAILS_BY_FINGERPRINT_FETCH_COMPLETED =
  'DEVICE_DETAILS_BY_FINGERPRINT_FETCH_COMPLETED';
export const DEVICE_DETAILS_BY_FINGERPRINT_FETCH_STARTED =
  'DEVICE_DETAILS_BY_FINGERPRINT_FETCH_STARTED';

export const DEVICE_DETAILS_FETCH_COMPLETED = 'DEVICE_DETAILS_FETCH_COMPLETED';
export const DEVICE_DETAILS_FETCH_STARTED = 'DEVICE_DETAILS_FETCH_STARTED';

export const DEVICE_FINGERPRINT_FETCH_COMPLETED =
  'DEVICE_FINGERPRINT_FETCH_COMPLETED';
export const DEVICE_FINGERPRINT_FETCH_STARTED =
  'DEVICE_FINGERPRINT_FETCH_STARTED';

export const DEVICE_FINGERPRINT_POST_COMPLETED =
  'DEVICE_FINGERPRINT_POST_COMPLETED';
export const DEVICE_FINGERPRINT_POST_STARTED =
  'DEVICE_FINGERPRINT_POST_STARTED';

//Device Search
export const DEVICE_SEARCH_INPUT_BLUR = 'DEVICE_SEARCH_INPUT_BLUR';
export const DEVICE_SEARCH_SET_INPUT_FOCUS_STARTED =
  'DEVICE_SEARCH_SET_INPUT_FOCUS_STARTED';
export const DEVICE_SEARCH_SET_INPUT_FOCUS_COMPLETED =
  'DEVICE_SEARCH_SET_INPUT_FOCUS_COMPLETED';

export const DEVICE_SUGGESTIONS_FETCH_COMPLETED =
  'DEVICE_SUGGESTIONS_FETCH_COMPLETED';
export const DEVICE_SUGGESTIONS_FETCH_STARTED =
  'DEVICE_SUGGESTIONS_FETCH_STARTED';

export const LATENCYS_FETCH_COMPLETED = 'LATENCYS_FETCH_COMPLETED';
export const LATENCYS_FETCH_STARTED = 'LATENCYS_FETCH_STARTED';

export const MAKE_SCREEN_READER_ANNOUNCEMENT =
  'MAKE_SCREEN_READER_ANNOUNCEMENT';

export const MARK_PERFORMANCE = 'MARK_PERFORMANCE';
export const MARK_PULSE_LOADED = 'MARK_PULSE_LOADED';

export const PLAN_INFO_FETCH_COMPLETED = 'PLAN_INFO_FETCH_COMPLETED';
export const PLAN_INFO_FETCH_STARTED = 'PLAN_INFO_FETCH_STARTED';

export const REQUEST_APP_RELOAD = 'REQUEST_APP_RELOAD';
export const REQUEST_BACK_NAVIGATION = 'REQUEST_BACK_NAVIGATION';
export const REQUEST_ROUTE = 'REQUEST_ROUTE';
export const REQUEST_TEST = 'REQUEST_TEST';

export const SESSION_ID_FETCH_COMPLETED = 'SESSION_ID_FETCH_COMPLETED';
export const SESSION_ID_FETCH_STARTED = 'SESSION_ID_FETCH_STARTED';

export const SET_INITIAL_APP_CONFIG = 'SET_INITIAL_APP_CONFIG';

export const HIDE_DEVICE_SEARCH = 'HIDE_DEVICE_SEARCH';
export const SHOW_DEVICE_SEARCH = 'SHOW_DEVICE_SEARCH';

export const HIDE_TESTING_SCREEN = 'HIDE_TESTING_SCREEN';
export const SHOW_TESTING_SCREEN = 'SHOW_TESTING_SCREEN';

export const SET_DEVICE_SEARCH_INPUT_VALUE = 'SET_DEVICE_SEARCH_INPUT_VALUE';

export const SET_ERROR_TYPE = 'SET_ERROR_TYPE';

export const SPEED_TEST_ANIMATION_LOADED = 'SPEED_TEST_ANIMATION_LOADED';

export const SPEED_TEST_DATA_LOAD_COMPLETED = 'SPEED_TEST_DATA_LOAD_COMPLETED';
export const SPEED_TEST_DATA_LOAD_STARTED = 'SPEED_TEST_DATA_LOAD_STARTED';

export const SPEEDTEST__CLOSE_MODAL = '@@speedtest/SPEEDTEST__CLOSE_MODAL';
export const SPEEDTEST__OPEN_MODAL = '@@speedtest/SPEEDTEST__OPEN_MODAL';

export const TEST_CONFIG_POST_COMPLETED = 'TEST_CONFIG_POST_COMPLETED';
export const TEST_CONFIG_POST_STARTED = 'TEST_CONFIG_POST_STARTED';

export const TEST_PLANS_FETCH_COMPLETED = 'TEST_PLANS_FETCH_COMPLETED';
export const TEST_PLANS_FETCH_STARTED = 'TEST_PLANS_FETCH_STARTED';

export const TEST_COMPLETED = 'TEST_COMPLETED';
export const TEST_STARTED = 'TEST_STARTED';

export const TRACK_METRICS = 'TRACK_METRICS';

// Download Test
export const DOWNLOAD_TEST_COMPLETED = 'DOWNLOAD_TEST_COMPLETED';
export const DOWNLOAD_TEST_STARTED = 'DOWNLOAD_TEST_STARTED';

export const DOWNLOAD_TEST_DATA_COMPLETED = 'DOWNLOAD_TEST_DATA_COMPLETED';
export const DOWNLOAD_TEST_DATA_STARTED = 'DOWNLOAD_TEST_DATA_STARTED';

export const DOWNLOAD_TEST_DESKTOP_COMPLETED =
  'DOWNLOAD_TEST_DESKTOP_COMPLETED';
export const DOWNLOAD_TEST_DESKTOP_STARTED = 'DOWNLOAD_TEST_DESKTOP_STARTED';

export const DOWNLOAD_TEST_RESULTS_POST_COMPLETED =
  'DOWNLOAD_TEST_RESULTS_POST_COMPLETED';
export const DOWNLOAD_TEST_RESULTS_POST_STARTED =
  'DOWNLOAD_TEST_RESULTS_POST_STARTED';

export const DOWNLOAD_TEST_STATS_COMPLETED = 'DOWNLOAD_TEST_STATS_COMPLETED';
export const DOWNLOAD_TEST_STATS_STARTED = 'DOWNLOAD_TEST_STATS_STARTED';

export const TEST_DOWNLOAD_CURRENT_UPDATED = 'TEST_DOWNLOAD_CURRENT_UPDATED';

// Latency Test
export const HTTP_LATENCY_TEST_COMPLETED = 'HTTP_LATENCY_TEST_COMPLETED';
export const HTTP_LATENCY_TEST_STARTED = 'HTTP_LATENCY_TEST_STARTED';

export const LATENCY_TEST_COMPLETED = 'LATENCY_TEST_COMPLETED';
export const LATENCY_TEST_STARTED = 'LATENCY_TEST_STARTED';
export const REQUEST_LATENCY_TEST = 'REQUEST_LATENCY_TEST';

export const WS_LATENCY_TEST_COMPLETED = 'WS_LATENCY_TEST_COMPLETED';
export const WS_LATENCY_TEST_STARTED = 'WS_LATENCY_TEST_STARTED';

export const LATENCY_AND_UPLOAD_TEST_RESULTS_POST_COMPLETED =
  'LATENCY_AND_UPLOAD_TEST_RESULTS_POST_COMPLETED';
export const LATENCY_AND_UPLOAD_TEST_RESULTS_POST_STARTED =
  'LATENCY_AND_UPLOAD_TEST_RESULTS_POST_STARTED';

// Upload Test
export const UPLOAD_TEST_COMPLETED = 'UPLOAD_TEST_COMPLETED';
export const UPLOAD_TEST_STARTED = 'UPLOAD_TEST_STARTED';

export const TEST_UPLOAD_CURRENT_UPDATED = 'TEST_UPLOAD_CURRENT_UPDATED';

// Advanced Settings
export const SERVER_LOCATION_FETCH_COMPLETED =
  'SERVER_LOCATION_FETCH_COMPLETED';
export const SERVER_LOCATION_FETCH_STARTED = 'SERVER_LOCATION_FETCH_STARTED';
export const SET_SELECTED_PROTOCOL = 'SET_SELECTED_PROTOCOL';
export const SET_SECURE_URLS = 'SET_SECURE_URLS';
export const SET_SERVER_LOCATION = 'SET_SERVER_LOCATION';
