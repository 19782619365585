import { smartFetch } from './data-helpers';

export interface SessionIdResponse {
  id: string;
}

/**
 * Gets session ID from IPIE
 * @param {String} endpoint
 * @returns {() => Promise}
 */
export function fetchSessionId(endpoint: string) {
  return function() {
    return smartFetch<SessionIdResponse>(endpoint, {
      cache: 'no-cache',
    });
  };
}

export default {
  fetchSessionId,
};
