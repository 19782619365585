import { Route, ROUTES } from './api/constants/routes';

/**
 * Default app title
 */
export const SITE_TITLE = 'Xfinity Speed Test';

interface SiteMapConfig {
  selfCanonicalize?: boolean;
  title: string;
  description?: string;
  previewImage?: string;
  route: UnionOf<typeof ROUTES>;
}

export const SITE_MAP: Record<Route, SiteMapConfig> = {
  HOME: {
    title: 'home_title',
    description: 'home_description',
    previewImage: 'speedtest-preview.png',
    route: '/',
  },
  RESULTS: {
    title: 'result_title',
    route: '/results',
  },
  COMPARE_BY_CATEGORY: {
    title: 'compareByCategory_title',
    description: 'compareByCategory_description',
    selfCanonicalize: true,
    route: '/devicespeeds',
  },
  KNOWN_DEVICE: {
    title: 'knownDevice_title',
    route: '/devicespeeds/:id',
  },
  TIPS_AND_TOOLS: {
    title: 'tipsAndTools_title',
    description: 'tipsAndTools_description',
    selfCanonicalize: true,
    route: '/tips-and-tools',
  },
  DEVICE_VS_PLAN: {
    title: 'deviceVsPlanSpeed_title',
    description: 'deviceVsPlanSpeed_description',
    selfCanonicalize: true,
    route: '/tips-and-tools/device-vs-plan',
  },
  NON_SPEED_PROBLEMS: {
    title: 'nonSpeedProblems_title',
    description: 'nonSpeedProblems_description',
    selfCanonicalize: true,
    route: '/tips-and-tools/non-speed-problems',
  },
  IMPROVE_SPEED: {
    title: 'improveSpeed_title',
    description: 'improveSpeed_description',
    selfCanonicalize: true,
    route: '/tips-and-tools/improve-speed',
  },
  ERROR: {
    title: 'home_title',
    route: '/error',
  },
};
