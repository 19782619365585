// Third-party libraries
import { merge } from 'rxjs';
import { mergeMapTo, partition } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
// Our code
import { SPEED_TEST_DATA_LOAD_STARTED } from '../constants/action-types';
import { fetchDeviceFingerprintStart } from '../actions/device-fingerprint';
import { fetchSessionIdStart } from '../actions/session-id';
import { fetchTestPlansStart } from '../actions/test';
import { RootState, RootAction, EpicDependencies } from '../store-types';

/**
 * Initiates three data requests when the Redux
 * store has been created.
 *
 * Right after we create the store, we dispatch
 * SPEED_TEST_DATA_LOAD_STARTED. This will dispatch
 * the actions to start fetching
 * 1. the session id
 * 2. the device fingerprint
 * 3. the test plans
 *
 * @example
 *     SPEED_TEST_DATA_LOAD_STARTED = L
 * DEVICE_FINGERPRINT_FETCH_STARTED = D
 *         SESSION_ID_FETCH_STARTED = S
 *         TEST_PLANS_FETCH_STARTED = T
 *
 *  Input -L
 * Output -(DST)
 */
export const loadInitialData: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { window }) => {
  /* eslint-disable no-irregular-whitespace */
  const isSearchbot = window.navigator.userAgent.match(
    /googlebot|bingbot|yandex|baiduspider|twitterbot|duckduckbot|sogou spider|exabot|Ia_archiver|facebookexternalhit|rogerbot|linkedinbot|embedly|quora link preview|showyoubot|outbrain|pinterest\/0\.|pinterestbot|slackbot|vkShare|W3C_Validator|whatsapp/i
  );
  // partition returns a tuple of observables ([Observable, Observable]) instead of a single observable, which means it's not really pipeable and must be the last operator in the chain.
  // Bringing it out into its own call allows for proper type checking.
  // For more: https://github.com/ReactiveX/rxjs/issues/2995
  const dataLoadStartedAction$ = action$.pipe(
    ofType(SPEED_TEST_DATA_LOAD_STARTED)
  );
  const [searchbotData$, deviceData$] = partition(() => !!isSearchbot)(
    dataLoadStartedAction$
  );

  return merge(
    searchbotData$.pipe(
      mergeMapTo([fetchSessionIdStart(), fetchTestPlansStart()])
    ),
    deviceData$.pipe(
      mergeMapTo([
        fetchDeviceFingerprintStart(),
        fetchSessionIdStart(),
        fetchTestPlansStart(),
      ])
    )
  );
};
