import React from 'react';
import { Helmet } from 'react-helmet';
import { SITE_MAP } from '../../site-map';
import { Route } from '../../api/constants/routes';
import { useLocalization } from '../../hooks/localization';

export const SEO_ALLOWED_URL = 'https://speedtest.xfinity.com';

export const safeHostname = (hostname: string) => {
  switch (hostname) {
    // During prerendering, we want to ensure this is our allowed SEO URL.
    case 'http://localhost:8000':
    case 'http://localhost:7000':
    case 'http://local.xfinity.com:7000':
    case undefined:
    case '':
      return SEO_ALLOWED_URL;
    default:
      return hostname;
  }
};

const Head = ({ route: routeKey }: { route?: Route }) => {
  const t = useLocalization();
  const {
    title: titleKey,
    description = 'home_description',
    previewImage = 'speedtest-preview.png',
    selfCanonicalize = false,
    route,
  } = SITE_MAP[routeKey || 'HOME'];
  const title = t(titleKey);
  const origin = window.location.origin;
  const hostname = safeHostname(origin);
  const canonical = `${hostname}${selfCanonicalize ? route : ''}`;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={t(description)} />
      <link rel="alternate" href={canonical} hrefLang="en-us" />
      <link rel="canonical" href={canonical} />
      {/* Open Graph Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={t('siteTitle')} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonical} />
      <meta
        property="og:image"
        // Open Graph does not support relative paths
        content={`${hostname}/images/${previewImage}`}
      />
    </Helmet>
  );
};

export default Head;
