import { createAction } from '@reduxjs/toolkit';

import {
  PLAN_INFO_FETCH_STARTED,
  PLAN_INFO_FETCH_COMPLETED,
} from '../constants/action-types';
import { PlanInfoResponse } from '../network-interfaces/plan-info';

export const fetchPlanInfoStart = createAction(
  PLAN_INFO_FETCH_STARTED,
  (sessionId = '') => ({
    payload: {
      sessionId: sessionId,
    },
  })
);

export const fetchPlanInfoComplete = createAction(
  PLAN_INFO_FETCH_COMPLETED,
  (error: Error | null, response: PlanInfoResponse | null) => ({
    payload: error || response,
    error: Boolean(error),
  })
);
