import React from 'react';

interface Props {
  title: string;
  message: string;
}

const ResultsHeading = ({ title, message }: Props) => {
  return (
    <div className="max-w-525">
      <h2 className="pb-2 leading-none font-300 text-headline1">{title}</h2>
      <p className="text-neutral2-base text-body1">{message}</p>
    </div>
  );
};

export default ResultsHeading;
