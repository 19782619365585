import { version } from 'speed-testjs/package.json';

/*eslint sort-keys: "error"*/
export const desktopTestConfig = {
  CONTROLLER_NAME: 'speedtest.controller.js',
  DOWNLOAD_CURRENT_TESTS: 18,
  DOWNLOAD_MOVINGAVERAGE: 18,
  DOWNLOAD_PROGRESS_INTERVAL: 25,
  DOWNLOAD_SIZE: 200000000, // 200 MB
  DOWNLOAD_SLICE_END: 0.9,
  DOWNLOAD_SLICE_START: 0.3,
  DOWNLOAD_TIMEOUT: 15000,
  IPV6_TIMEOUT: 3000,
  IS_FULL_DESKTOP: true,
  IS_MOBILE_PROFILE: false,
  LATENCY_BASED_ROUTING_TIMEOUT: 3000,
  LATENCY_TIMEOUT: 3000,
  LOCATIONS_TIMEOUT: 3000,
  MAX_CONNECTIONS_PER_PORT: 6,
  MONITOR_INTERVAL: 1000,
  SPEED_TEST_JS_VERSION: version,
  TEST_SERVER_TIMEOUT: 2000,
  TEST_TRANSITION_DURATION: 500,
  UI_MOVING_AVERAGE: 10,
  UPLOAD_CURRENT_TESTS: 1,
  UPLOAD_MONITOR_INTERVAL: 400,
  UPLOAD_MOVINGAVERAGE: 18,
  UPLOAD_TEST_SIZE: 10000, // 10 KB
  UPLOAD_TIMEOUT: 12000,
  XHR_STATECOMPLETE: 4,
  XHR_STATUSSUCCESS: 200,
};

export const mobileTestConfig = {
  ...desktopTestConfig,
  DOWNLOAD_CURRENT_TESTS: 4,
  DOWNLOAD_SIZE: 20000000, // 20 MB
  DOWNLOAD_TIMEOUT: 10000,
  IS_FULL_DESKTOP: false,
  IS_MOBILE_PROFILE: true,
  MONITOR_INTERVAL: 100,
  UPLOAD_CURRENT_TESTS: 4,
  UPLOAD_TEST_SIZE: 50000, // 50 KB
};
