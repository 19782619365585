/**
 * smartFetch is a wrapper around the native Fetch API. smartFetch is invoked
 * exactly the same way that you'd invoke fetch.
 *
 * It was created for
 * 2 reasons:
 * 1. We want to handle non-200 status responses as if the request failed
 * 2. When responses are successful, we want to extract the response body
 *
 * fetch returns a promise that fulfills (as opposed to rejects)
 * as long as a response is received over the network. If
 * there is a client-side error (status code of 4**) or a server-side
 * error (status code of 5**) it treats it as a success. We would
 * like to treat any non-200 status as an error in order to make
 * Redux store updates more intuitive.
 *
 * In our Redux store, the slices of state pertaining to data fetched
 * over the network are only concerned with 2 things
 * 1. Did you successfully GET (or POST) the necessary data?
 * 2. If so, what is that data?
 *
 * Since we're primarily concerned with the body of responses,
 * we're going to do the work of unpacking the response body here.
 * We're assuming that the body is formatted as JSON. If it's not,
 * we're going to treat this request attempt as a failure, but store
 * any relevant data in the store for debugging purposes.
 *
 * @param {string} endpoint URL of the resource
 * @param {Object} options any settings you want to apply to request (method, headers, cache, etc.)
 * @returns {Promise}
 */
export const smartFetch = <T>(endpoint: string, options = {}) => {
  return fetch(endpoint, options).then(response => {
    if (response && response.ok) {
      return safeStreamJSON<T>(response);
    }

    return safeStreamJSON<T>(response).then(r => Promise.reject(r));
  });
};

/**
 * Accepts a collection of URL parameters to reduce to an object
 *
 * @param {Array} paramArray = collection of 'key=value' items
 * @returns {Object} array parsed to simple object 'key: value'
 */
export const convertParametersToObject = (paramArray: string[]) => {
  return paramArray.reduce((result: { [index: string]: string }, current) => {
    const pair = current.split('=').map(decodeURIComponent);

    if (pair.length && !result[pair[0]]) {
      result[pair[0]] = pair[1];
    }

    return result;
  }, {});
};

/**
 * Checks a given location for the value of a named hash or query parameter
 *
 * @param {Object} location - window.location
 * @param {String} paramName - name of the parameter to find
 * @param {Object} options - optional options Object to modify output of the method if needed
 * @returns {String} value of the first occurrence of the named parameter if found
 */
export const getFirstMatchedParameter = (
  _location: Window['location'],
  paramName: string,
  options?: { hashSeparator: string }
) => {
  const hashSeparator = (options && options.hashSeparator) || '&';
  const hashParams = _location.hash.replace('#', '').split(hashSeparator);
  const queryParams = _location.search.replace('?', '').split('&');
  const parameterObject = convertParametersToObject(
    ([] as string[]).concat(hashParams, queryParams)
  );
  let parameter: string | boolean = parameterObject[paramName];

  // coerce to boolean if parameter value is "true" or "false"
  if (parameter === 'true' || parameter === 'false') {
    parameter = parameter === 'true' ? true : false;
  }

  return parameter;
};

/**
 * queryParams was created to aid in creation of queryStrings from a hash
 *
 * It returns
 * empty string if no parameters are provided
 * if object is provided {marketName:'iPhone X', modelName: ''}
 * would give you marketName=iPhone X&modelName=
 *  e.g.
 *
 *
 * @param {Object} params - hash of key, value pairs which you want to have become query string parameters
 * @returns {string}
 */
export const queryParams = (params: { [index: string]: string } = {}) => {
  return Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&');
};

function safeStreamJSON<T>(response: Response): Promise<T> {
  if (response && response instanceof Response && !response.bodyUsed) {
    return response.text().then(text => {
      // to avoid rejection if text is falsey
      if (!text) return;
      try {
        return JSON.parse(text);
      } catch (e) {
        return Promise.reject({
          data: text,
          error: e,
        });
      }
    });
  }

  return Promise.reject({
    error: response || '"response" object is undefined',
  });
}
