import { ofType, Epic } from 'redux-observable';
import { of } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  withLatestFrom,
  tap,
  ignoreElements,
  distinctUntilChanged,
} from 'rxjs/operators';

import { fetchData, getEndpoints } from './epic-helpers';
import { SESSION_ID_FETCH_STARTED } from '../constants/action-types';
import {
  fetchSessionId,
  SessionIdResponse,
} from '../network-interfaces/session-id';
import { fetchSessionIdComplete } from '../actions/session-id';
import { RootState, RootAction, EpicDependencies } from '../store-types';
import { addDefault } from '../../modules/logger/logger';

function onFetchSuccess(response: SessionIdResponse) {
  return fetchSessionIdComplete(null, response);
}

function onFetchFailure(error: Error) {
  return fetchSessionIdComplete(error, null);
}

export const logSessionId: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) =>
  state$.pipe(
    map((state: RootState) => speedTestContext.getState(state).test.sessionId),
    distinctUntilChanged(),
    filter(sessionId => Boolean(sessionId)),
    tap(sessionId => addDefault({ platformSessionId: sessionId })),
    ignoreElements()
  );

export const fetchSessionIdEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(SESSION_ID_FETCH_STARTED),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    mergeMap(([action, endpoints]) =>
      of(action).pipe(
        fetchData(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          fetchSessionId(endpoints!.SESSION_ID_ENDPOINT),
          onFetchSuccess,
          onFetchFailure
        )
      )
    )
  );
};
