import { createAction } from '@reduxjs/toolkit';

import {
  DEVICE_SUGGESTIONS_FETCH_STARTED,
  DEVICE_SUGGESTIONS_FETCH_COMPLETED,
} from '../constants/action-types';
import { DeviceSuggestionsResponse } from '../network-interfaces/device-suggestions';

export const fetchDeviceSuggestionsStart = createAction(
  DEVICE_SUGGESTIONS_FETCH_STARTED,
  (text = '') => ({
    payload: {
      text: text,
    },
  })
);

export const fetchDeviceSuggestionsComplete = createAction(
  DEVICE_SUGGESTIONS_FETCH_COMPLETED,
  (error: Error | null, response: DeviceSuggestionsResponse | null) => ({
    type: DEVICE_SUGGESTIONS_FETCH_COMPLETED,
    payload: error || response,
    error: Boolean(error),
  })
);
