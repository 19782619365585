// Third-party Libraries
import { connect } from 'react-redux';
// Our Code
import { requestRoute } from '../api/actions/navigation';
import TipsAndToolsPage from '../pages/TipsAndTools/TipsAndToolsPage';
import { getCompareByCategoryLink } from '../api/selectors/view-state/device-search';
import { RootState } from '../api/store-types';

const mapStateToProps = (state: RootState) => ({
  compareByCategoryLink: getCompareByCategoryLink(state),
});

const actionCreators = { requestRoute };

export default connect(
  mapStateToProps,
  actionCreators
)(TipsAndToolsPage);
