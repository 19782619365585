// Our code
import * as analytics from './analytics';
import { fetchSessionIdEpic, logSessionId } from './session-id';
import {
  fetchTestPlansEpic,
  hideTestingScreenEpic,
  postTestConfigEpic,
  postDownloadTestResultsEpic,
  postLatencyAndUploadTestResultsEpic,
  scheduleLatencyAndUploadTestResultsPostEpic,
  schedulePostTestConfigEpic,
  schedulePostDownloadTestResultsEpic,
  scheduleTestCompleteEpic,
  scheduleUploadTestEpic,
  testInterruptedEpic,
  testResultsRedirect,
} from './test';
import * as downloadTest from './download-test';
import * as latencyTest from './latency-test';
import * as uploadTest from './upload-test';
import * as logger from './logger';
import {
  fetchDeviceDetailsEpic,
  fetchDeviceDetailsFailureEpic,
  fetchDeviceDetailsOnNavigationEpic,
  logDeviceDetailsEpic,
} from './device-details';
import * as deviceSuggestions from './device-suggestions';
import {
  fetchDeviceDetailsByFingerprintEpic,
  fetchDeviceFingerprintEpic,
  logDeviceFingerprintEpic,
  postDeviceFingerprintEpic,
  scheduleDeviceFingerprintPostEpic,
  scheduleFetchDeviceDetailsByFingerprintEpic,
} from './device-fingerprint';
import { fetchLatencysEpic, scheduleLatencysFetchEpic } from './latencys';
import * as navigation from './navigation';
import performanceLogs from './performance-logs';
import performanceMarks from './performance-marks';
import testLogs from './test-logs';
import { fetchPlanInfoEpic, schedulePlanInfoFetchEpic } from './plan-info';
import { executePulseActionsEpic } from './pulse';
import { requestTestEpic } from './request-test';
import { setFocusEpic, focusDeviceSearchInputEpic } from './ui';
import { loadInitialData } from './load-initial-data';
import { appBackgroundedChanged } from 'xfi-client-core/src/epics/session';
import { appFullyLoadedEpic, appReadyEpic } from './lifecycle';
import { errorLoggerEpic } from './error';
import {
  closeAdvancedSettingsModalEpic,
  createAdvancedSettingsTestUrlsEpic,
  fetchServerLocationEpic,
  setServerLocationEpic,
  setAdvancedSettingsDataEpic,
} from './advanced-settings';

export const epics = [
  analytics.logPageChange,
  appBackgroundedChanged,
  appFullyLoadedEpic,
  appReadyEpic,
  closeAdvancedSettingsModalEpic,
  createAdvancedSettingsTestUrlsEpic,
  deviceSuggestions.clearDeviceSearchScreenReaderAnnouncementEpic,
  deviceSuggestions.fetchDeviceSuggestionsEpic,
  deviceSuggestions.makeDeviceSearchScreenReaderAnnouncementEpic,
  downloadTest.generateDownloadDataEpic,
  downloadTest.generateDownloadStatsEpic,
  downloadTest.generateDownloadTestDesktopEpic,
  downloadTest.scheduleDownloadTestCompleteEpic,
  downloadTest.scheduleDownloadTestDataStartEpic,
  downloadTest.scheduleDownloadTestEpic,
  downloadTest.scheduleDownloadTestStatsStartEpic,
  errorLoggerEpic,
  executePulseActionsEpic,
  fetchDeviceDetailsByFingerprintEpic,
  fetchDeviceDetailsEpic,
  fetchDeviceDetailsFailureEpic,
  fetchDeviceDetailsOnNavigationEpic,
  fetchDeviceFingerprintEpic,
  fetchLatencysEpic,
  fetchPlanInfoEpic,
  fetchServerLocationEpic,
  fetchSessionIdEpic,
  fetchTestPlansEpic,
  hideTestingScreenEpic,
  latencyTest.requestLatencyTest,
  latencyTest.runHTTPLatencyTestEpic,
  latencyTest.runWSLatencyTestEpic,
  latencyTest.scheduleLatencyTest,
  latencyTest.scheduleLatencyTestComplete,
  loadInitialData,
  logger.initialize,
  logger.trackMetricsEpic,
  logDeviceDetailsEpic,
  logDeviceFingerprintEpic,
  logSessionId,
  navigation.noResultsRedirectEpic,
  navigation.requestAppReloadEpic,
  navigation.requestBackEpic,
  navigation.requestRouteEpic,
  navigation.scrollToTopEpic,
  navigation.setAnnouncementEpic,
  performanceLogs.logPerformanceEpic,
  performanceLogs.logSecondaryPerformanceEpic,
  performanceMarks.markAppFullyLoadedEpic,
  performanceMarks.markAppReadyEpic,
  performanceMarks.markDataCalls,
  performanceMarks.markPerformanceEpic,
  postDeviceFingerprintEpic,
  postDownloadTestResultsEpic,
  postLatencyAndUploadTestResultsEpic,
  postTestConfigEpic,
  requestTestEpic,
  scheduleDeviceFingerprintPostEpic,
  scheduleFetchDeviceDetailsByFingerprintEpic,
  scheduleLatencyAndUploadTestResultsPostEpic,
  scheduleLatencysFetchEpic,
  schedulePlanInfoFetchEpic,
  schedulePostDownloadTestResultsEpic,
  schedulePostTestConfigEpic,
  scheduleTestCompleteEpic,
  scheduleUploadTestEpic,
  setAdvancedSettingsDataEpic,
  setFocusEpic,
  focusDeviceSearchInputEpic,
  setServerLocationEpic,
  testInterruptedEpic,
  testLogs.logDownloadTestUserCompletionEpic,
  testLogs.logLatencyAndUploadTestCompletionEpic,
  testResultsRedirect,
  uploadTest.uploadTestEpic,
];
