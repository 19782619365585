// Third-party libraries
import { fromEvent, merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Epic } from 'redux-observable';
// Our code
import * as LOG_EVENTS from '../constants/log-events';
import { serializeError } from '../helpers/error';
import { trackMetrics } from '../actions/logger';
import { RootState, RootAction, EpicDependencies } from '../store-types';

export const errorLoggerEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { window }) => {
  const actionErrors$ = action$.pipe(
    filter(({ error }) => Boolean(error)),
    map(({ payload, type }) => {
      return {
        errorEventName: LOG_EVENTS.APP_ERROR,
        error: payload,
        eventContext: type,
      };
    })
  );
  const windowErrors$ = fromEvent(window, 'error').pipe(
    map(error => {
      return {
        errorEventName: LOG_EVENTS.JS_ERROR,
        error,
        eventContext: 'unhandled error',
      };
    })
  );
  return merge(actionErrors$, windowErrors$).pipe(
    map(({ errorEventName, error, eventContext }) =>
      trackMetrics(
        errorEventName,
        { error: serializeError(error) },
        eventContext
      )
    )
  );
};
