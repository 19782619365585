/**
 * @fileoverview Marks application loading performance events using `surfnperf` for later
 * calculation and logging of metrics. Requires `surnperf` as an Epic middleware dependency.
 */
// Third-party libraries
import { merge } from 'rxjs';
import {
  filter,
  first,
  tap,
  ignoreElements,
  map,
  mergeMap,
} from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
// Our code
import * as ACTION_TYPES from '../constants/action-types';
import * as PERF_MARKS from '../constants/performance-marks';
import { appReady } from '../actions/lifecycle';
import dataCallActionsToMarks from '../../modules/performance/perform-marks-config';
import { markPerformance } from '../actions/performance';
import { serializeError } from '../helpers/error';
import { RootState, RootAction, EpicDependencies } from '../store-types';

/**
 * Dispatches an action to mark when the app is fully loaded, including the whole page,
 * the page's dependent resources and all data calls.
 *
 * @param {Object} action$ Observable stream of actions
 * @returns {Object} Observable stream of actions
 */
export const markAppFullyLoadedEpic: Epic<RootAction, RootAction> = action$ => {
  return action$.pipe(
    ofType(ACTION_TYPES.APP_FULLY_LOADED),
    first(),
    map(() => markPerformance(PERF_MARKS.APP_FULLY_LOADED_MARK))
  );
};

/**
 * When MARK_PERFORMANCE is dispatched, uses `surfnperf` to mark performance
 * events with the provided `eventKey`.
 *
 * @param {Object} action$ Observable stream of actions
 * @param {Object} state$ [NOT USED] Observable stream of state changes
 * @param {Object} dependencies Injected dependencies
 * @param {Object} dependencies.surfnperf `surfnperf` module
 * @returns {Object} Observable stream of actions
 */
export const markPerformanceEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { surfnperf }) => {
  return action$.pipe(
    ofType(ACTION_TYPES.MARK_PERFORMANCE),
    tap((action: ReturnType<typeof markPerformance>) => {
      const { customMarkPayload, eventKey } = action.payload;

      if (eventKey && !surfnperf.getMark(eventKey)) {
        if (customMarkPayload) {
          surfnperf.setCustom(eventKey, customMarkPayload);
        } else {
          surfnperf.mark(eventKey);
        }
      }
    }),
    ignoreElements()
  );
};

/**
 * Dispatches an action to mark when the app is determined to be usable or the
 * critical data call fails. If there is an error, it marks the time of
 * failure and stores error information.
 * @param {Object} action$ Observable stream of actions
 * @returns {Object} Observable stream of actions
 */
export const markAppReadyEpic: Epic<RootAction, RootAction> = action$ => {
  return action$.pipe(
    ofType(ACTION_TYPES.APP_READY),
    first(),
    // mergeMap lets us map to an array with any number of actions to dispatch
    mergeMap((action: ReturnType<typeof appReady>) => {
      const { error, payload } = action;

      if (error) {
        return [
          markPerformance(PERF_MARKS.APP_FAILURE_MARK),
          markPerformance(PERF_MARKS.ERROR_MARK, serializeError(payload)),
        ];
      }

      return [markPerformance(PERF_MARKS.APP_READY_MARK)];
    })
  );
};

/**
 * Dispatches actions to mark when data calls start and complete.
 *
 * @param {Object} action$ Observable stream of actions
 * @returns {Object} Observable stream of actions
 */
export const markDataCalls: Epic<RootAction, RootAction> = action$ => {
  /**
   * Array of Observables that each listen for the first dispatch of a specific
   * data call Action
   */
  const dataCallActionStreams = Array.from(dataCallActionsToMarks.keys()).map(
    actionType =>
      action$.pipe(
        ofType(actionType),
        first()
      )
  );

  return merge(...dataCallActionStreams).pipe(
    filter(({ type: actionType }: { type: UnionOf<typeof ACTION_TYPES> }) =>
      dataCallActionsToMarks.has(actionType)
    ),
    map(({ type: actionType }: { type: UnionOf<typeof ACTION_TYPES> }) =>
      // Narrowed in the filter
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      markPerformance(dataCallActionsToMarks.get(actionType)!)
    )
  );
};

export default {
  markAppFullyLoadedEpic,
  markAppReadyEpic,
  markDataCalls,
  markPerformanceEpic,
};
