import { smartFetch } from './data-helpers';
import { desktopTestConfig, mobileTestConfig } from '../constants/test-config';

export type TestConfig = typeof desktopTestConfig | typeof mobileTestConfig;

export interface PostTestConfigResponse {
  testId: string;
  messageId: string;
}

export interface TestPlansResponse {
  appVersion: string;
  baseUrlIPv4: string;
  baseUrlIPv4NoPort: string;
  baseUrlIPv6: string;
  baseUrlIPv6NoPort: string;
  clientIPAddress: string;
  clientIsp: string;
  clientLatitude: string;
  clientLongitude: string;
  defaultServerId: number;
  hasIPv6: boolean;
  isOnComcastWifiBlock: boolean;
  maxUploadSize: number;
  osHostName: string;
  osPlatform: string;
  osType: string;
  port: number;
  secureBaseUrlIPv4: string;
  secureBaseUrlIPv6: string;
  secureTestPorts: string;
  serverDivision: string;
  serverLocation: string[];
  serverMarket: string;
  serverRegion: string;
  sitename: string;
  testPorts: string;
  webSocketPort: number;
  webSocketUrlIPv4: string;
  webSocketUrlIPv6: string;
}

export interface PostDownloadTestResponse {
  title: string;
  message: string;
  image: string;
  lang: string;
  download_speed_magnitude: number;
  downlod_speed_units: string;
  button_call_to_action: string;
  call_to_action_link: string;
  customer_type: string;
  capabilities: { position: number; check: boolean; text: string }[];
}

export type TestResults = {
  ACCOUNTNAME: string;
  BROWSER_NAME: string;
  BROWSER_VERSION: string;
  CIMA_LOGGED_IN: boolean;
  CLIENT_IPV4_ADDRESS: string;
  CLIENT_IPV6_ADDRESS: string;
  CLIENT_ISP: string;
  CLIENT_LATITUDE: string;
  CLIENT_LONGITUDE: string;
  DEFAULT_SERVER_ID: number;
  DIVISION: string;
  IPV6: number;
  MARKET: string;
  OPERATIVE_SYSTEM: string;
  PLATFORM: string;
  REGION: string;
  SERVER_HOSTNAME: string;
  SERVER_SITENAME: string;
  supportWebSocket: boolean;
  TEST_METHOD: string;
  IPV4_DOWNLOAD_MBPS: string;
  ipv4DownloadData: string;
  IPV6_DOWNLOAD_MBPS: number;
  ipv6DownloadData: string;
  IPV4_LATENCY: string;
  IPV6_LATENCY: number;
  IPV4_DOWNLOAD_PEAK: string;
  IPV4_UPLOAD_PEAK: string;
  IPV6_DOWNLOAD_PEAK: string;
  IPV6_UPLOAD_PEAK: string;
  SERVER_NAME: string;
  IPV4_UPLOAD_MBPS: string;
  IPV6_UPLOAD_MBPS: number;
  ipv4UploadData: string;
  ipv6UploadData: string;
}[];

/**
 * Gets tests plans from middleware service
 * @param {String} endpoint
 * @returns {() => Promise}
 */
export function fetchTestPlans(endpoint: string) {
  return function() {
    return smartFetch<TestPlansResponse>(endpoint, { mode: 'cors' });
  };
}

/**
 * Sends config to IPIE
 * @param {String} endpoint
 * @returns {(options: { sessionId: String, testConfig: Object}) => Promise}
 */
export function postTestConfig(endpoint: string) {
  return function({
    sessionId,
    testConfig,
  }: {
    sessionId?: string;
    testConfig?: TestConfig;
  }) {
    if (!sessionId && !testConfig) {
      return Promise.reject(new Error('missing sessionId and testConfig'));
    }
    if (!sessionId) {
      return Promise.reject(new Error('missing sessionId'));
    }
    if (!testConfig) {
      return Promise.reject(new Error('missing testConfig'));
    }

    const postBody = {
      speedtestConfig: testConfig,
    };
    return smartFetch<PostTestConfigResponse>(`${endpoint}/${sessionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postBody),
    });
  };
}

/**
 * Sends test results to IPIE
 * @param {String} endpoint
 * @returns {(options: {sessionId: String, testId: String, testResults: Object[]}) => Promise}
 */
export function postDownloadTestResults(endpoint: string) {
  return function({
    sessionId,
    testId,
    testResults,
  }: {
    sessionId: string;
    testId: string;
    testResults: TestResults;
  }) {
    const postUrl = endpoint
      .replace('<%sessionId%>', sessionId)
      .replace('<%testId%>', testId);

    return smartFetch<PostDownloadTestResponse>(postUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(testResults),
    });
  };
}

export default {
  fetchTestPlans,
  postTestConfig,
  postDownloadTestResults,
};
