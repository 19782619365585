// Third-party Libraries
import { connect } from 'react-redux';
import get from 'lodash/get';
// Our Code
import DeviceVsPlanSpeedPage from '../pages/DeviceVsPlanSpeed/DeviceVsPlanSpeedPage';
import {
  getResultsDownloadSpeed,
  hasResultsDownload,
} from '../api/selectors/view-state/test-speeds';
import { requestRoute } from '../api/actions/navigation';
import { requestTest } from '../api/actions/request-test';
import { RootState } from '../api/store-types';

const mapStateToProps = (state: RootState) => ({
  deviceBestName: get(state, 'deviceFingerprint.deviceDetails.bestName', ''),
  downloadSpeedReady: hasResultsDownload(state),
  downloadSpeedResult: getResultsDownloadSpeed(state),
  planDownloadSpeed: get(state, 'network.planSpeed', ''),
});

const actionCreators = { requestTest, requestRoute };

export default connect(
  mapStateToProps,
  actionCreators
)(DeviceVsPlanSpeedPage);
