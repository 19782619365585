// Third-party libraries
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import './i18n';
// Our code
import App from './components/App/App';
import configureStore, { history } from './api/configureStore';
import ErrorBoundary from './containers/ErrorBoundary';
import './styles/index.css';

const renderApp = () => {
  const store = configureStore();

  render(
    <Provider store={store}>
      <Suspense fallback={<div />}>
        <ErrorBoundary>
          <App history={history} />
        </ErrorBoundary>
      </Suspense>
    </Provider>,
    document.getElementById('app')
  );
};

if ('fetch' in window) {
  renderApp();
} else {
  import(
    /* webpackChunkName: "polyfills"*/ './modules/polyfills/polyfills'
  ).then(renderApp);
}
