// Third-party libraries
import { connect } from 'react-redux';
import get from 'lodash/get';
// Our Code
// Actions
import { animationLoaded } from '../api/actions/testing-animation';
import { markPerformance } from '../api/actions/performance';
import { requestRoute } from '../api/actions/navigation';
import { requestTest } from '../api/actions/request-test';
import {
  setServerLocation,
  setSelectedProtocol,
} from '../api/actions/advanced-settings';
import { speedtestCloseModal, speedtestOpenModal } from '../api/actions/modals';
// Selectors
import { getCurrentDownloadSpeed } from '../api/selectors/view-state/test-speeds';
import {
  getHasBothProtocols,
  getSelectedProtocol,
  getSelectedServerLocation,
  getServerLocation,
} from '../api/selectors/view-state/advanced-settings';
import { getIsAdvancedSettingsModalOpen } from '../api/selectors/view-state/modals';
import { getCompareByCategoryLink } from '../api/selectors/view-state/device-search';
// Components
import HomePage from '../pages/Home/HomePage';
// Constants
import { RootState } from '../api/store-types';

const mapStateToProps = (state: RootState) => ({
  compareByCategoryLink: getCompareByCategoryLink(state),
  currentDownloadSpeed: getCurrentDownloadSpeed(state),
  deviceBestName: get(state, 'deviceFingerprint.deviceDetails.bestName'), // dynamic state value
  fetchServerLocationStatus:
    state.advancedSettings.statuses.fetchServerLocation.status,
  hasBothProtocols: getHasBothProtocols(state),
  isAdvancedSettingsModalOpen: getIsAdvancedSettingsModalOpen(state),
  selectedProtocol: getSelectedProtocol(state),
  selectedServerLocation: getSelectedServerLocation(state),
  serverLocation: getServerLocation(state),
  showTestingScreen: state.ui.showTestingScreen, // default state value
  testPlansStatus: state.test.statuses.fetch.status, // default state value
});

const actionCreators = {
  animationLoaded,
  markPerformance,
  requestRoute,
  requestTest,
  setSelectedProtocol,
  setServerLocation,
  speedtestCloseModal,
  speedtestOpenModal,
};

export default connect(
  mapStateToProps,
  actionCreators
)(HomePage);
