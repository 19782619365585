import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import DeviceIcon from '../DeviceIcon/DeviceIcon';
import PlanInfo from '../PlanInfo/PlanInfo';
import { useLocalization } from '../../hooks/localization';

interface Props extends HTMLAttributes<HTMLDivElement> {
  maxSpeed: string;
  planSpeed: string | null;
  unit?: string;
  name: string;
  type: string;
  isYourDevice: boolean;
}

const DeviceInfo = ({
  maxSpeed,
  planSpeed,
  unit = 'Mbps',
  name,
  type,
  isYourDevice,
  className: parentClassNames,
  ...props
}: Props) => {
  const t = useLocalization();
  return (
    <div className={classnames(parentClassNames, 'flex')} {...props}>
      <div className="lg:flex">
        <DeviceIcon
          type={type}
          style={{ width: 100, height: 100 }}
          className="lg:self-center"
        />
      </div>
      <div className="pt-2 ml-8 lg:ml-12">
        <div className="self-center md:flex md:items-stretch">
          <div
            className="mt-0 lg:mr-12 lg:mt-6 md:min-w-200 md:mr-8"
            tabIndex={-1}
          >
            <h2 className="leading-tight tracking-wide text-headline3">
              {name}{' '}
              {isYourDevice
                ? t('compareByCategory_deviceSearch_yourDevice')
                : ''}
            </h2>
            <p>
              <span className="leading-normal tracking-wider text-headline1 font-700">
                {maxSpeed}
              </span>
              <span className="ml-1 tracking-wide text-body3 font-700 text-neutral2-base">
                {unit}
              </span>
            </p>
          </div>
          <p className="leading-normal tracking-wide max-w-320 text-body1 text-neutral2-base sm:ml-0 lg:max-w-450 lg:flex lg:self-center">
            {t('compareByCategory_chart_note')}
          </p>
        </div>
        {planSpeed && (
          <PlanInfo
            planSpeed={planSpeed}
            className="hidden px-0 py-6 pl-0 mr-8 italic leading-normal tracking-wide border-t border-b-0 text-neutral2-base text-body2 border-grey-2 sm:my-6 md:block"
          />
        )}
      </div>
    </div>
  );
};

export default DeviceInfo;
