import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { ReactComponent as Arrow } from './icons/icon-arrow-down.svg';
import accordionStyles from './Accordion.module.css';
import 'details-element-polyfill';

interface Props {
  arrowText?: ReactNode;
  outerContent: ReactNode;
  innerContent: ReactNode;
  index?: number;
  className?: string;
  handleClick: (event: React.SyntheticEvent, index?: number) => void;
  open: boolean;
}

const Accordion = ({
  className: parentClassNames,
  index = 0,
  arrowText,
  outerContent,
  handleClick,
  innerContent,
  ...props
}: Props) => {
  return (
    <details
      onClick={event => handleClick(event, index)}
      className={classnames(
        'select-none',
        accordionStyles.details,
        parentClassNames
      )}
      {...props}
    >
      <summary
        className={classnames(
          'relative container py-4 px-4 cursor-pointer list-none',
          accordionStyles.summary
        )}
      >
        <div className="flex items-center justify-between">
          {outerContent}
          <div className="flex items-center">
            {arrowText}
            <Arrow className={accordionStyles.arrow} />
          </div>
        </div>
      </summary>
      <div
        className={classnames(
          'overflow-hidden text-body2',
          accordionStyles.innerContent
        )}
      >
        {innerContent}
      </div>
    </details>
  );
};

export default Accordion;
