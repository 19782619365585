import { createReducer } from '@reduxjs/toolkit';

import * as STATUSES from '../constants/statuses';
import { DeviceSuggestionsResponse } from '../network-interfaces/device-suggestions';
import {
  fetchDeviceSuggestionsStart,
  fetchDeviceSuggestionsComplete,
} from '../actions/device-suggestions';

const { BUSY, ERROR, READY, EMPTY } = STATUSES;

interface DeviceSuggestionsState {
  statuses: {
    fetch: {
      status: UnionOf<typeof STATUSES>;
      error: Error | null;
    };
  };
  data: DeviceSuggestionsResponse | null;
}

const initialState: DeviceSuggestionsState = {
  statuses: {
    fetch: {
      status: EMPTY,
      error: null,
    },
  },
  data: null,
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(fetchDeviceSuggestionsStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        fetch: {
          status: BUSY,
          error: state.statuses.fetch.error,
        },
      },
    }))
    .addCase(fetchDeviceSuggestionsComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            fetch: {
              status: ERROR,
              error: action.payload as Error,
            },
          },
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          fetch: {
            status: READY,
            error: null,
          },
        },
        data: action.payload as DeviceSuggestionsResponse,
      };
    })
);

export default reducer;
