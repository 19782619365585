import { createAction } from '@reduxjs/toolkit';

import { REQUEST_TEST } from '../constants/action-types';

export const requestTest = createAction(
  REQUEST_TEST,
  ({ useAdvancedSettingsData = false }) => ({
    payload: {
      useAdvancedSettingsData,
    },
  })
);
