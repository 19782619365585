import React from 'react';
import ModalBox from '../ModalBox/ModalBox';
import ModalHeader from '../ModalHeader/ModalHeader';
import ModalActionBar from '../ModalActionBar/ModalActionBar';
import SelectInput from '../SelectInput/SelectInput';
import {
  ADVANCED_SETTINGS,
  ADVANCED_SETTINGS_MODAL,
} from '../../api/constants/modals';
import { requestTest as requestTestAction } from '../../api/actions/request-test';
import { setSelectedProtocol as setSelectedProtocolAction } from '../../api/actions/advanced-settings';
import { setServerLocation as setServerLocationAction } from '../../api/actions/advanced-settings';
import { speedtestCloseModal as speedtestCloseModalAction } from '../../api/actions/modals';
import { BUSY } from '../../api/constants/statuses';
import {
  CANCEL_MODAL_BUTTON,
  START_TEST_MODAL_BUTTON,
} from '../../api/constants/bids';
import { useLocalization } from '../../hooks/localization';
import XdsButton from '../XdsButton/XdsButton';

export interface AdvancedSettingsType {
  fetchServerLocationStatus: string;
  hasBothProtocols: boolean;
  isAdvancedSettingsModalOpen: boolean;
  requestTest: Dispatch<typeof requestTestAction>;
  selectedProtocol: string;
  selectedServerLocation: string;
  serverLocation: {
    label: string;
    value: string;
  }[];
  setServerLocation: Dispatch<typeof setServerLocationAction>;
  setSelectedProtocol: Dispatch<typeof setSelectedProtocolAction>;
  speedtestCloseModal: Dispatch<typeof speedtestCloseModalAction>;
}

const AdvancedSettings = ({
  fetchServerLocationStatus,
  hasBothProtocols,
  isAdvancedSettingsModalOpen,
  requestTest,
  selectedProtocol,
  setSelectedProtocol,
  setServerLocation,
  serverLocation,
  selectedServerLocation,
  speedtestCloseModal,
}: AdvancedSettingsType) => {
  const t = useLocalization();
  return (
    <div>
      <ModalBox
        modalDataBid={ADVANCED_SETTINGS_MODAL}
        isOpen={isAdvancedSettingsModalOpen}
      >
        {({ Header, Body, Footer }) => (
          <>
            <Header>
              <ModalHeader
                header={t('advancedSettings_title')}
                onClose={() => speedtestCloseModal(ADVANCED_SETTINGS)}
              />
            </Header>
            <Body>
              <SelectInput
                className="mb-1"
                id="advanced-settings-host"
                label={t('common_host')}
                name={t('common_host')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setServerLocation(selectedServerLocation, e.target.value)
                }
                options={serverLocation}
                value={selectedServerLocation}
              />
              {hasBothProtocols && (
                <SelectInput
                  id="advanced-settings-protocol"
                  label={t('common_protocol')}
                  name={t('common_protocol')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSelectedProtocol(e.target.value)
                  }
                  options={[
                    { label: 'IPv4', value: 'IPv4' },
                    { label: 'IPv6', value: 'IPv6' },
                  ]}
                  value={selectedProtocol}
                />
              )}
            </Body>

            <Footer>
              <ModalActionBar
                className="my-4"
                primaryButton={
                  <XdsButton
                    tone="theme1"
                    data-bid={START_TEST_MODAL_BUTTON}
                    disabled={fetchServerLocationStatus === BUSY}
                    onClick={() =>
                      requestTest({ useAdvancedSettingsData: true })
                    }
                  >
                    {t('advancedSettings_startTest')}
                  </XdsButton>
                }
                secondaryButton={
                  <XdsButton
                    tone="theme1"
                    variant="ghost"
                    data-bid={CANCEL_MODAL_BUTTON}
                    onClick={() => speedtestCloseModal(ADVANCED_SETTINGS)}
                  >
                    {t('advancedSettings_cancel')}
                  </XdsButton>
                }
              />
            </Footer>
          </>
        )}
      </ModalBox>
    </div>
  );
};

export default AdvancedSettings;
