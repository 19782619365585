import React from 'react';
import { GatewaySpeedTestIcon } from '../GatewaySpeedTestIcon/GatewaySpeedTestIcon';
import MediaObjectButton from '../MediaObjectButton/MediaObjectButton';
import { GATEWAY_SPEED_TEST_RESULTS_PAGE_BUTTON } from '../../api/constants/bids';
import { useLocalization } from '../../hooks/localization';

export type GatewayModels = 'xb3' | 'xb6' | 'xb7';

interface Props {
  gatewayModelName?: GatewayModels;
  startFormFlow?: () => void;
}

const LinkToGatewaySpeedTest = ({
  gatewayModelName = 'xb6',
  startFormFlow = () => {},
}: Props) => {
  const t = useLocalization();
  return (
    <div className="container px-4 pb-6 md:pb-10 md:pr-6">
      <MediaObjectButton
        className="shadow text-body2 rounded-small bg-grey-0"
        data-bid={GATEWAY_SPEED_TEST_RESULTS_PAGE_BUTTON}
        description={t('result_gatewaySpeedTestButton_description')}
        Icon={<GatewaySpeedTestIcon gatewayModelName={gatewayModelName} />}
        onClick={startFormFlow}
        title={
          <div className="leading-loose font-500">
            <span className="p-1 mr-2 text-neutral2-base bg-fill-theme1-base-select rounded-small text-body3 font-600">
              {t('result_new')}
            </span>
            {t('result_testSpeedToHome')}
          </div>
        }
      />
    </div>
  );
};

export default LinkToGatewaySpeedTest;
