import React from 'react';
import { DEVICE_SEARCH_ACTIVE_STATE } from '../../api/constants/bids';

import {
  DEVICE_SEARCH_STATE_INITIAL,
  DEVICE_SEARCH_STATE_NO_DEVICE_FOUND,
} from '../../api/constants/states';

import { useLocalization } from '../../hooks/localization';

interface Props {
  compareByCategoryLink: () => JSX.Element;
  deviceSearchUIState?: string;
}

const DeviceSearchMessage = ({
  compareByCategoryLink: CompareByCategoryLink,
  deviceSearchUIState,
}: Props) => {
  const t = useLocalization();

  const states: Record<string, JSX.Element> = {
    [DEVICE_SEARCH_STATE_INITIAL]: (
      <>
        <p className="text-neutral2-base">
          {t('compareByCategory_deviceSearch_message_initial_instructions')}
        </p>
        <p className="mt-2 text-neutral2-base">
          {t('compareByCategory_deviceSearch_message_initial_example')}
        </p>
      </>
    ),
    [DEVICE_SEARCH_STATE_NO_DEVICE_FOUND]: (
      <>
        <p className="text-neutral2-base">
          {t('compareByCategory_deviceSearch_message_noDevice')}
        </p>
        <CompareByCategoryLink />
      </>
    ),
  };

  return deviceSearchUIState && states[deviceSearchUIState] ? (
    <div className="container px-4 mt-4" data-bid={DEVICE_SEARCH_ACTIVE_STATE}>
      {states[deviceSearchUIState]}
    </div>
  ) : null;
};

export default DeviceSearchMessage;
