// Third-party libraries
import get from 'lodash/get';
import { ofType, Epic } from 'redux-observable';
import { filter, map, withLatestFrom } from 'rxjs/operators';
// Our code
import { fetchData, getEndpoints } from './epic-helpers';
import {
  LATENCYS_FETCH_STARTED,
  TEST_PLANS_FETCH_COMPLETED,
} from '../constants/action-types';
import { fetchLatencys } from '../network-interfaces/latencys';
import { fetchLatencysComplete, fetchLatencysStart } from '../actions/latencys';
import { fetchTestPlansComplete } from '../actions/test';
import { RootState, RootAction, EpicDependencies } from '../store-types';

export const scheduleLatencysFetchEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(TEST_PLANS_FETCH_COMPLETED),
    filter(
      (action: ReturnType<typeof fetchTestPlansComplete>) => !action.error
    ),
    filter(action => get(action, 'payload.hasIPv6')),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    map(([action, endpoints]) => {
      const secureBaseUrlIPv6 = get(action, 'payload.secureBaseUrlIPv6');
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return endpoints!.LATENCYS_ENDPOINT.replace(
        '<%secureBaseUrl%>',
        secureBaseUrlIPv6
      );
    }),
    map(fetchLatencysStart)
  );
};

export const fetchLatencysEpic: Epic<RootAction, RootAction> = action$ => {
  return action$.pipe(
    ofType(LATENCYS_FETCH_STARTED),
    fetchData(
      fetchLatencys,
      response => fetchLatencysComplete(null, response),
      error => fetchLatencysComplete(error, null),
      action => action.payload
    )
  );
};
