/**
 * @fileoverview Helper functions for working with errors.
 */

/**
 * Returns a usefully serializable version of an `Error` object or `ErrorEvent` object.  Otherwise, it returns the given
 * value as is.
 *
 * @function
 * @param {*} error Error or ErrorEvent payload
 * @returns {*} Usefully serializable `Error` object or `ErrorEvent` object, else the untouched input value
 */
export const serializeError = (error: Error | ErrorEvent) => {
  return error instanceof Object
    ? error instanceof Error
      ? {
          // Error object properties are not enumerable and are left out of JSON.stringify() and
          // spread operations. Setting desired props explicitly to make them enumerable/serializable.
          message: error.message,
          name: error.name,
          stack: error.stack,
          ...error,
        }
      : {
          colno: error.colno,
          error: error.error && {
            message: error.error.message,
            name: error.error.name,
            stack: error.error.stack,
          },
          filename: error.filename,
          lineno: error.lineno,
          message: error.message,
        }
    : error;
};
export default {
  serializeError,
};
