import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';

interface Props extends HTMLAttributes<HTMLDivElement> {
  imgSrc: string;
  title: string;
  subtitle: string;
  className?: string;
}

const TipsAndToolsCard = ({
  imgSrc,
  title,
  subtitle,
  className: parentClassNames,
  ...props
}: Props) => {
  return (
    <div
      className={classnames(
        parentClassNames,
        'bg-grey-0 rounded-small shadow overflow-hidden'
      )}
      {...props}
    >
      <div className="aspect-ratio aspect-ratio--1:3">
        <img src={imgSrc} alt="" className="w-full" />
      </div>
      <div className="p-4">
        <h4 className="mb-2 leading-none font-600 text-body2">{title}</h4>
        <p className="leading-tight text-body3 text-neutral2-base">
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export default TipsAndToolsCard;
