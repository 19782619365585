import { createReducer } from '@reduxjs/toolkit';

import * as STATUSES from '../constants/statuses';
import { fetchLatencysStart, fetchLatencysComplete } from '../actions/latencys';
import {
  fetchPlanInfoStart,
  fetchPlanInfoComplete,
} from '../actions/plan-info';
import { fetchTestPlansComplete } from '../actions/test';
import { LatencysResponse } from '../network-interfaces/latencys';
import { TestPlansResponse } from '../network-interfaces/test';
import { PlanInfoResponse } from '../network-interfaces/plan-info';

const { BUSY, ERROR, READY, EMPTY } = STATUSES;

export interface NetworkState {
  statuses: {
    fetch: {
      status: UnionOf<typeof STATUSES>;
      error: Error | null;
    };
    fetchPlanInfo: {
      status: UnionOf<typeof STATUSES>;
      error: Error | null;
    };
  };
  clientHasIPv6: boolean;
  planSpeed: number | null;
  publicIPAddress: string | null;
}

const initialState: NetworkState = {
  statuses: {
    fetch: {
      status: EMPTY,
      error: null,
    },
    fetchPlanInfo: {
      status: EMPTY,
      error: null,
    },
  },
  clientHasIPv6: false,
  planSpeed: null,
  publicIPAddress: null,
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(fetchLatencysStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        fetch: {
          status: BUSY,
          error: state.statuses.fetch.error,
        },
      },
    }))
    .addCase(fetchLatencysComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            fetch: {
              status: ERROR,
              error: action.payload as Error,
            },
          },
          clientHasIPv6: false,
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          fetch: {
            status: READY,
            error: null,
          },
        },
        clientHasIPv6: true,
        publicIPAddress: (action.payload as LatencysResponse).ipAddress,
      };
    })
    .addCase(fetchPlanInfoStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        fetchPlanInfo: {
          status: BUSY,
          error: state.statuses.fetchPlanInfo.error,
        },
      },
    }))
    .addCase(fetchPlanInfoComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            fetchPlanInfo: {
              status: ERROR,
              error: action.payload as Error,
            },
          },
          planSpeed: null,
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          fetchPlanInfo: {
            status: READY,
            error: null,
          },
        },
        planSpeed: (action.payload as PlanInfoResponse).downloadSpeed || null,
      };
    })
    .addCase(fetchTestPlansComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          publicIPAddress: null,
        };
      }

      return {
        ...state,
        publicIPAddress: (action.payload as TestPlansResponse).clientIPAddress,
      };
    })
);

export default reducer;
