import { createAction } from '@reduxjs/toolkit';

import { TRACK_METRICS } from '../constants/action-types';

export const trackMetrics = createAction(
  TRACK_METRICS,
  (
    eventName: string,
    params: { [index: string]: any },
    eventContext: string | null = null
  ) => ({
    payload: { eventName, params, eventContext },
  })
);
