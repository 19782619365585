// Third-party Libraries
import { connect } from 'react-redux';
// Our Code
import ImproveSpeedPage from '../pages/ImproveSpeed/ImproveSpeedPage';
import { requestTest } from '../api/actions/request-test';

const mapStateToProps = (/* state */) => ({});

const actionCreators = { requestTest };

export default connect(
  mapStateToProps,
  actionCreators
)(ImproveSpeedPage);
