import { createAction } from '@reduxjs/toolkit';

import {
  SESSION_ID_FETCH_COMPLETED,
  SESSION_ID_FETCH_STARTED,
} from '../constants/action-types';
import { SessionIdResponse } from '../network-interfaces/session-id';

export const fetchSessionIdStart = createAction(SESSION_ID_FETCH_STARTED);

/**
 * Action creator for signaling that fetching the sessionId is complete
 * @example
 * fetchSessionIdComplete(null, { id: '12345' })
 * // returns
 * // {
 * //   type: 'SESSION_ID_FETCH_COMPLETED',
 * //   payload: {
 * //     id: '12345'
 * //   },
 * //   error: false
 * // }
 */
export const fetchSessionIdComplete = createAction(
  SESSION_ID_FETCH_COMPLETED,
  (error: Error | null, response: SessionIdResponse | null) => ({
    payload: error || response,
    error: Boolean(error),
  })
);
