import { createAction } from '@reduxjs/toolkit';

import {
  SPEEDTEST__CLOSE_MODAL,
  SPEEDTEST__OPEN_MODAL,
} from '../constants/action-types';
import * as modalTypes from '../constants/modals';

export const speedtestCloseModal = createAction(
  SPEEDTEST__CLOSE_MODAL,
  (name: UnionOf<typeof modalTypes>) => ({
    payload: name,
  })
);

export const speedtestOpenModal = createAction(
  SPEEDTEST__OPEN_MODAL,
  (name: UnionOf<typeof modalTypes>) => ({
    payload: name,
  })
);
