/**
 * @fileoverview Helper functions for actually executing the speed tests.
 */
import get from 'lodash/get';
import { AdvancedSettingsState } from '../reducers/advanced-settings';
import { DeviceFingerprintState } from '../reducers/device-fingerprint';
import { NetworkState } from '../reducers/network';
import { TestState } from '../reducers/test';

function getDownloadValues(test: TestState, wasTestRunWithIPv6: boolean) {
  const accumulatedDownloadData = get(test, 'download.accumulated', []);
  const downloadMBPS = get(test, 'download.mean', '--');

  return {
    IPV4_DOWNLOAD_MBPS: wasTestRunWithIPv6 ? '--' : downloadMBPS,
    ipv4DownloadData: wasTestRunWithIPv6 ? '' : accumulatedDownloadData.join(),
    IPV6_DOWNLOAD_MBPS: wasTestRunWithIPv6 ? downloadMBPS : '--',
    ipv6DownloadData: wasTestRunWithIPv6 ? accumulatedDownloadData.join() : '',
  };
}

function getLatencyValues(test: TestState, wasTestRunWithIPv6: boolean) {
  const latencyMin = get(test, 'latency.min', '--');

  return {
    IPV4_LATENCY: wasTestRunWithIPv6 ? '--' : latencyMin,
    IPV6_LATENCY: wasTestRunWithIPv6 ? latencyMin : '--',
  };
}

function getUploadValues(test: TestState, wasTestRunWithIPv6: boolean) {
  const uploadMBPS = get(test, 'upload.mean', '--');

  return {
    IPV4_UPLOAD_MBPS: wasTestRunWithIPv6 ? '--' : uploadMBPS,
    IPV6_UPLOAD_MBPS: wasTestRunWithIPv6 ? uploadMBPS : '--',
    /*
      Product is not interested in the intermediary upload values from the test so we're
      passing the default value.
    */
    ipv4UploadData: '',
    ipv6UploadData: '',
  };
}

/**
 * The values in these fields are no longer consumed by the middleware team,
 * but they still need to be provided to IPIE. For that reason, they are
 * being passed static values that have been agreed upon by both the MW
 * and IPIE team.
 */
function getLegacyValues() {
  return {
    IPV4_DOWNLOAD_PEAK: '--',
    IPV4_UPLOAD_PEAK: '--',
    IPV6_DOWNLOAD_PEAK: '--',
    IPV6_UPLOAD_PEAK: '--',
    SERVER_NAME: '',
  };
}

export const injectSecureBaseUrl = (
  urlTemplate: string,
  {
    selectedProtocol,
    serverLocationSecureIPv4Url,
    serverLocationSecureIPv6Url,
    useAdvancedSettingsTestData,
  }: AdvancedSettingsState,
  testPlans: TestState['plans'],
  clientHasIPv6: boolean
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { hasIPv6, secureBaseUrlIPv4, secureBaseUrlIPv6 } = testPlans!;
  const useAdvancedUrlsToTest =
    useAdvancedSettingsTestData &&
    (!!serverLocationSecureIPv4Url || !!serverLocationSecureIPv6Url);

  if (useAdvancedUrlsToTest) {
    return hasIPv6 && clientHasIPv6 && selectedProtocol === 'IPv6'
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlTemplate.replace('<%secureBaseUrl%>', serverLocationSecureIPv6Url!)
      : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlTemplate.replace('<%secureBaseUrl%>', serverLocationSecureIPv4Url!);
  } else {
    return hasIPv6 && clientHasIPv6
      ? urlTemplate.replace('<%secureBaseUrl%>', secureBaseUrlIPv6)
      : urlTemplate.replace('<%secureBaseUrl%>', secureBaseUrlIPv4);
  }
};

function injectSecureTestPort(urlTemplate: string, secureTestPort: string) {
  return urlTemplate.replace('<%secureTestPort%>', secureTestPort);
}

/**
 * Creates the payload for the request which POSTs the test results to IPIE and gets back the copy to display
 * on the results page.
 * @param {Object} deviceFingerprintData the 'data' property nested in the 'deviceFingerprint' slice of state
 * @param {Object} network 'network' slice of state
 * @param {*} test 'test' slice of state
 */
export const createTestResultsPostPayload = (
  deviceFingerprintData: DeviceFingerprintState['data'],
  network: NetworkState,
  test: TestState
) => {
  const wasTestRunWithIPv6 = get(network, 'clientHasIPv6', false);
  const supportWebSocket = 'WebSocket' in window || 'MozWebSocket' in window;

  const downloadValues = getDownloadValues(test, wasTestRunWithIPv6);
  const uploadValues = getUploadValues(test, wasTestRunWithIPv6);
  const latencyValues = getLatencyValues(test, wasTestRunWithIPv6);
  const legacyValues = getLegacyValues();

  return [
    {
      ACCOUNTNAME: get(network, 'publicIPAddress'),
      BROWSER_NAME: get(deviceFingerprintData, 'advertised_browser'),
      BROWSER_VERSION: get(deviceFingerprintData, 'advertised_browser_version'),
      /*
        CIMA_LOGGED_IN will be false in the standalone site because it's an
        unauthenticated experience, however this will be true when
        speedtest is consumed by xFi.
      */
      CIMA_LOGGED_IN: false,
      CLIENT_IPV4_ADDRESS: get(test, 'plans.clientIPAddress'),
      CLIENT_IPV6_ADDRESS: wasTestRunWithIPv6
        ? get(network, 'publicIPAddress')
        : '--',
      CLIENT_ISP: get(test, 'plans.clientIsp'),
      CLIENT_LATITUDE: get(test, 'plans.clientLatitude'),
      CLIENT_LONGITUDE: get(test, 'plans.clientLongitude'),
      DEFAULT_SERVER_ID: get(test, 'plans.defaultServerId'),
      DIVISION: get(test, 'plans.serverDivision'),
      IPV6: wasTestRunWithIPv6 ? 1 : 0, // 0 if false, 1 if true
      MARKET: get(test, 'plans.serverMarket'),
      OPERATIVE_SYSTEM: get(test, 'plans.osType'),
      PLATFORM: get(test, 'plans.osPlatform'),
      REGION: get(test, 'plans.serverRegion'),
      SERVER_HOSTNAME: get(test, 'plans.osHostName'),
      SERVER_SITENAME: get(test, 'plans.sitename'),
      supportWebSocket,
      TEST_METHOD: 'https', // as long as we're only using https, this can be hardcoded
      ...downloadValues,
      ...latencyValues,
      ...legacyValues,
      ...uploadValues,
    },
  ];
};

/**
 * Generates the array of urls that will be used to initialize the test
 * @param {string} urlTemplate a string into which values will be "injected"
 * @param {Object} advancedSettings slice of state
 * @param {Object} testPlans plans from the test slice of state
 * @param {number} connectionsPerPort a test configuration setting that manages the maximum number of connections per port
 * @param {boolean} clientHasIPv6 whether or not the client has support for IPv6
 */
export const createTestUrls = (
  urlTemplate: string,
  advancedSettings: AdvancedSettingsState,
  testPlans: TestState['plans'],
  connectionsPerPort: number,
  clientHasIPv6: boolean
) => {
  let urls: string[] = [];
  let testUrl = injectSecureBaseUrl(
    urlTemplate,
    advancedSettings,
    testPlans,
    clientHasIPv6
  );
  let secureTestPorts: string = get(testPlans, 'secureTestPorts', '');
  let secureTestPortsArr = secureTestPorts.split(',');

  secureTestPortsArr.forEach(port => {
    for (let i = 0; i < connectionsPerPort; i++) {
      urls.push(injectSecureTestPort(testUrl, port));
    }
  });
  return urls;
};

/**
 * Generates the secure secureBaseUrlIPv4 and secureBaseUrlIPv6 from the data returned in the selectserver api call (Advanced Settings)
 * Examples of IPv4 and IPv6 Advanced Settings Test URLs:
 * IPv4: qoecnf-nash-02.speedtest-web.sys.comcast.net
 * IPv6: v6-qoecnf-nash-02.speedtest-web.sys.comcast.net
 */
export const createAdvancedSettingsTestUrls = (serverLocationUrl: string) => {
  const indexNum = serverLocationUrl.indexOf('sys');
  const serverLocationSecureIPv4Url =
    serverLocationUrl.slice(0, indexNum) +
    'speedtest-web.' +
    serverLocationUrl.slice(indexNum);
  const serverLocationSecureIPv6Url = 'v6-' + serverLocationSecureIPv4Url;
  return {
    serverLocationSecureIPv4Url,
    serverLocationSecureIPv6Url,
  };
};
