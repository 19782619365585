import React, { ReactNode } from 'react';
import classnames from 'classnames';

declare type HeaderType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface Props {
  children?: ReactNode;
  className?: string;
  description?: ReactNode;
  headingLevel?: number;
  Icon?: ReactNode;
  title: ReactNode;
}

/* istanbul ignore next */
const MediaObject = ({
  description,
  children,
  className: parentClassNames,
  headingLevel = 3,
  Icon,
  title,
  ...props
}: Props) => {
  const HeaderElement = `h${headingLevel}` as HeaderType;

  return (
    <div
      className={classnames(parentClassNames, 'flex items-center no-underline')}
      {...props}
    >
      {Icon && <div className="flex-shrink-0 mr-4">{Icon}</div>}
      <div className="flex-1">
        <HeaderElement
          className="text-neutral-base break-words text-body1"
          data-bid="test"
        >
          {title}
        </HeaderElement>
        {description && (
          <div
            className="text-body2 text-neutral2-base"
            data-bid="test"
            style={{ maxWidth: '34rem' }}
          >
            {description}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default MediaObject;
