import React, { HTMLAttributes } from 'react';
import AdvancedSettings from '../../components/AdvancedSettings/AdvancedSettings';
import ResultsContent from '../../components/Results/Results';
import TipsAndToolsContent from '../../components/TipsAndTools/TipsAndTools';
import LinkToGatewaySpeedTest from '../../components/LinkToGatewaySpeedTest/LinkToGatewaySpeedTest';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import { requestLatencyTest as requestLatencyTestAction } from '../../api/actions/latency-test';
import { requestTest as requestTestAction } from '../../api/actions/request-test';
import { AdvancedSettingsType } from '../../components/AdvancedSettings/AdvancedSettings';
import { GatewayModels } from '../../components/LinkToGatewaySpeedTest/LinkToGatewaySpeedTest';
import { requestRoute as requestSpeedTestRoute } from '../../api/speedtest-interface';
import { getAccessibilityLabel } from '../../api/helpers/accessibility';
import { useLocalization } from '../../hooks/localization';
import { Capability } from '../../components/CapabilitiesList/CapabilitiesList';
interface Props extends HTMLAttributes<HTMLDivElement>, AdvancedSettingsType {
  compareByCategoryLink: string;
  deviceBestName: string;
  downloadSpeedResult: string;
  downloadSpeedResultVehicle: string;
  gatewayModelName?: GatewayModels;
  gatewaySpeedTestAvailable: boolean;
  hostName: string;
  latencySpeed: {
    value: string;
    displaySpinner: boolean;
  };
  requestLatencyTest: Dispatch<typeof requestLatencyTestAction>;
  protocolVersion: string;
  requestRoute: Dispatch<typeof requestSpeedTestRoute>;
  requestTest: Dispatch<typeof requestTestAction>;
  startFormFlow?: () => void;
  testResults: {
    title: string;
    message: string;
    image: string;
    lang: string;
    download_speed_magnitude: number;
    downlod_speed_units: string;
    button_call_to_action: string;
    call_to_action_link: string;
    capabilities: Capability[];
  };
  uploadSpeed: {
    value: string;
    displaySpinner: boolean;
  };
}

const ResultsPage = ({
  compareByCategoryLink,
  deviceBestName,
  downloadSpeedResult,
  downloadSpeedResultVehicle,
  fetchServerLocationStatus,
  gatewayModelName,
  gatewaySpeedTestAvailable,
  hasBothProtocols,
  hostName,
  isAdvancedSettingsModalOpen,
  latencySpeed,
  requestLatencyTest,
  protocolVersion,
  requestRoute,
  requestTest,
  selectedProtocol,
  selectedServerLocation,
  serverLocation,
  setSelectedProtocol,
  setServerLocation,
  speedtestCloseModal,
  startFormFlow,
  testResults,
  uploadSpeed,
}: Props) => {
  const t = useLocalization();
  const accessibilityLabel = getAccessibilityLabel(
    t,
    testResults.capabilities,
    testResults.title,
    testResults.message,
    downloadSpeedResult
  );

  return (
    <>
      <ResultsContent
        buttonText={testResults.button_call_to_action}
        capabilities={testResults.capabilities}
        compareByCategoryLink={compareByCategoryLink}
        ctaLink={testResults.call_to_action_link}
        deviceBestName={deviceBestName}
        downloadSpeed={downloadSpeedResult}
        hostName={hostName}
        image={downloadSpeedResultVehicle}
        latencySpeed={latencySpeed}
        requestLatencyTest={requestLatencyTest}
        message={testResults.message}
        protocolVersion={protocolVersion}
        title={testResults.title}
        requestRoute={requestRoute}
        requestTest={requestTest}
        uploadSpeed={uploadSpeed}
        accessibilityLabel={accessibilityLabel}
      />
      <div className="py-8 bg-grey-2 md:py-10">
        {gatewaySpeedTestAvailable && (
          <LinkToGatewaySpeedTest
            gatewayModelName={gatewayModelName}
            startFormFlow={startFormFlow}
          />
        )}
        <SectionHeader
          className="container px-4"
          header={t('result_tipsAndTools')}
        />
        <TipsAndToolsContent
          className="container px-2 md:px-4"
          requestRoute={requestRoute}
          compareByCategoryLink={compareByCategoryLink}
        />
      </div>
      <AdvancedSettings
        fetchServerLocationStatus={fetchServerLocationStatus}
        hasBothProtocols={hasBothProtocols}
        isAdvancedSettingsModalOpen={isAdvancedSettingsModalOpen}
        requestTest={requestTest}
        selectedProtocol={selectedProtocol}
        selectedServerLocation={selectedServerLocation}
        serverLocation={serverLocation}
        setSelectedProtocol={setSelectedProtocol}
        setServerLocation={setServerLocation}
        speedtestCloseModal={speedtestCloseModal}
      />
    </>
  );
};

export default ResultsPage;
