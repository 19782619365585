import { smartFetch } from './data-helpers';

export type DeviceSuggestionsResponse = {
  displayName: string;
  id: string;
}[];

/**
 * Gets device suggestions from IPIE
 * @param {String} endpoint
 * @returns {(text: String) => Promise}
 */
export function fetchDeviceSuggestions(endpoint: string) {
  return function(text = '') {
    return smartFetch<DeviceSuggestionsResponse>(`${endpoint}?text=${text}`, {
      cache: 'default',
    });
  };
}

export default {
  fetchDeviceSuggestions,
};
