import React, { HTMLAttributes, ReactNode } from 'react';
import classnames from 'classnames';
import { Dispatch } from 'redux';
import { SITE_TITLE } from '../../site-map';
import { getOnBack } from '../../api/selectors/view-state/header';
import { ReactComponent as ChevronIcon } from './icons/icon-arrow-right-fillable.svg';
import { ReactComponent as ArrowIcon } from './icons/ic-m-left.svg';
import { useLocalization } from '../../hooks/localization';

interface Props extends HTMLAttributes<HTMLHeadingElement> {
  showBackButton?: boolean;
  onBack: ReturnType<typeof getOnBack>;
  right?: ReactNode;
  dispatch: Dispatch;
}

const Header = ({
  className: parentClassNames,
  showBackButton = true,
  onBack,
  right,
  title = SITE_TITLE,
  dispatch,
  ...props
}: Props) => {
  const t = useLocalization();
  const backLabel = t(['header_back', '']);
  return (
    <header
      className={classnames(
        parentClassNames,
        'border-b border-grey-2 bg-grey-0'
      )}
      {...props}
    >
      <div className="container flex items-stretch px-4 h-75">
        <div className="flex items-center w-1/5">
          {showBackButton && (
            <button
              className="flex items-center"
              onClick={() => dispatch(onBack())}
            >
              <ArrowIcon
                className="mt-1 lg:hidden"
                style={{ width: 18, height: 16 }}
                aria-hidden="true"
              />
              <ChevronIcon
                style={{ width: 8, height: 14 }}
                className="hidden my-1 transform rotate-180 lg:block"
                aria-hidden="true"
              />
              <span className="hidden pl-4 leading-none lg:block font-500">
                {backLabel}
              </span>
              <span className="sr-only lg:hidden">{backLabel}</span>
            </button>
          )}
        </div>
        <div className="flex items-center justify-center w-3/5">
          <h1 className="leading-none text-center text-headline3 md:text-headline2">
            {title}
          </h1>
        </div>
        <div className="flex items-center justify-end w-1/5">{right}</div>
      </div>
    </header>
  );
};

export default Header;
