// Third-party Libraries
import { createSelector } from 'reselect';
// Our Code
import { RootState } from '../../store-types';
import { ADVANCED_SETTINGS } from '../../../api/constants/modals';

export const getIsAdvancedSettingsModalOpen = createSelector(
  (state: RootState) => state.modals.isOpen,
  (state: RootState) => state.modals.name,
  (isOpen, name) => isOpen && name === ADVANCED_SETTINGS
);
