// Third-party libraries
import { ofType, Epic } from 'redux-observable';
import includes from 'lodash/includes';
import get from 'lodash/get';
import { filter, map, withLatestFrom, mapTo, tap } from 'rxjs/operators';
// Our  code
import { markPulseLoaded } from '../actions/pulse';
import { ROUTES } from '../constants/routes';
import { getSessionId, getTestId } from '../helpers/state-getters';
import { RootState, RootAction, EpicDependencies } from '../store-types';

declare global {
  interface Window {
    // From Pulse
    pi: any;
  }
}

const PULSE_CLIENT_ID = 'PI-30504999';

const appendAndExecutePulseScript = (
  window: Window,
  speedTestState: RootState
) => {
  window['pi'] = function() {
    window['pi'].commands = window['pi'].commands || [];
    window['pi'].commands.push(arguments);
  };
  const script = window.document.createElement('script');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  script.src = speedTestState.appConfig.endpoints!.PULSE_ENDPOINT;
  script.type = 'text/javascript';
  script.async = true;
  window.document.body.appendChild(script);

  window.pi('identify', PULSE_CLIENT_ID);
  window.pi('set_custom_data', getPulseCustomData(speedTestState));
  window.pi('get', 'surveys');
};

const getPulseCustomData = (speedTestState: RootState) => {
  return {
    sessionId: getSessionId(speedTestState),
    testId: getTestId(speedTestState),
    timestamp: Date.now(),
    tier: get(speedTestState, 'network.planSpeed', ''),
    deviceFingerprint: get(speedTestState, 'deviceFingerprint.data', {}),
    deviceDetails: get(speedTestState, 'deviceFingerprint.deviceDetails', {}),
    downloadResult: {
      downloadSpeed: get(
        speedTestState,
        'test.download.results.download_speed_magnitude',
        ''
      ),
      contextualMessage: get(speedTestState, 'test.download.results.title', ''),
    },
    customerType: get(
      speedTestState,
      'test.download.results.customer_type',
      ''
    ),
  };
};

export const executePulseActionsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { window, speedTestContext }) => {
  return action$.pipe(
    ofType(speedTestContext.CHANGE_ROUTE),
    withLatestFrom(state$, (action, state) => state),
    filter(state => includes(speedTestContext.getRoute(state), ROUTES.RESULTS)),
    map(speedTestContext.getState),
    filter(
      (speedTestState: RootState) =>
        get(speedTestState.test, 'download.results') !== null &&
        speedTestState.appConfig.pulseLoaded === false
    ),
    tap(speedTestState => appendAndExecutePulseScript(window, speedTestState)),
    mapTo(markPulseLoaded())
  );
};
