// Third-party libraries
import { zip } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
// Our code
import {
  DEVICE_DETAILS_BY_FINGERPRINT_FETCH_COMPLETED,
  DEVICE_FINGERPRINT_FETCH_COMPLETED,
  DEVICE_FINGERPRINT_POST_COMPLETED,
  SESSION_ID_FETCH_COMPLETED,
  TEST_PLANS_FETCH_COMPLETED,
} from '../constants/action-types';
import { fetchTestPlansComplete } from '../actions/test';
import { fromLoadEvent } from './epic-helpers';
import { appFullyLoaded, appReady } from '../actions/lifecycle';
import { EpicDependencies, RootAction, RootState } from '../store-types';

const dataCalls = [
  DEVICE_DETAILS_BY_FINGERPRINT_FETCH_COMPLETED,
  DEVICE_FINGERPRINT_FETCH_COMPLETED,
  DEVICE_FINGERPRINT_POST_COMPLETED,
  SESSION_ID_FETCH_COMPLETED,
  TEST_PLANS_FETCH_COMPLETED,
];

/**
 * Dispatches an action to mark when the app is fully loaded, including the
 * whole page, the page's dependent resources and all data calls. This
 * action is used by `logPerformanceEpic` and `logSecondaryPerformanceEpic`.
 *
 * @param {Object} action$ Observable stream of actions
 * @param {Object} state$ [NOT USED] Observable stream of state changes
 * @param {Object} {window} window to listen for `load` event
 * @returns {Object} Observable stream of actions
 */
export const appFullyLoadedEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { window }) => {
  const dataCallCompletions = dataCalls.map(actionType =>
    action$.pipe(
      ofType(actionType),
      first()
    )
  );
  const windowLoadEvent$ = fromLoadEvent(window);

  return zip(...dataCallCompletions, windowLoadEvent$).pipe(
    map(() => appFullyLoaded())
  );
};

/**
 * Dispatching APP_READY action, when TEST_PLANS_FETCH_COMPLETED happened with
 * any results
 *
 * Please feel free to revisit that at some point. We could include more data
 * related actions, in order dispatch APP_READY action. If we decided to do so,
 * please use zip method from rxjs, to zip multiply actions.
 */
export const appReadyEpic: Epic<RootAction, RootAction> = action$ => {
  return action$.pipe(
    ofType(TEST_PLANS_FETCH_COMPLETED),
    map((action: ReturnType<typeof fetchTestPlansComplete>) => ({
      payload: action.error ? action.payload : {},
      error: Boolean(action.error),
    })),
    map(appReady)
  );
};
