import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import {
  ROUTES,
  ROUTES_MAPPING,
  Route as RouteKey,
} from '../../api/constants/routes';
import { history } from '../../api/configureStore';
import { matchedRoute } from '../../api/selectors/view-state/header';

import {
  AccessibilityAnnouncerContainer,
  HeaderContainer,
} from '../../containers/';
import Head from '../Head/Head';

interface Props {
  history: typeof history;
}

const App = ({ history }: Props) => {
  const [route] = useSelector(matchedRoute);

  return (
    <ConnectedRouter history={history}>
      <AccessibilityAnnouncerContainer />
      <Head route={route} />
      <HeaderContainer />
      <Switch>
        {Object.keys(ROUTES).map((route: RouteKey) => (
          <Route
            key={ROUTES[route]}
            component={ROUTES_MAPPING[route].component}
            exact={ROUTES_MAPPING[route].exact}
            path={ROUTES[route]}
          />
        ))}
        <Redirect to={ROUTES.HOME} />
      </Switch>
    </ConnectedRouter>
  );
};

export default App;
