import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import TipsAndToolsCard from '../TipsAndToolsCard/TipsAndToolsCard';
import DeviceLookUp from './images/Tips_and_Tools-Device_Lookup.svg';
import ImproveSpeed from './images/Tips_and_Tools-Improve_Speed.svg';
import NonSpeedProblems from './images/Tips_and_Tools-Non_Speed_Problems.png';
import DeviceVsPlan from './images/Tips_and_Tools-Plan_Vs_Device.svg';
import Link from '../Link/Link';
import { ROUTES } from '../../api/constants/routes';
import { requestRoute as requestSpeedTestRoute } from '../../api/speedtest-interface';
import { useLocalization } from '../../hooks/localization';

interface Props extends HTMLAttributes<HTMLDivElement> {
  requestRoute: Dispatch<typeof requestSpeedTestRoute>;
  compareByCategoryLink: string;
}

const TipsAndTools = ({
  className: parentClassNames,
  requestRoute,
  compareByCategoryLink,
  ...props
}: Props) => {
  const t = useLocalization();
  const tipsAndToolsCards = [
    {
      imgSrc: DeviceVsPlan,
      title: t('tipsAndTools_deviceVsPlan_header'),
      subtitle: t('tipsAndTools_deviceVsPlan_body'),
      to: ROUTES.DEVICE_VS_PLAN,
    },
    {
      imgSrc: NonSpeedProblems,
      title: t('tipsAndTools_nonSpeedProblems_header'),
      subtitle: t('tipsAndTools_nonSpeedProblems_body'),
      to: ROUTES.NON_SPEED_PROBLEMS,
    },
    {
      imgSrc: ImproveSpeed,
      title: t('tipsAndTools_improveSpeed_header'),
      subtitle: t('tipsAndTools_improveSpeed_body'),
      to: ROUTES.IMPROVE_SPEED,
    },
    {
      imgSrc: DeviceLookUp,
      title: t('tipsAndTools_compareByCategory_header'),
      subtitle: t('tipsAndTools_compareByCategory_body'),
      to: compareByCategoryLink,
    },
  ];

  return (
    <div className={classnames(parentClassNames, 'flex flex-wrap')} {...props}>
      {tipsAndToolsCards.map(card => (
        <Link
          href={card.to}
          key={card.to}
          requestRoute={requestRoute}
          className="block w-full px-2 mb-4 no-underline md:w-1/2"
        >
          <TipsAndToolsCard className="h-full" {...card} />
        </Link>
      ))}
    </div>
  );
};

export default TipsAndTools;
