import { createAction } from '@reduxjs/toolkit';

import {
  HTTP_LATENCY_TEST_COMPLETED,
  HTTP_LATENCY_TEST_STARTED,
  LATENCY_TEST_COMPLETED,
  LATENCY_TEST_STARTED,
  REQUEST_LATENCY_TEST,
  WS_LATENCY_TEST_COMPLETED,
  WS_LATENCY_TEST_STARTED,
} from '../constants/action-types';
import {
  HttpLatencyTestResult,
  WsLatencyTestResult,
} from '../epics/latency-test';

export const httpLatencyTestComplete = createAction(
  HTTP_LATENCY_TEST_COMPLETED,
  (error: Error | null, result: HttpLatencyTestResult | null) => ({
    payload: error || { result },
    error: Boolean(error),
  })
);

export const httpLatencyTestStart = createAction(HTTP_LATENCY_TEST_STARTED);

export const latencyTestComplete = createAction(
  LATENCY_TEST_COMPLETED,
  (
    action: { payload?: any; error: boolean } = {
      error: false,
    }
  ) => ({
    payload: action.payload,
    error: action.error,
  })
);

export const latencyTestStart = createAction(LATENCY_TEST_STARTED);

export const requestLatencyTest = createAction(REQUEST_LATENCY_TEST);

export const wsLatencyTestComplete = createAction(
  WS_LATENCY_TEST_COMPLETED,
  (error: Error | null, result: WsLatencyTestResult | null) => ({
    payload: error || { result },
    error: Boolean(error),
  })
);

export const wsLatencyTestStart = createAction(WS_LATENCY_TEST_STARTED);
