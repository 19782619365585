import React from 'react';

import LabelSpeedResult from '../LabelSpeedResult/LabelSpeedResult';
import ExternalLink from '../ExternalLink/ExternalLink';
import Link from '../Link/Link';
import Header from './images/header.svg';
import PlanSpeed from './images/plan-speed.svg';
import TestResults from './images/device-speed.svg';
import { ROUTES } from '../../api/constants/routes';
import { requestRoute as requestRouteAction } from '../../api/actions/navigation';
import { requestTest as requestTestAction } from '../../api/actions/request-test';
import { useLocalization } from '../../hooks/localization';

interface Props {
  deviceBestName: string;
  downloadSpeedReady: boolean;
  downloadSpeedResult: string;
  planDownloadSpeed: string;
  requestTest: Dispatch<typeof requestTestAction>;
  requestRoute: Dispatch<typeof requestRouteAction>;
}

const DeviceVsPlanSpeed = ({
  planDownloadSpeed,
  downloadSpeedReady,
  downloadSpeedResult,
  deviceBestName,
  requestTest,
  requestRoute,
}: Props) => {
  const t = useLocalization();

  const deviceName =
    deviceBestName === '' ? t('common_device') : deviceBestName;
  const aboutSpeedToDeviceSubHeader = t(
    'deviceVsPlanSpeed_aboutSpeedToDevice_subHeader',
    { device: deviceName }
  );
  return (
    <div className="flex flex-wrap">
      <div className="flex flex-col md:flex-col-reverse md:pt-2">
        <div className="self-end w-full md:pt-10 lg:w-2/3 lg:pt-0">
          <div className="aspect-ratio aspect-ratio--1:1-75">
            <img src={Header} alt="results vs plan" />
          </div>
        </div>
        <div className="flex md:flex-col lg:flex-row-reverse">
          <div className="text-center md:text-left lg:w-2/3">
            <h2 className="pt-8 font-700 text-headline1 md:font-300 md:pt-0">
              {t('deviceVsPlanSpeed_main_title')}
            </h2>
            <p className="px-6 pt-4 pb-8 text-body1 text-neutral2-base md:pl-0 md:pt-2 md:pb-10 lg:pb-2">
              {t('deviceVsPlanSpeed_main_description')}
            </p>
          </div>
          <nav className="hidden md:flex md:flex-col lg:w-1/3">
            <h3 className="text-headline2 font-300 md:pb-4">
              {t('deviceVsPlanSpeed_summary_title')}
            </h3>
            <ul className="p-0 list-none">
              <li className="md:border-grey-7 md:border-l-3 md:pl-6 md:pb-2 text-body1">
                <Link className="link" href="#about-plan-speed">
                  {t('deviceVsPlanSpeed_summary_aboutPlanSpeed')}
                </Link>
              </li>
              <li className="md:border-grey-7 md:border-l-3 md:pl-6 md:pb-2 text-body1">
                <Link className="link" href="#your-speed-test">
                  {t('deviceVsPlanSpeed_summary_aboutSpeedToDevice')}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <div className="lg:w-2/3">
          <h3
            id="about-plan-speed"
            className="pt-8 pb-4 pl-4 bg-grey-2 md:pl-0 md:bg-transparent text-headline2 md:font-400"
          >
            {t('deviceVsPlanSpeed_aboutPlanspeed_header')}
          </h3>
          <div className="aspect-ratio aspect-ratio--1:3">
            <img
              src={PlanSpeed}
              className="w-full align-bottom border-b border-grey-2 md:border-0"
              alt="your plan speed"
            />
          </div>
          {planDownloadSpeed && (
            <LabelSpeedResult
              className="px-4 py-6 border-b border-grey-2 md:px-0 md:pb-1 md:pt-6 md:border-b-0"
              data={planDownloadSpeed}
              isReady={true}
              title={t('deviceVsPlanSpeed_aboutPlanSpeed_subHeader')}
            />
          )}
          <p className="px-4 pt-8 pb-4 text-body1 text-neutral2-base md:pt-6 md:px-0">
            {t('deviceVsPlanSpeed_aboutPlanspeed_body')}
          </p>
          <ExternalLink
            href="https://www.xfinity.com/learn/internet-service/speed"
            className="pt-1 mb-4 ml-4 link md:ml-0 md:pt-0 text-body1"
          >
            {t('deviceVsPlanSpeed_aboutPlanSpeed_homMuchSpeed')}
          </ExternalLink>
          <h3
            id="your-speed-test"
            className="pt-8 pb-4 pl-4 bg-grey-2 md:pl-0 md:bg-transparent text-headline2 md:font-400"
          >
            {t('deviceVsPlanSpeed_aboutSpeedToDevice_header')}
          </h3>
          <div className="aspect-ratio aspect-ratio--1:3">
            <img
              src={TestResults}
              className="w-full align-bottom border-b border-grey-2 md:border-0"
              alt="speed to device"
            />
          </div>
          <LabelSpeedResult
            className="px-4 py-6 border-b border-grey-2 md:px-0 md:pb-1 md:pt-6 md:border-b-0"
            data={downloadSpeedResult}
            isReady={downloadSpeedReady}
            requestTest={requestTest}
            title={aboutSpeedToDeviceSubHeader}
          />
          <p className="px-4 pt-8 pb-4 text-body1 text-neutral2-base md:pt-4 md:px-0">
            {t('deviceVsPlanSpeed_aboutSpeedToDevice_body_description')}
          </p>
          <p className="px-4 pt-1 pb-6 text-body1 text-neutral2-base md:pl-0 md:pt-0 md:pb-1">
            {t('deviceVsPlanSpeed_aboutSpeedToDevice_body_listHeader')}
          </p>
          <ul className="pb-4 pl-10 pr-4 text-body1 text-neutral2-base md:pl-6 md:leading-loose">
            <li>
              {t('deviceVsPlanSpeed_aboutSpeedToDevice_body_firstElement')}
            </li>
            <li>
              {t('deviceVsPlanSpeed_aboutSpeedToDevice_body_secondElement')}
            </li>
            <li>
              {t('deviceVsPlanSpeed_aboutSpeedToDevice_body_thirdElement')}
            </li>
            <li>
              {t('deviceVsPlanSpeed_aboutSpeedToDevice_body_fourthElement')}
            </li>
          </ul>
          {/* TODO: replace with router-friendly internal link component */}
          <Link
            onClick={event => {
              event.preventDefault();
              requestRoute({ route: ROUTES.IMPROVE_SPEED });
            }}
            href={ROUTES.IMPROVE_SPEED}
            className="pt-1 mb-4 ml-4 link md:ml-0 text-body1"
          >
            {t('deviceVsPlanSpeed_aboutSpeedToDevice_improveSpeed')}
          </Link>
          <div className="h-50 bg-grey-2 md:bg-grey-0" />
        </div>
      </div>
    </div>
  );
};

export default DeviceVsPlanSpeed;
