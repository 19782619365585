import React from 'react';
import { Link } from '../Link/Link';
import { ReactComponent as ExternalIcon } from './icons/ExternalLink.svg';

// eslint-disable-next-line
const ExternalLink = ({ children, ...props }: any) => (
  <Link rel="noopener noreferrer" target="_blank" {...props}>
    {children} <ExternalIcon className="w-20 h-20 ml-2 fill-current" />
  </Link>
);

export default ExternalLink;
