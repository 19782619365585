import { createAction } from '@reduxjs/toolkit';

import {
  A11Y_ANNOUNCER_FOCUS,
  DEVICE_SEARCH_INPUT_BLUR,
  DEVICE_SEARCH_SET_INPUT_FOCUS_STARTED,
  HIDE_DEVICE_SEARCH,
  HIDE_TESTING_SCREEN,
  MAKE_SCREEN_READER_ANNOUNCEMENT,
  SET_DEVICE_SEARCH_INPUT_VALUE,
  SET_ERROR_TYPE,
  SHOW_DEVICE_SEARCH,
  SHOW_TESTING_SCREEN,
  DEVICE_SEARCH_SET_INPUT_FOCUS_COMPLETED,
} from '../constants/action-types';
import * as errorTypes from '../constants/error-types';

export const blurDeviceSearchInput = createAction(DEVICE_SEARCH_INPUT_BLUR);

export const focusA11yAnnouncer = createAction(
  A11Y_ANNOUNCER_FOCUS,
  (description = 'Xfinity xFi Speed Test') => ({
    payload: {
      description,
    },
  })
);

export const focusDeviceSearchInput = createAction(
  DEVICE_SEARCH_SET_INPUT_FOCUS_STARTED
);
export const focusDeviceSearchInputCompleted = createAction(
  DEVICE_SEARCH_SET_INPUT_FOCUS_COMPLETED
);

export const makeScreenReaderAnnouncement = createAction(
  MAKE_SCREEN_READER_ANNOUNCEMENT,
  ({ message = '', politeness = 'polite', type = 'MESSAGE' } = {}) => ({
    payload: {
      message,
      politeness,
      type,
    },
  })
);

export const hideDeviceSearch = createAction(HIDE_DEVICE_SEARCH);

export const hideTestingScreen = createAction(HIDE_TESTING_SCREEN);

export const showDeviceSearch = createAction(SHOW_DEVICE_SEARCH);

export const showTestingScreen = createAction(SHOW_TESTING_SCREEN);

export const setDeviceSearchInputValue = createAction(
  SET_DEVICE_SEARCH_INPUT_VALUE,
  (deviceSearchInputValue: string) => ({
    payload: deviceSearchInputValue,
  })
);

export const setErrorType = createAction(
  SET_ERROR_TYPE,
  (errorType: UnionOf<typeof errorTypes>) => ({
    payload: {
      errorType,
    },
  })
);
