import React, { useEffect, Suspense, lazy, HTMLAttributes } from 'react';
import HomePageContent from '../../components/HomePageContent/HomePageContent';
import AdvancedSettings from '../../components/AdvancedSettings/AdvancedSettings';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import { APP_RENDERED_MARK } from '../../api/constants/performance-marks';
import { markPerformance as markPerformanceAction } from '../../api/actions/performance';
import { requestTest as requestTestAction } from '../../api/actions/request-test';
import TipsAndToolsAccordions from '../../components/TipsAndToolsAccordions/TipsAndToolsAccordions';
import { requestRoute as requestRouteAction } from '../../api/speedtest-interface';
import { animationLoaded as animationLoadedAction } from '../../api/actions/testing-animation';
import {
  setServerLocation as setServerLocationAction,
  setSelectedProtocol as setSelectedProtocolAction,
} from '../../api/actions/advanced-settings';
import {
  speedtestCloseModal as speedtestCloseModalAction,
  speedtestOpenModal as speedtestOpenModalAction,
} from '../../api/actions/modals';
import { useLocalization } from '../../hooks/localization';

interface HomePageScreenProps extends HTMLAttributes<HTMLDivElement> {
  markPerformance: Dispatch<typeof markPerformanceAction>;
  compareByCategoryLink: string;
  deviceBestName: string;
  requestRoute: Dispatch<typeof requestRouteAction>;
  requestTest: Dispatch<typeof requestTestAction>;
  testPlansStatus: string;
}

const TestingScreen = lazy(() =>
  import('../../components/TestingScreen/TestingScreen')
);

const HomePageScreen = (props: HomePageScreenProps) => {
  useEffect(() => {
    const { markPerformance } = props;

    if (markPerformance) {
      window.requestAnimationFrame(() => {
        markPerformance(APP_RENDERED_MARK);
      });
    }
  }, []);

  const {
    compareByCategoryLink,
    deviceBestName,
    requestRoute,
    requestTest,
    testPlansStatus,
  } = props;
  const t = useLocalization();
  const tipsAndToolsHeader = t('home_tipsAndTools_header');
  return (
    <>
      <div className="container p-4 pb-16">
        <div>
          <HomePageContent
            deviceBestName={deviceBestName}
            requestTest={requestTest}
            testPlansStatus={testPlansStatus}
          />
        </div>
      </div>
      <SectionHeader className="container px-4" header={tipsAndToolsHeader} />
      <TipsAndToolsAccordions
        compareByCategoryLink={compareByCategoryLink}
        requestRoute={requestRoute}
      />
    </>
  );
};

interface Props {
  compareByCategoryLink: string;
  currentDownloadSpeed: string;
  deviceBestName: string;
  fetchServerLocationStatus: string;
  hasBothProtocols: boolean;
  isAdvancedSettingsModalOpen: boolean;
  selectedProtocol: string;
  selectedServerLocation: string;
  serverLocation: { label: string; value: string }[];
  showTestingScreen: boolean;
  testPlansStatus: string;
  animationLoaded: Dispatch<typeof animationLoadedAction>;
  markPerformance: Dispatch<typeof markPerformanceAction>;
  requestRoute: Dispatch<typeof requestRouteAction>;
  requestTest: Dispatch<typeof requestTestAction>;
  setSelectedProtocol: Dispatch<typeof setSelectedProtocolAction>;
  setServerLocation: Dispatch<typeof setServerLocationAction>;
  speedtestCloseModal: Dispatch<typeof speedtestCloseModalAction>;
  speedtestOpenModal: Dispatch<typeof speedtestOpenModalAction>;
}

const HomePage = ({
  animationLoaded,
  compareByCategoryLink,
  currentDownloadSpeed = '0',
  deviceBestName = '',
  fetchServerLocationStatus,
  hasBothProtocols,
  isAdvancedSettingsModalOpen,
  markPerformance,
  requestRoute,
  requestTest,
  selectedProtocol,
  selectedServerLocation,
  serverLocation,
  setSelectedProtocol,
  setServerLocation,
  showTestingScreen = false,
  speedtestCloseModal,
  testPlansStatus,
}: Props) => {
  return (
    <div>
      {showTestingScreen ? (
        <Suspense fallback={<div />}>
          <TestingScreen
            currentDownloadSpeed={currentDownloadSpeed}
            onAnimationLoaded={animationLoaded}
          />
        </Suspense>
      ) : (
        <HomePageScreen
          compareByCategoryLink={compareByCategoryLink}
          deviceBestName={deviceBestName}
          markPerformance={markPerformance}
          requestRoute={requestRoute}
          requestTest={requestTest}
          testPlansStatus={testPlansStatus}
        />
      )}
      <AdvancedSettings
        fetchServerLocationStatus={fetchServerLocationStatus}
        hasBothProtocols={hasBothProtocols}
        isAdvancedSettingsModalOpen={isAdvancedSettingsModalOpen}
        requestTest={requestTest}
        selectedProtocol={selectedProtocol}
        selectedServerLocation={selectedServerLocation}
        serverLocation={serverLocation}
        setSelectedProtocol={setSelectedProtocol}
        setServerLocation={setServerLocation}
        speedtestCloseModal={speedtestCloseModal}
      />
    </div>
  );
};

export default HomePage;
