import { AnnouncerType } from '../../components/AccessibilityAnnouncer/AccessibilityAnnouncer';
import { Capability } from '../../components/CapabilitiesList/CapabilitiesList';
import { TFunction } from 'i18next';

/**
 * Create an accessibility label based on Speed Test result.
 *
 * @param {TFunction} t translations function (normally, the `t` function from `i18next`)
 * @param {Capability[]} capabilities capabilities list
 * @param {String} title result title
 * @param {String} message result message
 * @param {String} downloadSpeed the result download speed
 *
 * @returns {String} an accessibility label based on Speed Test results
 */
export const getAccessibilityLabel = (
  t: TFunction,
  capabilities: Capability[],
  title: string,
  message: string,
  downloadSpeed: string
): string => {
  let checksPassed: string[] = [];
  let checksFailed: string[] = [];

  capabilities.forEach(capability =>
    capability.check
      ? checksPassed.push(capability.text)
      : checksFailed.push(capability.text)
  );

  return (
    t('accessibility_testComplete') +
    ' ' +
    title +
    '. ' +
    message +
    ' ' +
    (checksPassed.length > 0
      ? t('accessibility_checksPassed', {
          checks: checksPassed.join(', '),
        })
      : '') +
    ' ' +
    (checksFailed.length > 0
      ? t('accessibility_checksFailed', {
          checks: checksFailed.join(', '),
        })
      : '') +
    ' ' +
    t('accessibility_downloadSpeed', { speed: downloadSpeed })
  );
};

/**
 * Creates a message for NVDA based on accessibility announcer type.
 *
 * @param {TFunction} t translations function (normally, the `t` function from `i18next`)
 * @param {AnnouncerType} type announcer message type
 * @param {String} message message to show
 *
 * @returns {String} a message for NVDA
 *
 * @see AccessibilityAnnouncer component for details
 */
export const getAnnouncerMessage = (
  t: TFunction,
  type: AnnouncerType,
  message: string
): string => {
  switch (type) {
    case 'PAGE_LOADED':
      return t('accessibility_pageLoaded', { description: t(message) });

    case 'DEVICE_SUGGESTIONS': {
      let suggestionsCount = parseInt(message);
      let resultMessage = '';
      //We are using `plural` feature from i18next, but there is also a
      //dedicated copy for 0 device suggeestions
      suggestionsCount === 0
        ? (resultMessage = t('accessibility_noSuggestionsFound'))
        : (resultMessage = t('accessibility_suggestionsFound', {
            count: suggestionsCount,
          }));

      return resultMessage;
    }

    case 'CLEAR':
      return '';

    case 'MESSAGE':
    default:
      return message;
  }
};
