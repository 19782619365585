import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { HomeContainer, ErrorContainer } from '../../containers';

/**
 * Contains all valid routes used in the app.
 * TODO: Remove this object in favor of the SITE_MAP
 */
export const ROUTES = {
  HOME: '/',
  RESULTS: '/results',
  COMPARE_BY_CATEGORY: '/devicespeeds',
  KNOWN_DEVICE: '/devicespeeds/:id',
  TIPS_AND_TOOLS: '/tips-and-tools',
  DEVICE_VS_PLAN: '/tips-and-tools/device-vs-plan',
  NON_SPEED_PROBLEMS: '/tips-and-tools/non-speed-problems',
  IMPROVE_SPEED: '/tips-and-tools/improve-speed',
  ERROR: '/error',
} as const;

/**
 * A strong type definition for a route. This bind the page titles to the routes.
 */
export type Route = keyof typeof ROUTES;

/**
 * Associate the pages with the routes. Every element is used for routing purpose.
 */
/* istanbul ignore next */
export const ROUTES_MAPPING: Record<Route, RouteProps> = {
  COMPARE_BY_CATEGORY: {
    component: lazy(() =>
      import(
        /* webpackChunkName: "CompareByCategory" */ '../../containers/CompareByCategory'
      )
    ),
    exact: true,
  },

  DEVICE_VS_PLAN: {
    component: lazy(() =>
      import(
        /* webpackChunkName: "DeviceVsPlanSpeed" */ '../../containers/DeviceVsPlanSpeed'
      )
    ),
    exact: true,
  },

  HOME: {
    component: HomeContainer,
    exact: true,
  },

  IMPROVE_SPEED: {
    component: lazy(() =>
      import(
        /* webpackChunkName: "ImproveSpeed" */ '../../containers/ImproveSpeed'
      )
    ),
    exact: true,
  },

  KNOWN_DEVICE: {
    component: lazy(() =>
      import(
        /* webpackChunkName: "KnownDevice" */ '../../containers/KnownDevice'
      )
    ),
    exact: false,
  },

  NON_SPEED_PROBLEMS: {
    component: lazy(() =>
      import(
        /* webpackChunkName: "NonSpeedProblems" */ '../../containers/NonSpeedProblems'
      )
    ),
    exact: true,
  },

  RESULTS: {
    component: lazy(() =>
      import(/* webpackChunkName: "Results" */ '../../containers/Results')
    ),
    exact: true,
  },

  TIPS_AND_TOOLS: {
    component: lazy(() =>
      import(
        /* webpackChunkName: "TipsAndTools" */ '../../containers/TipsAndTools'
      )
    ),
    exact: true,
  },

  ERROR: {
    component: ErrorContainer,
    exact: true,
  },
};
