import React from 'react';

import CompareByCategoryContent from '../../components/CompareByCategory/CompareByCategory';
import DeviceSearch from '../../components/DeviceSearch/DeviceSearch';
import { COMPARE_BY_CATEGORY_CONTENT } from '../../api/constants/bids';
import { LOOKUP_DEVICES_BUTTON } from '../../api/constants/bids';
import {
  blurDeviceSearchInput as blurDeviceSearchInputAction,
  focusDeviceSearchInput as focusDeviceSearchInputAction,
  hideDeviceSearch as hideDeviceSearchAction,
  setDeviceSearchInputValue as setDeviceSearchInputValueAction,
  showDeviceSearch as showDeviceSearchAction,
} from '../../api/actions/ui';
import { makeScreenReaderAnnouncement as makeScreenReaderAnnouncementAction } from '../../api/actions/ui';
import { requestRoute as requestRouteAction } from '../../api/actions/navigation';
import { fetchDeviceSuggestionsStart as fetchDeviceSuggestionsStartAction } from '../../api/actions/device-suggestions';
import { useLocalization } from '../../hooks/localization';
interface Props {
  deviceSuggestions: {
    displayName: string;
    id: string;
  }[];
  deviceSearchInputValue: string;
  deviceSearchIsActive: boolean;
  deviceSearchUIState?: string;
  deviceSuggestionsAreVisible: boolean;
  planDownloadSpeed: number;
  blurDeviceSearchInput: Dispatch<typeof blurDeviceSearchInputAction>;
  focusDeviceSearchInput: Dispatch<typeof focusDeviceSearchInputAction>;
  fetchDeviceSuggestionsStart: Dispatch<
    typeof fetchDeviceSuggestionsStartAction
  >;
  hideDeviceSearch: Dispatch<typeof hideDeviceSearchAction>;
  isSearchHintVisible: boolean;
  makeScreenReaderAnnouncement: Dispatch<
    typeof makeScreenReaderAnnouncementAction
  >;
  requestRoute: Dispatch<typeof requestRouteAction>;
  setDeviceSearchInputValue: Dispatch<typeof setDeviceSearchInputValueAction>;
  showDeviceSearch: Dispatch<typeof showDeviceSearchAction>;
}

const CompareByCategoryPage = (props: Props) => {
  const {
    deviceSearchIsActive,
    hideDeviceSearch,
    setDeviceSearchInputValue,
  } = props;
  const { planDownloadSpeed, ...deviceSearchProps } = props;
  const t = useLocalization();

  const compareByCategoryLink = () => (
    <button
      className="mt-4 link text-body1"
      data-bid={LOOKUP_DEVICES_BUTTON}
      onClick={() => {
        setDeviceSearchInputValue('');
        hideDeviceSearch();
      }}
    >
      {t('compareByCategory_lookup')}
    </button>
  );

  return (
    <>
      <DeviceSearch
        compareByCategoryLink={compareByCategoryLink}
        deviceSearchId="device-search-autocomplete"
        {...deviceSearchProps}
      />

      {!deviceSearchIsActive && (
        <div
          className="container md:px-4 md:mt-8"
          data-bid={COMPARE_BY_CATEGORY_CONTENT}
        >
          <CompareByCategoryContent planDownloadSpeed={planDownloadSpeed} />
        </div>
      )}
    </>
  );
};

export default CompareByCategoryPage;
