/**
 * @fileoverview Helper functions to calculate information related to the current state of tests.
 */
// Third-party libraries
import trimEnd from 'lodash/trimEnd';
// Our code
import { ROUTES } from '../constants/routes';
import { BUSY } from '../constants/statuses';
import { getDownloadTestStatus, getShowTestingScreen } from './state-getters';
import { RootState } from '../store-types';

/**
 * Checks if we're on the route for the home / testing
 * screen.
 *
 * @function
 * @param {String} currentRoute
 * @param {String} rootRoute
 * @returns {Boolean} Whether we're on the route for the home / testing screen
 */
export const isOnHomeAndTestingRoute = (
  currentRoute: string,
  rootRoute: string
) => {
  // The testing view currently resides on the `HOME` route
  const homeAndTestingRoute = `${rootRoute}${ROUTES.HOME}`;
  /*
    Why `trimEnd`? Great question! At the time of writing,
    our testing route is the same as our home route. They're
    both simply `/`. In xFi, our `rootRoute` is `/speedtest`. It would
    stand to reason that in xFi the testing route would be `/speedtest/`,
    but it's not. The reason is because when xFi processes route
    changes, that trailing `/` is removed. Therefore, prior
    to comparing the two routes, we're "sanitizing" them both
    by removing any trailing `/`.
  */
  return trimEnd(currentRoute, '/') === trimEnd(homeAndTestingRoute, '/');
};

/**
 * Checks if we're on the route for the home / testing screen
 * and whether or not the testing screen is currently displayed.
 *
 * @function
 * @param {Object} state The full state object
 * @param {Object} speedTestContext Interface to work with ST within context
 * of the full app
 * @returns {Boolean} Whether or not the testing screen is currently displayed
 */
export const isTestingScreenDisplayed = (
  speedTestState: RootState,
  currentRoute: string,
  rootRoute: string
) =>
  isOnHomeAndTestingRoute(currentRoute, rootRoute) &&
  getShowTestingScreen(speedTestState);

/**
 * Determines whether a download test is in progress.
 *
 * @function
 * @param {Object} state The full state object
 * @param {Object} speedTestContext Interface to work with ST within context
 * of the full app
 * @returns {Boolean} Whether a download test is in progress
 */
export const isTestInProgress = (speedTestState: RootState) =>
  getDownloadTestStatus(speedTestState) === BUSY;

export default {
  isOnHomeAndTestingRoute,
  isTestingScreenDisplayed,
  isTestInProgress,
};
