import React, { ReactNode } from 'react';
import classnames from 'classnames';
import clb from 'clb';

import XdsButtonStyles from './XdsButton.module.css';
import Link from '../Link/Link';

export interface ButtonProps {
  as?: 'button' | 'a';
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  href?: string;
  muted?: boolean;
  onClick?: any;
  size?: 'small' | 'medium';
  style?: any;
  requestRoute?: any;
  tone?: 'neutral' | 'positive' | 'negative' | 'theme1' | 'caution' | 'inverse';
  type?: 'button' | 'submit';
  variant?: 'stroke' | 'fill' | 'ghost';
  waiting?: boolean;
}

const classes = clb({
  base: 'pointer-cursor rounded-small ring-offset-2 focus:ring',
  variants: {
    variant: {
      stroke: ({ tone }) => ({
        'border-opacity-tertiary border-2': true,
        'hover:text-inverse-base focus:text-inverse-base': tone !== 'inverse',
        'text-neutral-base border-stroke-neutral-base hover:bg-fill-neutral-hover focus:bg-fill-neutral-hover active:bg-fill-neutral-down focus:ring-stroke-neutral-focus':
          tone === 'neutral',
        'text-negative-base border-stroke-negative-base hover:bg-fill-negative-hover focus:bg-fill-negative-hover active:bg-fill-negative-down focus:ring-stroke-negative-focus':
          tone === 'negative',
        'text-positive-base border-stroke-positive-base hover:bg-fill-positive-hover focus:bg-fill-positive-hover active:bg-fill-positive-down focus:ring-stroke-positive-focus':
          tone === 'positive',
        'text-theme1-base border-stroke-theme1-base hover:bg-fill-theme1-hover focus:bg-fill-theme1-hover active:bg-fill-theme1-down focus:ring-stroke-theme1-focus':
          tone === 'theme1',
        'text-caution-base border-stroke-caution-base hover:bg-fill-caution-hover focus:bg-fill-caution-hover active:bg-fill-caution-down focus:ring-stroke-caution-focus':
          tone === 'caution',
        'text-inverse-base border-stroke-inverse-base hover:text-neutral-base focus:text-neutral-base hover:bg-fill-inverse-hover focus:bg-fill-inverse-hover active:bg-fill-inverse-down focus:ring-stroke-inverse-focus':
          tone === 'inverse',
      }),
      fill: ({ tone }) => ({
        'border-transparent text-inverse-base': true,
        'focus:text-inverse-base hover:text-inverse-base': tone !== 'inverse',
        'bg-fill-neutral-base hover:bg-fill-neutral-hover focus:bg-fill-neutral-hover active:bg-fill-neutral-down focus:ring-stroke-neutral-focus':
          tone === 'neutral',
        'bg-fill-negative-base hover:bg-fill-negative-hover focus:bg-fill-negative-hover active:bg-fill-negative-down focus:ring-stroke-negative-focus':
          tone === 'negative',
        'bg-fill-positive-base hover:bg-fill-positive-hover focus:bg-fill-positive-hover active:bg-fill-positive-down focus:ring-stroke-positive-focus':
          tone === 'positive',
        'bg-fill-theme1-base hover:bg-fill-theme1-hover focus:bg-fill-theme1-hover active:bg-fill-theme1-down focus:ring-stroke-theme1-focus':
          tone === 'theme1',
        'bg-fill-caution-base hover:bg-fill-caution-hover focus:bg-fill-caution-hover active:bg-fill-caution-down focus:ring-stroke-caution-focus':
          tone === 'caution',
        'bg-fill-inverse-base hover:bg-fill-inverse-hover focus:bg-fill-inverse-hover active:bg-fill-inverse-down focus:ring-stroke-inverse-focus':
          tone === 'inverse',
      }),
      ghost: ({ tone }) => ({
        'text-neutral-base hover:text-neutral-hover active:text-neutral-down focus:ring-stroke-neutral-focus':
          tone === 'neutral',
        'text-negative-base hover:text-negative-hover active:text-negative-down focus:ring-stroke-negative-focus':
          tone === 'negative',
        'text-positive-base hover:text-positive-hover active:text-positive-down focus:ring-stroke-positive-focus':
          tone === 'positive',
        'text-theme1-base hover:text-theme1-hover active:text-theme1-down focus:ring-stroke-theme1-focus':
          tone === 'theme1',
        'text-caution-base hover:text-caution-hover active:text-caution-down focus:ring-stroke-caution-focus':
          tone === 'caution',
        'text-inverse-base hover:text-inverse-hover active:text-inverse-down focus:ring-stroke-inverse-focus':
          tone === 'inverse',
      }),
    },
    size: {
      small: 'px-6 py-3 text-button2',
      medium: 'px-8 py-4 text-button1',
    },
    muted: {
      true: 'opacity-30',
    },
  },
});

const XdsButton = ({
  as: Element = 'button',
  children,
  disabled,
  muted,
  size = 'medium',
  tone = 'neutral',
  type = 'button',
  variant = 'fill',
  waiting,
  onClick,
  ...props
}: ButtonProps) => {
  const { className, style, ...safeProps } = props;

  const classNames = classnames(
    classes({
      tone,
      size,
      variant,
      muted: muted || waiting,
    }),
    className,
    'select-none',
    {
      'pointer-events-none cursor-default': waiting,
    }
  );

  const isLink = Element === 'a';

  if (isLink) {
    return (
      <Link
        className={classNames}
        onClick={e => {
          if (waiting) {
            return e.preventDefault();
          }

          if (onClick) {
            return onClick(e);
          }

          return true;
        }}
        {...safeProps}
      >
        {waiting ? (
          <div className="flex items-center justify-center">
            <span className={classnames(XdsButtonStyles.spinner, 'mr-6')} />
            {children}
          </div>
        ) : (
          children
        )}
      </Link>
    );
  } else {
    return (
      <Element
        className={classNames}
        disabled={disabled}
        onClick={e => {
          if (waiting) {
            return e.preventDefault();
          }

          if (onClick) {
            return onClick(e);
          }

          return true;
        }}
        type={type}
        {...safeProps}
      >
        {waiting ? (
          <div className="flex items-center justify-center">
            <span className={classnames(XdsButtonStyles.spinner, 'mr-6')} />
            {children}
          </div>
        ) : (
          children
        )}
      </Element>
    );
  }
};

export default XdsButton;
