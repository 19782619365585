import { queryParams, smartFetch } from './data-helpers';
import { DeviceDetailsResponse } from '../network-interfaces/device-details';

export interface WurflFingerprint {
  advertised_browser: string;
  advertised_browser_version: string;
  advertised_device_os: string;
  advertised_device_os_version: string;
  brand_name: string;
  complete_device_name: string;
  form_factor: string;
  is_app_webview: boolean;
  is_full_desktop: boolean;
  is_mobile: boolean;
  is_robot: boolean;
  is_smartphone: boolean;
  is_smarttv: boolean;
  is_tablet: boolean;
  manufacturer_name: string;
  marketing_name: string;
  max_image_height: number;
  max_image_width: number;
  model_name: string;
  physical_screen_height: number;
  physical_screen_width: number;
  pointing_method: string;
  resolution_height: number;
  resolution_width: number;
}

/**
 * Provides device details for device when fingerprint data is provided
 * Usage: either marketName or modelName or both has to be present with at least one of them having
 * non-empty string value
 *
 * @param {String} endpoint
 * @returns {(fingerprintData: {marketName: String, modelName: String, formFactor: String, operatingSystem: String}) => Promise}
 */
export function fetchDeviceDetailsByFingerprint(endpoint: string) {
  return function({
    marketName,
    modelName,
    formFactor,
    operatingSystem,
  }: {
    marketName?: string;
    modelName?: string;
    formFactor?: string;
    operatingSystem?: string;
  } = {}) {
    if (!marketName && !modelName && !formFactor && !operatingSystem) {
      return Promise.reject(
        new Error(
          'invalid marketName, modelName, formFactor and operatingSystem'
        )
      );
    }
    // Non-null assertions used below because we narrowed it above. TS doesn't yet recognize this format
    const params = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      MarketName: marketName!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ModelName: modelName!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      FormFactor: formFactor!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      OS: operatingSystem!,
    };
    return smartFetch<DeviceDetailsResponse>(
      `${endpoint}/?${queryParams(params)}`,
      {
        cache: 'default',
      }
    );
  };
}

/**
 * Sends device and session info to IPIE
 *
 * @param {String} endpoint
 * @returns {(options: {sessionId: String, deviceFingerprint: String}) => Promise}
 */
export function postDeviceFingerprint(endpoint: string) {
  return function({
    sessionId,
    deviceFingerprint,
  }: {
    sessionId: string;
    deviceFingerprint: WurflFingerprint;
  }) {
    const postBody = {
      wurfl: deviceFingerprint,
    };
    return smartFetch(`${endpoint}/${sessionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postBody),
    });
  };
}

export default {
  postDeviceFingerprint,
};
