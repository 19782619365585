/**
 * @fileoverview Determines when all relevant performance events have been marked and then
 * calculates and logs metrics based on those marks. Depends on the `gather-performance`
 * module to calculate the metrics and the `TRACK_METRICS` action to submit them.
 */
// Third-party libraries
import { zip } from 'rxjs';
import { delay, first, map } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
// Our code
import {
  PERF as PERF_LOG_EVENT,
  SECONDARY_PERF as SECONDARY_PERF_LOG_EVENT,
} from '../constants/log-events';
import { APP_FULLY_LOADED, APP_READY } from '../constants/action-types';
import { fromLoadEvent } from './epic-helpers';
import {
  gatherPerfData,
  gatherSecondaryPerfData,
} from '../../modules/performance/gather-performance';
import { trackMetrics } from '../actions/logger';
import { RootState, RootAction, EpicDependencies } from '../store-types';

/**
 * Gathers and logs primary app loading performance metrics after the app is determined to be usable
 * and the whole page has loaded, including all dependent resources such as stylesheets and images.
 *
 * @param {Object} action$ Observable stream of actions
 * @param {Object} state$ [NOT USED] Observable stream of state changes
 * @param {Object} dependencies Injected dependencies
 * @param {Object} dependencies.resourceTiming `surfnperf/resource-timing` module
 * @param {Object} dependencies.surfnperf `surfnperf` module
 * @param {Object} dependencies.window window to listen for `load` and gather data from
 * @returns {Object} Observable stream of actions
 */
export const logPerformanceEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { resourceTiming, surfnperf, window }) => {
  const appReady$ = action$.pipe(
    ofType(APP_READY),
    first()
  );
  const windowLoadEvent$ = fromLoadEvent(window);

  return zip(appReady$, windowLoadEvent$).pipe(
    delay(1), // Add delay to make sure the last mark has been made
    map(() =>
      trackMetrics(
        PERF_LOG_EVENT,
        gatherPerfData({
          resourceTiming,
          surfnperf,
          window,
        })
      )
    )
  );
};

/**
 * Gathers and logs secondary app loading performance metrics after the app is fully loaded,
 * including the whole page, the page's dependent resources, and all data calls.
 *
 * @param {Object} action$ Observable stream of actions
 * @param {Object} state$ [NOT USED] Observable stream of state changes
 * @param {Object} {surfnperf} `surfnperf` module
 * @returns {Object} Observable stream of actions
 */
export const logSecondaryPerformanceEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { surfnperf }) => {
  const appFullyLoaded$ = action$.pipe(
    ofType(APP_FULLY_LOADED),
    first()
  );

  return appFullyLoaded$.pipe(
    delay(1), // Add delay to make sure the last mark has been made
    map(() =>
      trackMetrics(
        SECONDARY_PERF_LOG_EVENT,
        gatherSecondaryPerfData({ surfnperf }),
        'surfNperf'
      )
    )
  );
};

export default {
  logPerformanceEpic,
  logSecondaryPerformanceEpic,
};
