import { ofType, Epic } from 'redux-observable';
import { ignoreElements, tap } from 'rxjs/operators';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';

import { RootState, RootAction, EpicDependencies } from '../store-types';

/**
 * On LOCATION_CHANGE, use the Google Analytics tracker (`ga`),
 * to send a 'pageview' to Google Analytics.
 */
export const logPageChange: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { ga }) => {
  return action$.pipe(
    ofType(LOCATION_CHANGE),
    tap((action: LocationChangeAction) => {
      ga('send', 'pageview', action.payload.location.pathname);
    }),
    ignoreElements()
  );
};
