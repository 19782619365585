// Third-party Libraries
import { connect } from 'react-redux';
import get from 'lodash/get';
// Our Code
import { fetchDeviceSuggestionsStart } from '../api/actions/device-suggestions';
import {
  getDeviceSearchUIState,
  getDeviceSuggestionsAreVisible,
  getIsSearchHintVisible,
} from '../api/selectors/view-state/device-search';
import KnownDevicePage from '../pages/KnownDevice/KnownDevicePage';
import {
  blurDeviceSearchInput,
  focusDeviceSearchInput,
  hideDeviceSearch,
  showDeviceSearch,
  setDeviceSearchInputValue,
} from '../api/actions/ui';
import { requestRoute } from '../api/actions/navigation';
import { RootState } from '../api/store-types';

const mapStateToProps = (state: RootState) => ({
  deviceDetails: get(state, 'deviceDetails.data'),
  deviceSearchInputValue: state.ui.deviceSearchInputValue,
  deviceSearchIsActive: state.ui.showDeviceSearch,
  deviceSearchUIState: getDeviceSearchUIState(state),
  deviceSuggestions: state.deviceSuggestions.data || [],
  deviceSuggestionsAreVisible: getDeviceSuggestionsAreVisible(state),
  isSearchHintVisible: getIsSearchHintVisible(state),
  knownDeviceId: get(state, 'deviceFingerprint.deviceDetails.deviceId', ''),
  planSpeed: get(state, 'network.planSpeed', ''),
});

const actionCreators = {
  blurDeviceSearchInput,
  focusDeviceSearchInput,
  fetchDeviceSuggestionsStart,
  hideDeviceSearch,
  requestRoute,
  setDeviceSearchInputValue,
  showDeviceSearch,
};

export default connect(
  mapStateToProps,
  actionCreators
)(KnownDevicePage);
