// Third-party Libraries
import { createSelector } from 'reselect';
// Our code
import {
  DEVICE_SEARCH_STATE_INITIAL,
  DEVICE_SEARCH_STATE_NO_DEVICE_FOUND,
} from '../../constants/states';
import * as STATUSES from '../../constants/statuses';
import { RootState } from '../../store-types';
import { ROUTES } from '../../constants/routes';

export const getCompareByCategoryLink = createSelector(
  (state: RootState) => state.deviceFingerprint.deviceDetails.deviceId,
  (state: RootState) =>
    state.deviceFingerprint.deviceDetails.expectedDownloadSpeed,
  (knownDeviceId, expectedDownloadSpeed) =>
    `${ROUTES.COMPARE_BY_CATEGORY}${
      expectedDownloadSpeed !== null ? `/${knownDeviceId}` : ''
    }`
  // if the expectedDownloadSpeed is missing from device details,
  // we shouldn't append the knownDeviceId
);

export const hasResultDeviceSuggestions = createSelector(
  (state: RootState) => state.deviceSuggestions.statuses.fetch.status,
  deviceSuggestionsFetchStatus =>
    deviceSuggestionsFetchStatus === STATUSES.READY
);

export const getDeviceSearchUIState = createSelector(
  (state: RootState) => state.ui.deviceSearchInputValue,
  (state: RootState) => state.deviceSuggestions.data || [],
  hasResultDeviceSuggestions,
  (
    deviceSearchInputValue,
    deviceSuggestions,
    deviceSuggestionsFetchStatusIsReady
  ) => {
    if (deviceSearchInputValue.length === 0) {
      return DEVICE_SEARCH_STATE_INITIAL;
    }

    if (deviceSuggestions.length === 0 && deviceSuggestionsFetchStatusIsReady) {
      return DEVICE_SEARCH_STATE_NO_DEVICE_FOUND;
    }
  }
);

export const getDeviceSuggestionsAreVisible = createSelector(
  getDeviceSearchUIState,
  state => state.ui.deviceSearchInputIsFocused,
  state => state.deviceSuggestions.data || [],
  (deviceSearchUIState, deviceSearchInputIsFocused, deviceSuggestions) =>
    deviceSearchUIState !== DEVICE_SEARCH_STATE_INITIAL &&
    deviceSearchInputIsFocused &&
    deviceSuggestions.length > 0
);

export const getIsSearchHintVisible = createSelector(
  (state: RootState) => state.ui.deviceSearchInputValue,
  (state: RootState) => state.deviceSuggestions.data || [],
  (state: RootState) => getDeviceSuggestionsAreVisible(state),
  (deviceSearchInputValue, deviceSuggestions, deviceSuggestionsAreVisible) => {
    const firstDeviceSuggestion = deviceSuggestions[0];

    if (!firstDeviceSuggestion || !deviceSearchInputValue) return false;

    if (
      deviceSuggestionsAreVisible &&
      firstDeviceSuggestion.displayName
        .toLowerCase()
        .startsWith(deviceSearchInputValue.toLowerCase())
    )
      return true;

    return false;
  }
);
