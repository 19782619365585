// Third-party libraries
// TODO: Switch to 'lodash-es' - CIMSI-10109
import { ofType, Epic } from 'redux-observable';
import { filter, map, mapTo, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
// Our code
import { RootState, RootAction, EpicDependencies } from '../store-types';
// Constants
import {
  REQUEST_TEST,
  SERVER_LOCATION_FETCH_COMPLETED,
  SERVER_LOCATION_FETCH_STARTED,
  SET_SERVER_LOCATION,
  SPEEDTEST__OPEN_MODAL,
} from '../constants/action-types';
import { ADVANCED_SETTINGS } from '../../api/constants/modals';
// Actions
import {
  fetchServerLocationComplete,
  fetchServerLocationStart,
  setSecureUrls,
  setSelectedProtocol,
  setServerLocation,
} from '../actions/advanced-settings';
import { speedtestCloseModal } from '../actions/modals';
// Everything else
import { fetchData, getEndpoints } from './epic-helpers';
import { fetchServerLocation } from '../network-interfaces/advanced-settings';
import { createAdvancedSettingsTestUrls } from './test-helpers';

export const fetchServerLocationEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(SERVER_LOCATION_FETCH_STARTED),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    mergeMap(([action, endpoints]) =>
      of(action).pipe(
        fetchData(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          fetchServerLocation(endpoints!.SERVER_LOCATION_ENDPOINT),
          response => fetchServerLocationComplete(null, response),
          error => fetchServerLocationComplete(error, null),
          action => action.payload.serverLocation
        )
      )
    )
  );
};

export const setServerLocationEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = action$ => {
  return action$.pipe(
    ofType(SET_SERVER_LOCATION),
    map(({ payload }) => fetchServerLocationStart(payload.serverLocation))
  );
};

/**
 * Generates the secure secureBaseUrlIPv4 and secureBaseUrlIPv6 from the data returned in the /selectserver api call
 */
export const createAdvancedSettingsTestUrlsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(SERVER_LOCATION_FETCH_COMPLETED),
    filter(
      (action: ReturnType<typeof fetchServerLocationComplete>) => !action.error
    ),
    withLatestFrom(state$, (action, state) => state),
    map(speedTestContext.getState),
    map(({ advancedSettings }) => {
      const secureUrls = createAdvancedSettingsTestUrls(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        advancedSettings.serverLocationData!.Fqdn
      );
      return secureUrls;
    }),
    map(({ serverLocationSecureIPv4Url, serverLocationSecureIPv6Url }) =>
      setSecureUrls(serverLocationSecureIPv4Url, serverLocationSecureIPv6Url)
    )
  );
};

/**
 * Sets 'selectedProtocol' and 'serverLocation' in state when the Advanced Settings modal is open for when the test is started when no changes to the default are made
 */
export const setAdvancedSettingsDataEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(SPEEDTEST__OPEN_MODAL),
    filter(({ payload }) => payload === ADVANCED_SETTINGS),
    withLatestFrom(state$, (action, state) => state),

    map(speedTestContext.getState),

    mergeMap((state: RootState) => {
      const clientHasIPv6 = state.network.clientHasIPv6;
      const protocol = clientHasIPv6 ? 'IPv6' : 'IPv4';
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const serverLocation = state.test.plans!.sitename;

      return [
        setSelectedProtocol(protocol),
        setServerLocation(null, serverLocation),
      ];
    })
  );
};

/**
 * Closes the Advanced Settings Modal after a test is run from the AS modal
 */
export const closeAdvancedSettingsModalEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(REQUEST_TEST),
    withLatestFrom(state$, (action, state) => state),
    map(speedTestContext.getState),
    filter((state: RootState) => state.modals.isOpen),
    mapTo(speedtestCloseModal(ADVANCED_SETTINGS))
  );
};
