// Our code
/*eslint sort-imports: "error"*/
import advancedSettings from './advanced-settings';
import appConfig from './app-config';
import deviceDetails from './device-details';
import deviceFingerprint from './device-fingerprint';
import deviceSuggestions from './device-suggestions';
import modals from './modals';
import network from './network';
import test from './test';
import ui from './ui';
import xfiClientCoreSessionReducer from 'xfi-client-core/src/reducers/session';

/*eslint sort-keys: "error"*/
export const reducers = {
  advancedSettings,
  appConfig,
  deviceDetails,
  deviceFingerprint,
  deviceSuggestions,
  modals,
  network,
  session: xfiClientCoreSessionReducer,
  test,
  ui,
};
