import { smartFetch } from './data-helpers';

export interface DeviceDetailsResponse {
  id: string;
  displayName: string;
  formFactor: string;
  downloadSpeed: string;
  similarDevices: {
    title: string;
    devices: {
      id: string;
      displayName: string;
      downloadSpeed: string;
      formFactor: string;
    }[];
    maxDownloadSpeed: string;
  };
}

/**
 * fetchDeviceDetails provides device details for device when uuid is provided
 * throws an error when empty uuid is provide
 * @param {String} endpoint
 * @returns {(uuid: String) => Promise}
 */
export function fetchDeviceDetails(endpoint: string) {
  return function(uuid?: string) {
    if (!uuid) {
      throw new Error('invalid uuid');
    }
    return smartFetch<DeviceDetailsResponse>(`${endpoint}/${uuid}`, {
      cache: 'default',
    });
  };
}

export default {
  fetchDeviceDetails,
};
