// Third-party libraries
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
// Comcast open source
import surfnperf from 'surfnperf';
import resourceTiming from 'surfnperf/resource-timing';
// Our code
import { epics } from './epics';
import { reducers } from './reducers';
import { loadInitialDataStart } from './actions/load-initial-data';
import * as speedtestInterface from './speedtest-interface';
import { setInitialAppConfig } from './actions/app-config';
import { RootAction, RootState, EpicDependencies } from './store-types';

export interface AppConfig {
  endpoints: {
    DEVICE_DETAILS_ENDPOINT: string;
    DEVICE_FINGERPRINT_ENDPOINT: string;
    DEVICE_FINGERPRINT_POST_ENDPOINT: string;
    DEVICE_SUGGESTIONS_ENDPOINT: string;
    DOWNLOAD_TEST_RESULTS_POST_ENDPOINT: string;
    DOWNLOAD_TEST_URL: string;
    LATENCYS_ENDPOINT: string;
    LATENCY_AND_UPLOAD_TEST_RESULTS_POST_ENDPOINT: string;
    PLAN_INFO_ENDPOINT: string;
    PULSE_ENDPOINT: string;
    SERVER_LOCATION_ENDPOINT: string;
    SESSION_ID_ENDPOINT: string;
    TEST_CONFIG_POST_ENDPOINT: string;
    TEST_PLANS_ENDPOINT: string;
    UPLOAD_TEST_URL: string;
  };
  environment: 'development' | 'production' | 'staging';
  MELEE_CONFIG: {
    APP_NAME: string;
    APP_VERSION: string;
    PARTNER: string;
    TOKEN: string;
    ENDPOINT: string;
  };
  sessionId: string;
}

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    __SPEED_TEST_CONFIG__?: AppConfig;
    ga: any;
  }
}

export const history = createBrowserHistory();

export const allReducers = {
  ...reducers,
  router: connectRouter(history),
};

export const rootReducer = combineReducers(allReducers);

export const epicDependencies = {
  document: window.document,
  ga: window.ga, // Google Analytics tracker
  resourceTiming,
  speedTestContext: speedtestInterface,
  surfnperf,
  window,
};

const epicMiddleware = createEpicMiddleware<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
>({
  dependencies: epicDependencies,
});

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const middleware = [routerMiddleware(history), epicMiddleware];

export default function configureStore() {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );

  epicMiddleware.run(combineEpics(...epics));

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  store.dispatch(setInitialAppConfig(window.__SPEED_TEST_CONFIG__!));

  store.dispatch(loadInitialDataStart());

  return store;
}
