import React from 'react';
import ResultsHeading from '../ResultsHeading/ResultsHeading';
import CapabilitiesList from '../CapabilitiesList/CapabilitiesList';
import ResultsAccordion from '../ResultsAccordion/ResultsAccordion';
import { RESULTS_LINK_CTA } from '../../api/constants/bids';
import * as VEHICLES from '../../api/constants/vehicles';
import XdsButton from '../XdsButton/XdsButton';
import Balloon from './images/balloon.svg';
import Plane from './images/plane.svg';
import Jet from './images/jet.svg';
import Rocket from './images/rocket.svg';
import Warp from './images/warp.svg';
import { requestLatencyTest as requestLatencyTestAction } from '../../api/actions/latency-test';
import { requestRoute as requestSpeedTestRoute } from '../../api/speedtest-interface';
import { requestTest as requestTestAction } from '../../api/actions/request-test';
import { ROUTES } from '../../api/constants/routes';
import { RESULTS } from '../../api/constants/bids';
import { useLocalization } from '../../hooks/localization';

const IMAGES: Record<string, string> = {
  [VEHICLES.BALLOON]: Balloon,
  [VEHICLES.PLANE]: Plane,
  [VEHICLES.JET]: Jet,
  [VEHICLES.ROCKET]: Rocket,
  [VEHICLES.WARP]: Warp,
};

interface Props {
  buttonText: string;
  capabilities: {
    check: boolean;
    position: number;
    text: string;
  }[];
  compareByCategoryLink: string;
  ctaLink: string;
  deviceBestName: string;
  downloadSpeed: string;
  hostName: string;
  image: string;
  latencySpeed: {
    value: string;
    displaySpinner: boolean;
  };
  requestLatencyTest: Dispatch<typeof requestLatencyTestAction>;
  message: string;
  protocolVersion: string;
  requestRoute: Dispatch<typeof requestSpeedTestRoute>;
  requestTest: Dispatch<typeof requestTestAction>;
  title: string;
  uploadSpeed: {
    value: string;
    displaySpinner: boolean;
  };
  accessibilityLabel?: string;
}

const Results = ({
  buttonText,
  capabilities = [],
  compareByCategoryLink,
  ctaLink,
  deviceBestName = '',
  downloadSpeed,
  hostName,
  image,
  latencySpeed,
  requestLatencyTest,
  message,
  protocolVersion,
  requestRoute,
  requestTest,
  title,
  uploadSpeed,
  accessibilityLabel = '',
  ...props
}: Props) => {
  const CTA_LINKS: Record<string, string> = {
    DEVICE_LOOKUP: compareByCategoryLink,
    DEVICE_VS_PLAN: ROUTES.DEVICE_VS_PLAN,
    STILL_HAVING_ISSUES: ROUTES.NON_SPEED_PROBLEMS,
    SPEED_TIPS: ROUTES.IMPROVE_SPEED,
    GET_MORE_SPEED: 'https://www.xfinity.com/learn/internet-service/speed',
  };
  const routeLink = CTA_LINKS[ctaLink];
  const t = useLocalization();
  const deviceName = deviceBestName ? deviceBestName : t('result_device');

  return (
    <div className="py-4 md:mt-8" data-bid={RESULTS} {...props}>
      <div className="container flex justify-between px-4">
        <div
          className="md:w-3/4 lg:w-3/5"
          aria-label={accessibilityLabel}
          role="alert"
          aria-hidden={true}
        >
          <ResultsHeading title={title} message={message} />
          <CapabilitiesList
            className="mt-4 md:mt-8"
            capabilities={capabilities}
          />
        </div>
        <div className="flex-shrink-0 hidden md:block">
          <img className="block" src={IMAGES[image]} height="310" alt="" />
        </div>
      </div>

      <ResultsAccordion
        downloadSpeed={downloadSpeed}
        hostName={hostName}
        latencySpeed={latencySpeed}
        requestLatencyTest={requestLatencyTest}
        protocolVersion={protocolVersion}
        uploadSpeed={uploadSpeed}
      />
      <div className="flex flex-col items-center pt-6 pb-4 m-auto sm:py-8">
        <XdsButton
          tone="theme1"
          as="a"
          requestRoute={requestRoute}
          data-bid={RESULTS_LINK_CTA}
          href={routeLink}
        >
          {buttonText}
        </XdsButton>
        <XdsButton
          tone="theme1"
          variant="ghost"
          onClick={() => requestTest({ useAdvancedSettingsData: false })}
        >
          {t('result_testSpeedToDevice', { device: deviceName })}
        </XdsButton>
      </div>
    </div>
  );
};

export default Results;
