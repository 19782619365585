import React from 'react';
import { ReactComponent as DesktopIcon } from './icons/icon-device--laptop.svg';
import { ReactComponent as GamingIcon } from './icons/icon-device--gaming.svg';
import { ReactComponent as SmartPhoneIcon } from './icons/icon-device--phone.svg';
import { ReactComponent as TabletIcon } from './icons/icon-device--tablet.svg';

const icons: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  gaming: GamingIcon,
  desktop: DesktopIcon,
  laptop: DesktopIcon,
  smartphone: SmartPhoneIcon,
  tablet: TabletIcon,
};

interface Props extends React.SVGProps<SVGSVGElement> {
  type: string;
}

const DeviceIcon = ({ type = '', ...props }: Props) => {
  // We are defining smartphone as our default device to guard against unrecognized icon slugs
  const Icon = icons[type.toLowerCase()] || icons['smartphone'];
  return <Icon {...props} />;
};

export default DeviceIcon;
