import { createAction } from '@reduxjs/toolkit';

import { SET_INITIAL_APP_CONFIG } from './../constants/action-types';
import { AppConfig } from '../configureStore';

/**
 * Action creator for setting up initial appConfig data. These will be
 * properties that change per deployment environment
 */
export const setInitialAppConfig = createAction(
  SET_INITIAL_APP_CONFIG,
  (appConfig: AppConfig) => ({
    payload: { appConfig },
  })
);
