/**
 * @fileoverview Event keys used to mark events for collecting performance metrics.
 */

/**
 * Application specific marks
 */
export const APP_FAILURE_MARK = 'appFailure';
export const APP_FULLY_LOADED_MARK = 'appFullyLoaded';
export const APP_READY_MARK = 'appReady';
export const APP_RENDERED_MARK = 'appRendered';
export const ERROR_MARK = 'error';

/**
 * Data call marks
 */
export const DEVICE_DETAILS_BY_FINGERPRINT_FETCH_STARTED_MARK =
  'fetchDeviceDetailsByFingerprint';
export const DEVICE_DETAILS_BY_FINGERPRINT_FETCH_COMPLETED_MARK =
  'deviceDetailsByFingerprintLoaded';
export const DEVICE_FINGERPRINT_FETCH_STARTED_MARK = 'fetchDeviceFingerprint';
export const DEVICE_FINGERPRINT_FETCH_COMPLETED_MARK =
  'deviceFingerprintLoaded';
export const DEVICE_FINGERPRINT_POST_STARTED_MARK = 'postDeviceFingerprint';
export const DEVICE_FINGERPRINT_POST_COMPLETED_MARK = 'deviceFingerprintPosted';
export const LATENCYS_FETCH_COMPLETED_MARK = 'latencysLoaded';
export const LATENCYS_FETCH_STARTED_MARK = 'fetchLatencys';
export const SESSION_ID_FETCH_STARTED_MARK = 'fetchSessionId';
export const SESSION_ID_FETCH_COMPLETED_MARK = 'sessionIdLoaded';
export const TEST_PLANS_FETCH_STARTED_MARK = 'fetchTestPlans';
export const TEST_PLANS_FETCH_COMPLETED_MARK = 'testPlansLoaded';

/**
 * Generic {@link https://github.com/Comcast/Surf-N-Perf/wiki/JavaScript-API|Surf-N-Perf} and
 * {@link https://www.w3.org/TR/navigation-timing/#processing-model|Navigation Timing API} marks
 */
export const LOAD_EVENT_END_MARK = 'loadEventEnd';
export const NAVIGATION_START_MARK = 'navigationStart';
export const PAGE_START_MARK = 'pageStart';
