import { createAction } from '@reduxjs/toolkit';

import {
  DEVICE_DETAILS_BY_FINGERPRINT_FETCH_COMPLETED,
  DEVICE_DETAILS_BY_FINGERPRINT_FETCH_STARTED,
  DEVICE_FINGERPRINT_FETCH_COMPLETED,
  DEVICE_FINGERPRINT_FETCH_STARTED,
  DEVICE_FINGERPRINT_POST_COMPLETED,
  DEVICE_FINGERPRINT_POST_STARTED,
} from '../constants/action-types';
import { DeviceDetailsResponse } from '../network-interfaces/device-details';
import { WurflFingerprint } from '../network-interfaces/device-fingerprint';

interface FingerprintData {
  marketName: string;
  modelName: string;
  formFactor: string;
  operatingSystem: string;
}

export const fetchDeviceDetailsByFingerprintComplete = createAction(
  DEVICE_DETAILS_BY_FINGERPRINT_FETCH_COMPLETED,
  (error: Error | null, response: DeviceDetailsResponse | null) => ({
    payload: error || response,
    error: Boolean(error),
  })
);

/**
 * Fetches device details, including the best name for the device,
 * when given then marketing_name or model_name (as defined in the
 * device fingerprint data). It's likely that we'll only have one
 * or the other, but not both. This will still result in a valid
 * request.
 */
export const fetchDeviceDetailsByFingerprintStart = createAction(
  DEVICE_DETAILS_BY_FINGERPRINT_FETCH_STARTED,
  (fingerprintData: FingerprintData) => ({
    payload: {
      marketName: fingerprintData.marketName,
      modelName: fingerprintData.modelName,
      formFactor: fingerprintData.formFactor,
      operatingSystem: fingerprintData.operatingSystem,
    },
  })
);

/**
 * Action creator for signaling that loading the fingerprint data is complete
 * @example
 * fetchDeviceFingerprintComplete(
 *   null,
 *   {
 *     advertised_browser: 'Chrome',
 *     form_factor: 'Desktop',
 *     is_mobile: false
 *   }
 * )
 * // returns
 * // {
 * //   type: 'DEVICE_FINGERPRINT_FETCH_COMPLETED',
 * //   payload: {
 * //     advertised_browser: 'Chrome',
 * //     form_factor: 'Desktop',
 * //     is_mobile: false
 * //   },
 * //   error: false
 * // }
 */
export const fetchDeviceFingerprintComplete = createAction(
  DEVICE_FINGERPRINT_FETCH_COMPLETED,
  (error: Error | null, response: WurflFingerprint | null) => ({
    payload: error || response,
    error: Boolean(error),
  })
);

export const fetchDeviceFingerprintStart = createAction(
  DEVICE_FINGERPRINT_FETCH_STARTED
);

export const postDeviceFingerprintComplete = createAction(
  DEVICE_FINGERPRINT_POST_COMPLETED,
  (error?: Error) => ({
    payload: error,
    error: Boolean(error),
  })
);

export const postDeviceFingerprintStart = createAction(
  DEVICE_FINGERPRINT_POST_STARTED,
  (sessionId: string, deviceFingerprint: WurflFingerprint) => ({
    type: DEVICE_FINGERPRINT_POST_STARTED,
    payload: {
      sessionId,
      deviceFingerprint,
    },
  })
);
