// Third-party Libraries
import { connect } from 'react-redux';
// Our Code
import ErrorPage from '../pages/Error/ErrorPage';
import { GENERAL } from '../api/constants/error-types';
import { requestAppReload } from '../api/actions/navigation';
import { RootState } from '../api/store-types';

const defaultErrorType = GENERAL;

const mapStateToProps = (state: RootState) => ({
  errorType: state.ui.errorType || defaultErrorType,
});

const actionCreators = { requestAppReload };

export default connect(
  mapStateToProps,
  actionCreators
)(ErrorPage);
