import {
  debounceTime,
  map,
  mapTo,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType, Epic } from 'redux-observable';

import { fetchData, getEndpoints } from './epic-helpers';
import {
  DEVICE_SUGGESTIONS_FETCH_COMPLETED,
  DEVICE_SUGGESTIONS_FETCH_STARTED,
} from '../constants/action-types';
import { fetchDeviceSuggestions } from '../network-interfaces/device-suggestions';
import { fetchDeviceSuggestionsComplete } from '../actions/device-suggestions';
import { makeScreenReaderAnnouncement } from '../actions/ui';
import { RootState, RootAction, EpicDependencies } from '../store-types';

/**
 * Resets the screen reader property in state to ensure a screen reader
 * announcement is made each time device suggestions are retrieved.
 *
 * @function
 * @param {Object} action$ Observable stream of actions
 *
 * @returns {Object} Observable stream of actions
 */
export const clearDeviceSearchScreenReaderAnnouncementEpic: Epic<
  RootAction,
  RootAction
> = action$ => {
  return action$.pipe(
    ofType(DEVICE_SUGGESTIONS_FETCH_STARTED),
    mapTo(
      makeScreenReaderAnnouncement({
        message: '',
        type: 'CLEAR',
      })
    )
  );
};

export const fetchDeviceSuggestionsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(DEVICE_SUGGESTIONS_FETCH_STARTED),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    debounceTime(50),
    mergeMap(([action, endpoints]) =>
      of(action).pipe(
        fetchData(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          fetchDeviceSuggestions(endpoints!.DEVICE_SUGGESTIONS_ENDPOINT),
          response => fetchDeviceSuggestionsComplete(null, response),
          error => fetchDeviceSuggestionsComplete(error, null),
          action => action.payload && action.payload.text
        )
      )
    )
  );
};

/**
 * Whenever device suggestions are retrieved make a screen reader announcement
 * that tells the user how many device suggestions are found.
 *
 * @function
 * @param {Object} action$ Observable stream of actions
 * @param {Object} state$ Observable stream of state changes
 * @param {Object} dependencies Injected dependencies
 * @param {Object} dependencies.speedTestContext Interface to work with ST within context of full app
 *
 * @returns {Object} Observable stream of actions
 */
export const makeDeviceSearchScreenReaderAnnouncementEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(DEVICE_SUGGESTIONS_FETCH_COMPLETED),
    debounceTime(500),
    withLatestFrom(state$, (action, state) => state),
    map(speedTestContext.getState),
    map(state => {
      const deviceSuggestions = state.deviceSuggestions.data || [];
      const deviceSuggestionsCount = deviceSuggestions.length;

      return makeScreenReaderAnnouncement({
        message: deviceSuggestionsCount.toString(),
        type: 'DEVICE_SUGGESTIONS',
      });
    })
  );
};
