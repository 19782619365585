import React from 'react';

import Header from './images/header.svg';
import { ReactComponent as Computer } from './images/icon-device--laptop.svg';
import { ReactComponent as Phone } from './images/icon-device--phone.svg';
import { useLocalization } from '../../hooks/localization';

interface Props {
  planDownloadSpeed: number;
}

const CompareByCategory = ({ planDownloadSpeed }: Props) => {
  const t = useLocalization();
  return (
    <div className="flex flex-wrap">
      <div className="flex flex-col md:flex-row-reverse lg:pb-6">
        <div className="md:w-1/2 aspect-ratio aspect-ratio--1:2-3">
          <img className="w-full" src={Header} alt="" />
        </div>
        <div className="px-6 py-8 md:pt-0 md:pl-0 md:w-1/2">
          <h2 className="font-700 text-headline1 md:font-500">
            {t('compareByCategory_chart_title')}
          </h2>
          <p className="pt-4 text-body1 text-neutral2-base">
            {t('compareByCategory_chart_description')}
          </p>
        </div>
      </div>
      <div className="w-full px-6 pb-8 md:px-0 lg:flex">
        <div className="flex flex-row pb-6 md:pb-4 lg:pb-0 lg:pt-6 lg:border-grey-5 lg:border-t-1 lg:border-b-1 lg:border-l-1 lg:justify-center lg:flex-col lg:w-320">
          {/* TODO replace img with svg from si-assets after Eng story TBD */}
          <Computer className="w-50 h-50 lg:h-75 lg:w-75 lg:self-center " />
          <h3 className="self-center pl-6 lg:pl-0 lg:mt-4 text-headline3">
            {t('compareByCategory_chart_computer')}
          </h3>
        </div>
        <div className="lg:w-full">
          <table className="w-full text-body1">
            <thead className="text-neutral2-base">
              <tr>
                <th
                  scope="col"
                  className="w-1/2 border-grey-5 border-1 bg-grey-2 h-50 md:h-60"
                >
                  {t('compareByCategory_chart_yearColumn')}
                </th>
                <th
                  scope="col"
                  className="w-1/2 border-grey-5 border-1 bg-grey-2"
                >
                  {t('compareByCategory_chart_speedColumn')}
                </th>
              </tr>
            </thead>
            <tbody className="text-center text-neutral2-base">
              <tr>
                <td className="border-grey-5 border-1 h-50 md:h-60">
                  {t('compareByCategory_chart_computer_firstRow_year')}
                </td>
                <td className="border-grey-5 border-1">
                  {t('compareByCategory_chart_computer_firstRow_speed')}
                </td>
              </tr>
              <tr>
                <td className="border-grey-5 border-1 h-50 md:h-60">
                  {t('compareByCategory_chart_computer_secondRow_year')}
                </td>
                <td className="border-grey-5 border-1">
                  {t('compareByCategory_chart_computer_secondRow_speed')}
                </td>
              </tr>
              <tr>
                <td className="border-grey-5 border-1 h-50 md:h-60">
                  {t('compareByCategory_chart_computer_thirdRow_year')}
                </td>
                <td className="border-grey-5 border-1">
                  {t('compareByCategory_chart_computer_thirdRow_speed')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-full px-6 pb-8 md:px-0 lg:flex">
        <div className="flex flex-row pb-6 md:pb-4 lg:pb-0 lg:pt-8 lg:border-grey-5 lg:border-t-1 lg:border-b-1 lg:border-l-1 lg:justify-center lg:flex-col lg:w-320">
          {/* TODO replace img with svg from si-assets after Eng story TBD */}
          <Phone style={{ width: 50, height: 60 }} className="lg:self-center" />
          <h3 className="self-center pl-6 lg:pl-0 lg:mt-6 text-headline3">
            {t('compareByCategory_chart_mobile')}
          </h3>
        </div>
        <div className="lg:w-full">
          <table className="w-full text-body1">
            <thead className="text-neutral2-base">
              <tr>
                <th
                  scope="col"
                  className="w-1/2 border-grey-5 border-1 bg-grey-2 h-50 md:h-60"
                >
                  {t('compareByCategory_chart_yearColumn')}
                </th>
                <th
                  scope="col"
                  className="w-1/2 border-grey-5 border-1 bg-grey-2"
                >
                  {t('compareByCategory_chart_speedColumn')}
                </th>
              </tr>
            </thead>
            <tbody className="text-center text-neutral2-base">
              <tr>
                <td className="border-grey-5 border-1 h-50 md:h-60">
                  {t('compareByCategory_chart_mobile_firstRow_year')}
                </td>
                <td className="border-grey-5 border-1">
                  {t('compareByCategory_chart_mobile_firstRow_speed')}
                </td>
              </tr>
              <tr>
                <td className="border-grey-5 border-1 h-50 md:h-60">
                  {t('compareByCategory_chart_mobile_secondRow_year')}
                </td>
                <td className="border-grey-5 border-1">
                  {t('compareByCategory_chart_mobile_secondRow_speed')}
                </td>
              </tr>
              <tr>
                <td className="border-grey-5 border-1 h-50 md:h-60">
                  {t('compareByCategory_chart_mobile_thirdRow_year')}
                </td>
                <td className="border-grey-5 border-1">
                  {t('compareByCategory_chart_mobile_thirdRow_speed')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {planDownloadSpeed && (
        <p className="px-6 pb-6 italic text-neutral2-base md:not-italic text-body2 md:px-0">
          {t('compareByCategory_chart_currentPlan', {
            planDownloadSpeed: planDownloadSpeed,
          })}
        </p>
      )}
      <p className="px-6 pb-8 italic text-neutral2-base text-body2 md:px-0">
        {t('compareByCategory_chart_note')}
      </p>
    </div>
  );
};

export default CompareByCategory;
