import { createReducer } from '@reduxjs/toolkit';

import * as ModalTypes from '../constants/modals';
import { speedtestCloseModal, speedtestOpenModal } from '../actions/modals';

interface ModalsState {
  isOpen: boolean;
  name: UnionOf<typeof ModalTypes> | null;
}

const initialState: ModalsState = {
  isOpen: false,
  name: null,
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(speedtestCloseModal, state => ({
      ...state,
      isOpen: false,
      name: null,
    }))
    .addCase(speedtestOpenModal, (state, action) => ({
      ...state,
      isOpen: true,
      name: action.payload as UnionOf<typeof ModalTypes>,
    }))
);

export default reducer;
