/**
 * @fileoverview Simple reusable getter selectors for working with Speed Test's slice of state.  Keeps
 * things DRY and maintainable. Expects Speed Test's slice of state as input, which is usually provided by
 * `speedTestContext.getState(state)`;
 */
import get from 'lodash/get';
import { RootState } from '../store-types';

/**
 * Returns the Speed Test state's Download test status.
 *
 * @function
 * @param {Object} speedTestState Speed Test's slice of app state
 * @returns {String} Status of the Download test
 */
export const getDownloadTestStatus = (speedTestState: RootState) =>
  get(speedTestState, 'test.statuses.downloadTest.status');

/**
 * Returns the Speed Test state's session id.
 *
 * @function
 * @param {Object} speedTestState Speed Test's slice of app state
 * @returns {String} Speed Test's session id
 */
export const getSessionId = (speedTestState: RootState) =>
  speedTestState.test.sessionId;

/**
 * Returns the Speed Test state's `showTestingScreen`.
 *
 * @function
 * @param {Object} speedTestState Speed Test's slice of app state
 * @returns {Boolean} Speed Test state's `showTestingScreen` value
 */
export const getShowTestingScreen = (speedTestState: RootState) =>
  get(speedTestState, 'ui.showTestingScreen');

/**
 * Returns the Speed Test state's Download test `testId`.
 *
 * @function
 * @param {Object} speedTestState Speed Test's slice of app state
 * @returns {String} Download test's test id
 */
export const getTestId = (speedTestState: RootState) =>
  speedTestState.test.testId;
