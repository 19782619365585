import { createAction } from '@reduxjs/toolkit';

import {
  REQUEST_APP_RELOAD,
  REQUEST_BACK_NAVIGATION,
  REQUEST_ROUTE,
} from '../constants/action-types';

interface Route {
  route: string | undefined;
}

export const requestRoute = createAction(REQUEST_ROUTE, (routeData: Route) => ({
  payload: routeData,
}));

export const requestAppReload = createAction(REQUEST_APP_RELOAD);

export const requestBack = createAction(REQUEST_BACK_NAVIGATION);
