import { combineLatest, of } from 'rxjs';
import {
  filter,
  first,
  map,
  mergeMap,
  pluck,
  withLatestFrom,
  mapTo,
} from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import pathToRegexp, { Key } from 'path-to-regexp';
import get from 'lodash/get';
// Our code
import { DeviceDetailsResponse } from '../network-interfaces/device-details';
import { fetchData, getEndpoints } from './epic-helpers';
import {
  DEVICE_DETAILS_BY_FINGERPRINT_FETCH_COMPLETED,
  DEVICE_DETAILS_FETCH_STARTED,
  DEVICE_DETAILS_FETCH_COMPLETED,
  SPEED_TEST_DATA_LOAD_STARTED,
} from '../constants/action-types';
import { DEVICE_DETAILS as DEVICE_DETAILS_LOG_EVENT } from '../constants/log-events';
import { fetchDeviceDetailsByFingerprintComplete } from '../actions/device-fingerprint';
import { fetchDeviceDetails } from '../network-interfaces/device-details';
import {
  fetchDeviceDetailsComplete,
  fetchDeviceDetailsStart,
} from '../actions/device-details';
import { ROUTES } from '../constants/routes';
import { RootState, RootAction, EpicDependencies } from '../store-types';
import { trackMetrics } from '../actions/logger';

function isKnownDeviceRouteChange(currRoute: string, knownDeviceRoute: string) {
  return Boolean(currRoute.match(pathToRegexp(knownDeviceRoute)));
}

export const fetchDeviceDetailsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(DEVICE_DETAILS_FETCH_STARTED),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    mergeMap(([action, endpoints]) =>
      of(action).pipe(
        fetchData(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          fetchDeviceDetails(endpoints!.DEVICE_DETAILS_ENDPOINT),
          resolution => fetchDeviceDetailsComplete(null, resolution),
          error => fetchDeviceDetailsComplete(error, null),
          action => get(action, 'payload.uuid')
        )
      )
    )
  );
};

/**
 * Given a path pattern and a pathname to test against
 * Takes the path pattern and converts it into a RegExp.
 * Takes that RegExp and extracts parameters from the pathname.
 * Returns a neat object of key-value pairs.
 *
 * @example
 * extractParamsFromPath('speed/:id/test', 'speed/wow/test')
 * //returns { id: 'wow'}
 * @param {String} path the path pattern we are trying match against
 * @param {String} pathname the the pathname that we are trying to extract parameters from
 * @returns {Object} an object of extracted parameters
 */
export const extractParamsFromPath = (path: string, pathname: string) => {
  let keys: Key[] = [];
  const regExp =
    pathToRegexp(path, keys).exec(pathname) ||
    (([] as unknown) as RegExpExecArray);

  return keys.reduce<Record<string | number, any>>(
    (acc, key, index) => ({ [key.name]: regExp[index + 1], ...acc }),
    {}
  );
};

/**
 * Fetches details about a device when we navigate to a
 * known device page (for example, /devicespeeds/SM-G900V_GalaxyS5).
 * xFi lazy loads speed test epics when the url contains 'speedtest'.
 * This is a problem if a user tried to go directly to a known device page
 * because the speed test epics wouldn't be loaded in time to process
 * the route change action. To ensure that speed test epics are loaded,
 * this epic listens for `SPEED_TEST_DATA_LOADED`. When this event is
 * dispatched, we can be reasonably sure that the speed test epics are
 * loaded.
 *
 * @example
 * SPEED_TEST_DATA_LOAD_STARTED = L
 *                 CHANGE_ROUTE = C
 * DEVICE_DETAILS_FETCH_STARTED = D
 * // data loads first
 *  Input -L---C--
 * Output -----D--
 *
 * // route changes to known device before data has loaded
 *  Input -C---L--
 * Output -----D--
 *
 * // we fetch device details as many times as we change to the known device
 * // route, regardless of how many times the data loads
 *  Input -L---C--C---C-
 * Output -----D--D---D-
 */
export const fetchDeviceDetailsOnNavigationEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  const routeChange$ = action$.pipe(
    ofType(speedTestContext.CHANGE_ROUTE) // Maps to SET_ROUTE in si-components
  );

  const loadStart$ = action$.pipe(
    ofType(SPEED_TEST_DATA_LOAD_STARTED),
    first()
  );

  return combineLatest(loadStart$, routeChange$).pipe(
    map((dataAction, routeAction) => routeAction),
    withLatestFrom(state$, (action, state) => state),
    map(speedTestContext.getRoute),
    filter(route =>
      isKnownDeviceRouteChange(
        route,
        `${speedTestContext.rootRoute}${ROUTES.KNOWN_DEVICE}`
      )
    ),
    map(
      route =>
        extractParamsFromPath(
          `${speedTestContext.rootRoute}${ROUTES.KNOWN_DEVICE}`,
          route
        ).id
    ),
    map(deviceId => fetchDeviceDetailsStart(deviceId))
  );
};

export const fetchDeviceDetailsFailureEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(DEVICE_DETAILS_FETCH_COMPLETED),
    filter(
      (action: ReturnType<typeof fetchDeviceDetailsComplete>) => action.error
    ),
    mapTo(speedTestContext.replaceRoute(ROUTES.COMPARE_BY_CATEGORY))
  );
};

/**
 * Log the device details with session id (session id decorates all logs) to
 * provide additional context to assist in reproducing and identifying why
 * errors are occurring.
 */
export const logDeviceDetailsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = action$ => {
  return action$.pipe(
    ofType(
      DEVICE_DETAILS_BY_FINGERPRINT_FETCH_COMPLETED,
      DEVICE_DETAILS_FETCH_COMPLETED
    ),
    filter(
      (
        action: ReturnType<
          | typeof fetchDeviceDetailsByFingerprintComplete
          | typeof fetchDeviceDetailsComplete
        >
      ) => !action.error
    ),
    pluck('payload'),
    map((payload: DeviceDetailsResponse) => {
      const { displayName, formFactor, id } = payload;

      return trackMetrics(DEVICE_DETAILS_LOG_EVENT, {
        displayName,
        formFactor,
        id,
      });
    })
  );
};
