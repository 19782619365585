import React, { SyntheticEvent, useState } from 'react';
import classnames from 'classnames';
import { ReactComponent as IconChevron } from './icons/icon-arrow-right-fillable.svg';
import { useLocalization } from '../../hooks/localization';

interface Props {
  /** Additional classnames for the root element of this component. */
  className?: string;

  /**The input is disabled  */
  disabled?: boolean;

  /** The error class should be applied to the input  */
  error?: boolean;

  /** Unique identifier to link Label to input. */
  id: string;

  /** The text next to the input. */
  label: string;

  /** The input `name` */
  name: string;

  /** An event handler to capture changes when a new value is selected. */
  onChange: (event: SyntheticEvent) => void;

  /** The options that will be shown in the dropdown. */
  options: { disabled?: boolean; label: string; value: string }[];

  /** The value for this input field. */
  value?: string;
}

const SelectInput = ({
  className: parentClassNames,
  disabled,
  error,
  id,
  label,
  onChange,
  options = [],
  value = '',
}: Props) => {
  const [isFocused, setFocus] = useState(false);
  const t = useLocalization();
  /**
   * If there is no option that matches the value of the Select, prepend "Select One.."
   * This check intionally allows the type coercion
   */
  if (
    !options.filter(option => option.value === value || option.value === '')
      .length
  ) {
    options.unshift({
      disabled: true,
      label: t('advancedSettings_selectInput'),
      value: '',
    });
  }

  return (
    <div className={parentClassNames} data-focus-container={true}>
      <div
        className={classnames(
          'relative w-full border rounded-xsmall',
          { 'border-grey-2 bg-grey-2': !isFocused && !error },
          { 'border-stroke-theme1-base bg-white': isFocused && !error },
          { 'border-negative bg-grey-2': error && !isFocused },
          { 'border-negative bg-white': error && isFocused }
        )}
      >
        <label
          className={classnames(
            'absolute px-1 pr-4 max-w-2/5 h-full flex flex-grow-0 justify-end items-center right-0 pointer-events-none bg-transparent text-body1',
            {
              'text-neutral2-base alpha-inactive': !isFocused && disabled,
              'text-neutral2-base': !isFocused && !disabled,
              'text-theme1-base': !disabled && isFocused,
            }
          )}
          htmlFor={id}
        >
          <span className="truncate">{label}</span>
          <IconChevron className="flex-shrink-0 p-1 ml-2 transform rotate-90 fill-current w-25 h-25 text-neutral2-base" />
        </label>
        <select
          className={classnames(
            'appearance-none text-body1 h-full w-full outline-none py-4 pl-4 bg-transparent',
            { 'text-neutral2-base': disabled }
          )}
          disabled={disabled}
          id={id}
          onBlur={() => {
            setFocus(false);
          }}
          onChange={onChange}
          onFocus={() => setFocus(true)}
          value={value}
        >
          {options.map(({ label, value, disabled }) => (
            <option key={`${label}-${value}`} value={value} disabled={disabled}>
              {label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectInput;
