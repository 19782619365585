import { createAction } from '@reduxjs/toolkit';

import {
  DOWNLOAD_TEST_COMPLETED,
  DOWNLOAD_TEST_STARTED,
  DOWNLOAD_TEST_DATA_COMPLETED,
  DOWNLOAD_TEST_DATA_STARTED,
  DOWNLOAD_TEST_DESKTOP_COMPLETED,
  DOWNLOAD_TEST_DESKTOP_STARTED,
  DOWNLOAD_TEST_RESULTS_POST_COMPLETED,
  DOWNLOAD_TEST_RESULTS_POST_STARTED,
  DOWNLOAD_TEST_STATS_COMPLETED,
  DOWNLOAD_TEST_STATS_STARTED,
  LATENCY_AND_UPLOAD_TEST_RESULTS_POST_COMPLETED,
  LATENCY_AND_UPLOAD_TEST_RESULTS_POST_STARTED,
  TEST_COMPLETED,
  TEST_CONFIG_POST_COMPLETED,
  TEST_CONFIG_POST_STARTED,
  TEST_DOWNLOAD_CURRENT_UPDATED,
  TEST_PLANS_FETCH_STARTED,
  TEST_PLANS_FETCH_COMPLETED,
  TEST_STARTED,
  TEST_UPLOAD_CURRENT_UPDATED,
  UPLOAD_TEST_COMPLETED,
  UPLOAD_TEST_STARTED,
} from '../constants/action-types';
import {
  TestPlansResponse,
  PostDownloadTestResponse,
  TestResults,
  PostTestConfigResponse,
  TestConfig,
} from '../network-interfaces/test';
import { DownloadTestError } from '../epics/download-test';

interface DownloadDesktopResults {
  accumulated: number[];
  mean: number;
}

export interface UploadTestResults {
  mean: number;
  peakValue: number;
}

export const downloadTestComplete = createAction(
  DOWNLOAD_TEST_COMPLETED,
  (action: { payload?: any; error: boolean } = { error: false }) => ({
    payload: action.payload,
    error: action.error,
  })
);

export const downloadTestStart = createAction(DOWNLOAD_TEST_STARTED);

export const downloadTestDesktopComplete = createAction(
  DOWNLOAD_TEST_DESKTOP_COMPLETED,
  (
    error: DownloadTestError | null,
    results: DownloadDesktopResults | null
  ) => ({
    payload: error || {
      accumulated: results && results.accumulated,
      mean: results && results.mean,
    },
    error: Boolean(error),
  })
);

export const downloadTestDesktopStart = createAction(
  DOWNLOAD_TEST_DESKTOP_STARTED
);

export const fetchTestPlansComplete = createAction(
  TEST_PLANS_FETCH_COMPLETED,
  (error: Error | null, response: TestPlansResponse | null) => ({
    payload: error || response,
    error: Boolean(error),
  })
);

export const fetchTestPlansStart = createAction(TEST_PLANS_FETCH_STARTED);

export const getDownloadDataComplete = createAction(
  DOWNLOAD_TEST_DATA_COMPLETED,
  (error: Error | null, accumulated: number[] | null) => ({
    payload: error || { accumulated },
    error: Boolean(error),
  })
);

export const getDownloadDataStart = createAction(DOWNLOAD_TEST_DATA_STARTED);

export const getDownloadStatsComplete = createAction(
  DOWNLOAD_TEST_STATS_COMPLETED,
  (error: Error | null, mean: number | null) => ({
    payload: error || { mean },
    error: Boolean(error),
  })
);

export const getDownloadStatsStart = createAction(
  DOWNLOAD_TEST_STATS_STARTED,
  (accumulated?: number[]) => ({
    payload: { accumulated },
  })
);

export const postDownloadTestResultsComplete = createAction(
  DOWNLOAD_TEST_RESULTS_POST_COMPLETED,
  (error: Error | null, response: PostDownloadTestResponse | null) => ({
    payload: error || response,
    error: Boolean(error),
  })
);

export const postDownloadTestResultsStart = createAction(
  DOWNLOAD_TEST_RESULTS_POST_STARTED,
  (sessionId: string, testId: string, testResults: TestResults) => ({
    payload: {
      sessionId,
      testId,
      testResults,
    },
  })
);

export const postLatencyAndUploadTestResultsComplete = createAction(
  LATENCY_AND_UPLOAD_TEST_RESULTS_POST_COMPLETED,
  (error?: Error) => ({
    payload: error,
    error: Boolean(error),
  })
);

export const postLatencyAndUploadTestResultsStart = createAction(
  LATENCY_AND_UPLOAD_TEST_RESULTS_POST_STARTED,
  (sessionId: string, testId: string, testResults: TestResults) => ({
    payload: {
      sessionId,
      testId,
      testResults,
    },
  })
);

export const postTestConfigComplete = createAction(
  TEST_CONFIG_POST_COMPLETED,
  (error: Error | null, response: PostTestConfigResponse | null) => ({
    payload: error || response,
    error: Boolean(error),
  })
);

export const postTestConfigStart = createAction(
  TEST_CONFIG_POST_STARTED,
  (sessionId: string, testConfig: TestConfig) => ({
    payload: {
      sessionId,
      testConfig,
    },
  })
);

/**
 * TEST_COMPLETED will be dispatched by other actions such as
 * DOWNLOAD_TEST_COMPLETED. In order to pass the correct 'error' and
 * 'payload' fields, we're passing the entire action in.
 * @param {Object} action Redux action
 */
export const testComplete = createAction(
  TEST_COMPLETED,
  (action: { payload?: any; error: boolean } = { error: false }) => ({
    payload: action.payload,
    error: action.error,
  })
);

export const testStart = createAction(TEST_STARTED);

/**
 * Updates the "current" value being stored in the 'test.download'
 * slice of state with the value returned by speed-testJS's
 * 'progress' callback.
 */
export const updateTestDownloadCurrent = createAction(
  TEST_DOWNLOAD_CURRENT_UPDATED,
  (result: number) => ({
    payload: result,
  })
);

/**
 * Updates the "current" value being stored in the 'test.upload'
 * slice of state with the value returned by speed-testJS's
 * 'progress' callback.
 */
export const updateTestUploadCurrent = createAction(
  TEST_UPLOAD_CURRENT_UPDATED,
  (result: number) => ({
    payload: result,
  })
);

/**
 *
 * @param {Object|null} error argument passed to uploadHttpConcurrentProgress's error callback
 * @param {Object|null} result argument passed to uploadHttpConcurrentProgress's complete callback
 * @param {number} result.mean the mean of all the uploads
 * @param {number} result.peakValue the greatest value from all of the uploads
 */
export const uploadTestComplete = createAction(
  UPLOAD_TEST_COMPLETED,
  (error: Error | null, results: UploadTestResults | null) => ({
    payload: error || { mean: results && results.mean },
    error: Boolean(error),
  })
);

export const uploadTestStart = createAction(UPLOAD_TEST_STARTED);
