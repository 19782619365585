import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { SPEED } from './hooks/localization';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

/**
 * Language Detection is performed through navigator.language only, browser compatibility here:
 * https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/language,
 * this explains why 'order' option contains just 'navigator'. more info here:
 * https://github.com/i18next/i18next-browser-languageDetector/blob/master/src/browserLookups/navigator.js
 *
 * Fallback strategy relies on the 'checkForSimilarInWhitelist' option, more info here
 * https://github.com/i18next/i18next-browser-languageDetector/pull/211
 * Say browser language is 'en-GB', fallback order will be:
 * en-GB -> en -> en-* -> fallbackLng. In detail:
 * 'en-GB' (not supported) --> 'en' (not supported) --> en-* (we support both
 * 'en-US' and 'en-CA', and the order counts here, it fallback to the first one found, 'en-US')
 */
i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/runtime-dictionaries/{{lng}}/namespaced/{{ns}}.json',
    },
    detection: {
      order: ['navigator'],
      checkForSimilarInWhitelist: true,
    },
    ns: [SPEED],
    fallbackLng: ['en-US'],
    whitelist: ['en-US', 'es-US'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
