import React, { ImgHTMLAttributes } from 'react';
import PropTypes from 'prop-types';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  alt?: string;
  src: string;
}

/**
 * An `img` element that has the default click/touch and drag behavior disabled.  This is
 * especially helpful in swipeable components like carousels.
 */
const NoDragImage = ({ alt = '', src, ...restProps }: Props) => (
  <img src={src} alt={alt} draggable={false} {...restProps} />
);

NoDragImage.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
};

export default NoDragImage;
