import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import XdsButton from '../XdsButton/XdsButton';
import { requestTest as requestTestAction } from '../../api/actions/request-test';
import { useLocalization } from '../../hooks/localization';
import Link from '../Link/Link';

interface Props extends HTMLAttributes<HTMLDivElement> {
  data: string;
  isReady: boolean;
  requestTest?: Dispatch<typeof requestTestAction>;
  title: string;
}

const LabelSpeedResult = ({
  title,
  data,
  className: parentClassNames,
  isReady,
  requestTest,
}: Props) => {
  const t = useLocalization();
  const noResultsUI = (
    <Link
      className="link"
      onClick={requestTest ? () => requestTest({}) : () => {}}
    >
      {t('deviceVsPlanSpeed_aboutSpeedToDevice_testSpeed')}
    </Link>
  );

  const getResultsTemplateByData = (data: string, isReady: boolean) => {
    return isReady && data ? (
      <React.Fragment>{data} Mbps</React.Fragment>
    ) : (
      noResultsUI
    );
  };

  return (
    <div
      className={classnames(
        parentClassNames,
        'flex justify-between text-headline3 text-neutral2-base'
      )}
    >
      <div className="mr-4">{title}</div>
      <div>{getResultsTemplateByData(data, isReady)}</div>
    </div>
  );
};

export default LabelSpeedResult;
