// Third-party libraries
import get from 'lodash/get';
import includes from 'lodash/includes';
import { ofType, Epic } from 'redux-observable';
import { merge } from 'rxjs';
import {
  filter,
  map,
  mapTo,
  ignoreElements,
  partition,
  pluck,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
// Our code
import {
  REQUEST_APP_RELOAD,
  REQUEST_BACK_NAVIGATION,
  REQUEST_ROUTE,
  APP_READY,
} from '../constants/action-types';
import { focusA11yAnnouncer } from '../actions/ui';

import { ROUTES } from '../constants/routes';
import { RootState, RootAction, EpicDependencies } from '../store-types';
import { matchRoute } from '../selectors/view-state/header';
import { SITE_MAP } from '../../site-map';

const _filterRoute = ([newRoute, currentRoute]: [string, string]) => {
  return !!(newRoute && newRoute !== currentRoute);
};

export const requestRouteEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  const currentRoute$ = state$.pipe(map(speedTestContext.getRoute));

  return action$.pipe(
    ofType(REQUEST_ROUTE),
    pluck('payload', 'route'),
    withLatestFrom(currentRoute$),
    filter(_filterRoute),
    map(([newRoute], locationState) =>
      // the `locationState` here is the size of the current history stack
      // we need this passed into `setRoute` so that we access it from our router state
      speedTestContext.setRoute(newRoute, locationState)
    )
  );
};

export const noResultsRedirectEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(speedTestContext.CHANGE_ROUTE),
    withLatestFrom(state$, (action, state) => state),
    filter(state => includes(speedTestContext.getRoute(state), ROUTES.RESULTS)),
    map(speedTestContext.getState),
    filter(({ test }) => get(test, 'download.results') === null),
    mapTo(speedTestContext.replaceRoute(ROUTES.HOME))
  );
};

export const requestAppReloadEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext, window }) => {
  return action$.pipe(
    ofType(REQUEST_APP_RELOAD),
    tap(
      () =>
        (window.location.href = `${speedTestContext.rootRoute}${ROUTES.HOME}`)
    ),
    ignoreElements()
  );
};

export const scrollToTopEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext, window }) => {
  return action$.pipe(
    ofType(speedTestContext.CHANGE_ROUTE),
    filter((action: ReturnType<typeof speedTestContext.setRoute>) => {
      // @ts-ignore: The incorrect overload is being selected for 'push' in connected-react-router
      return action.payload.location.hash === '';
    }),
    tap(() => window.requestAnimationFrame(() => window.scrollTo(0, 0))),
    ignoreElements()
  );
};

export const requestBackEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  const hasInAppHistory = (state: RootState) =>
    get(state, 'router.location.state', -1) > -1;

  const stateWhenBackIsRequested$ = action$.pipe(
    ofType(REQUEST_BACK_NAVIGATION),
    withLatestFrom(state$, (action, state) => state)
  );

  const [navigateBack$, navigateHome$] = partition(hasInAppHistory)(
    stateWhenBackIsRequested$
  );
  return merge(
    navigateBack$.pipe(mapTo(speedTestContext.requestBack())),
    navigateHome$.pipe(
      mapTo(speedTestContext.requestRoute({ route: ROUTES.HOME }))
    )
  );
};

/* When new content is loaded, a screen reader will announce
 * "Page Loaded - <NewPageTitle>" then proceed to read the newly rendered
 * content. NVDA will not read the contents of an element which has focus
 * if the change of focus was triggered by an element that is a descendant
 * of the element which now has focus. For example, if 'button' is a descendant
 * of 'container' and clicking on 'button' moves focus to 'container',
 * this focus change will not cause NVDA to read the contents of 'container'.
 * In order to have the contents of 'container' read when it is 'loaded' on
 * the screen, focus is being moved to a sibling of 'container'
 * then moved back to 'container'.
 * For more information on this NVDA issue, please see:
 * https://github.com/nvaccess/nvda/issues/6606
 */
export const setAnnouncementEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    filter(
      ({ type, payload }) =>
        type === APP_READY ||
        (type === speedTestContext.CHANGE_ROUTE && !payload.isFirstRendering)
    ),
    pluck('payload', 'location', 'pathname'),
    map(newPath => {
      const [routeKey] = matchRoute(newPath);
      return focusA11yAnnouncer(SITE_MAP[routeKey].title);
    })
  );
};
