import React, { HTMLAttributes, ReactNode, ReactElement } from 'react';
import classnames from 'classnames';
import Modal, { Props as ModalProps } from '../Modal/Modal';
import styles from './ModalBox.module.css';

const SCROLL_SHADOW_HEIGHT = 75;

export interface HeaderProps {
  children?: ReactNode;
}
/* istanbul ignore next */
const Header = ({ children }: HeaderProps) => <div>{children}</div>;

export interface BodyProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}
/* istanbul ignore next */
const Body = ({ className: parentClassNames, children }: BodyProps) => (
  <div className="relative flex-1">
    <div
      className={classnames(
        parentClassNames,
        'absolute inset-0 overflow-x-hidden overflow-y-auto scrolling-touch min-h-100 px-4 md:px-8 pt-6'
      )}
      style={{ paddingBottom: SCROLL_SHADOW_HEIGHT }}
    >
      {children}
    </div>
    <div
      className={classnames(
        'absolute inset-x-0 pointer-events-none border-b border-grey-3',
        styles.scrollShadow
      )}
      style={{ height: SCROLL_SHADOW_HEIGHT, bottom: -1 }}
    />
  </div>
);

export interface FooterProps {
  children?: ReactNode;
}
/* istanbul ignore next */
const Footer = ({ children }: FooterProps) => <div>{children}</div>;

interface Props {
  children: (modalComponents: {
    Header: (props: HeaderProps) => ReactElement | null;
    Body: (props: BodyProps) => ReactElement | null;
    Footer: (props: FooterProps) => ReactElement | null;
  }) => JSX.Element;
  modalDataBid: string;
  slideDuration?: number;
}
/* istanbul ignore next */
const ModalBox = ({
  className: parentClassNames,
  children,
  slideDuration = 250,
  ...props
}: Props & ModalProps) => {
  return (
    <div
      className={classnames(
        parentClassNames,
        'bg-grey-0 relative flex flex-col h-auto'
      )}
      style={{
        transitionDuration: `${slideDuration}ms`,
      }}
    >
      <Modal {...props}>
        {children &&
          typeof children == 'function' &&
          children({ Header, Body, Footer })}
      </Modal>
    </div>
  );
};

export default ModalBox;
