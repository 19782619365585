import React, { useState } from 'react';
import classnames from 'classnames';
import 'details-element-polyfill';
import Accordion from '../Accordion/Accordion';
import DeviceVsPlan from './images/DeviceVsPlan.svg';
import ImproveSpeed from './images/ImproveSpeed.svg';
import DeviceLookUp from './images/DeviceLookUp.svg';
import CompareDevicePlans from './images/CompareDevicePlans.svg';
import NeedsForSpeed from './images/NeedsForSpeed.svg';
import GatewayUpgrade from './images/GatewayUpgrade.svg';
import { ROUTES } from '../../api/constants/routes';
import Link from '../Link/Link';
import ExternalLink from '../ExternalLink/ExternalLink';
import Localize from '../Localize/Localize';
import { requestRoute as requestRouteAction } from '../../api/speedtest-interface';
import tipsAndToolsAccordionsStyles from './TipsAndToolsAccordions.module.css';
import { useLocalization } from '../../hooks/localization';

interface Props {
  compareByCategoryLink: string;
  requestRoute: Dispatch<typeof requestRouteAction>;
}

const TipsAndToolsAccordions = ({
  compareByCategoryLink,
  requestRoute,
}: Props) => {
  const t = useLocalization();
  const tipsAndTools = [
    {
      imgSrc: DeviceVsPlan,
      heading: t('home_tipsAndTools_firstSection_header'),
      content: (
        <>
          <p className="pt-2 text-neutral2-base text-body1">
            {t('home_tipsAndTools_firstSection_body_firstPart')}
          </p>
          <p className="pt-2 text-neutral2-base text-body1">
            <Localize
              className="speed-test-net"
              entry={t('home_tipsAndTools_firstSection_body_secondPart')}
            />
          </p>
          <Link
            className="mt-4 link md:mb-4 lg:my-2 text-body1"
            requestRoute={requestRoute}
            href={ROUTES.DEVICE_VS_PLAN}
          >
            {t('home_tipsAndTools_deviceSpeedVsPlanLink')}
          </Link>
        </>
      ),
    },
    {
      imgSrc: ImproveSpeed,
      heading: t('home_tipsAndTools_secondSection_header'),
      content: (
        <>
          <p className="pt-2 text-neutral2-base text-body1">
            {t('home_tipsAndTools_secondSection_body')}
          </p>
          <Link
            className="mt-4 link md:mb-4 lg:my-2 text-body1"
            requestRoute={requestRoute}
            href={ROUTES.IMPROVE_SPEED}
          >
            {t('home_tipsAndTools_coverageLink')}
          </Link>
        </>
      ),
    },
    {
      imgSrc: GatewayUpgrade,
      heading: t('home_tipsAndTools_thirdSection_header'),
      content: (
        <>
          <p className="pt-2 text-neutral2-base text-body1">
            {t('home_tipsAndTools_thirdSection_body')}
          </p>
          <Link
            className="mt-4 link md:mb-4 lg:my-2 text-body1"
            requestRoute={requestRoute}
            href={ROUTES.IMPROVE_SPEED}
          >
            {t('home_tipsAndTools_coverageLink')}
          </Link>
        </>
      ),
    },
    {
      imgSrc: DeviceLookUp,
      heading: t('home_tipsAndTools_fourthSection_header'),
      content: (
        <>
          <p className="pt-2 text-neutral2-base text-body1">
            {t('home_tipsAndTools_fourthSection_body')}
          </p>
          <Link
            className="mt-4 link md:mb-4 lg:my-2 text-body1"
            requestRoute={requestRoute}
            href={compareByCategoryLink}
          >
            {t('home_tipsAndTools_compareSpeedLink')}
          </Link>
        </>
      ),
    },
    {
      imgSrc: CompareDevicePlans,
      heading: t('home_tipsAndTools_fifthSection_header'),
      content: (
        <>
          <p className="pt-2 text-neutral2-base text-body1">
            {t('home_tipsAndTools_fifthSection_body')}
          </p>
          <ExternalLink
            className="mt-4 link md:mb-4 lg:my-2 text-body1"
            href="https://www.xfinity.com/learn/internet-service/speed"
          >
            {t('home_tipsAndTools_comparePlanLink')}
          </ExternalLink>
        </>
      ),
    },
    {
      imgSrc: NeedsForSpeed,
      heading: t('home_tipsAndTools_sixthSection_header'),
      content: (
        <>
          <p className="py-4 text-neutral2-base text-body1">
            {t('home_tipsAndTools_sixthSection_body')}
          </p>
          <table className="w-full">
            <thead className="text-center text-neutral2-base text-body1">
              <tr>
                <th
                  scope="col"
                  className="w-1/2 border-grey-5 border-1 bg-grey-2 h-50"
                >
                  {t('home_tipsAndTools_sixthSection_compareTable_activity')}
                </th>
                <th
                  scope="col"
                  className="w-1/2 border-grey-5 border-1 bg-grey-2"
                >
                  {t(
                    'home_tipsAndTools_sixthSection_compareTable_speedRequired'
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="text-center text-body1 text-neutral2-base">
              <tr>
                <td className="border-grey-5 border-1 h-50">
                  {t(
                    'home_tipsAndTools_sixthSection_compareTable_activity_firstRow'
                  )}
                </td>
                <td className="border-grey-5 border-1">
                  {t(
                    'home_tipsAndTools_sixthSection_compareTable_speedRequired_firstRow'
                  )}
                </td>
              </tr>
              <tr>
                <td className="border-grey-5 border-1 h-50">
                  {t(
                    'home_tipsAndTools_sixthSection_compareTable_activity_secondRow'
                  )}
                </td>
                <td className="border-grey-5 border-1">
                  {t(
                    'home_tipsAndTools_sixthSection_compareTable_speedRequired_secondRow'
                  )}
                </td>
              </tr>
              <tr>
                <td className="border-grey-5 border-1 h-50">
                  {t(
                    'home_tipsAndTools_sixthSection_compareTable_activity_thirdRow'
                  )}
                </td>
                <td className="border-grey-5 border-1">
                  {t(
                    'home_tipsAndTools_sixthSection_compareTable_speedRequired_thirdRow'
                  )}
                </td>
              </tr>
              <tr>
                <td className="border-grey-5 border-1 h-50">
                  {t(
                    'home_tipsAndTools_sixthSection_compareTable_activity_fourthRow'
                  )}
                </td>
                <td className="border-grey-5 border-1">
                  {t(
                    'home_tipsAndTools_sixthSection_compareTable_speedRequired_fourthRow'
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ),
    },
  ];

  const handleClick = (event: React.SyntheticEvent, index: number) => {
    if (event && index >= 0) {
      let target = event.target as HTMLElement;

      // don't prevent default behavior for anchor elements because
      // [target="_blank"] elements won't open in a new window
      const isExternalLink = (element: HTMLElement) => {
        if (
          element.getAttribute('target') === '_blank' ||
          (element.parentElement &&
            element.parentElement.getAttribute('target') === '_blank')
        )
          return true;

        return false;
      };

      if (!isExternalLink(target)) {
        event.preventDefault();
      }

      if (index === openRow) {
        setOpenRow(-1);
      } else {
        setOpenRow(index);
      }
    }
  };

  const [openRow, setOpenRow] = useState(-1);
  return (
    <ul className="container pb-16 border-t border-grey-3">
      {tipsAndTools.map((tipOrTool, index) => {
        return (
          <li key={tipOrTool.heading}>
            <Accordion
              className={classnames(
                'border-b border-grey-5',
                tipsAndToolsAccordionsStyles.details
              )}
              handleClick={handleClick}
              index={index}
              open={openRow >= 0 && openRow === index}
              outerContent={
                <h3 className="text-body1 lg:py-6">{tipOrTool.heading}</h3>
              }
              innerContent={
                <div className="items-start px-4 pb-6 lg:flex">
                  <img
                    src={tipOrTool.imgSrc}
                    alt=""
                    className="w-full lg:w-1/2"
                  />
                  <div className="lg:w-1/2 lg:pl-4">{tipOrTool.content}</div>
                </div>
              }
            />
          </li>
        );
      })}
    </ul>
  );
};

export default TipsAndToolsAccordions;
