import { createReducer } from '@reduxjs/toolkit';

import { setInitialAppConfig } from '../actions/app-config';
import { appReady } from '../actions/lifecycle';
import { markPulseLoaded } from '../actions/pulse';
import * as STATUSES from '../constants/statuses';
import { AppConfig } from '../configureStore';

const { BUSY, ERROR, READY } = STATUSES;

export interface AppConfigState {
  statuses: {
    appReady: {
      status: UnionOf<typeof STATUSES>;
      error: Error | null;
    };
  };
  endpoints: AppConfig['endpoints'] | null;
  environment: AppConfig['environment'] | null;
  pulseLoaded: boolean;
}

const initialState: AppConfigState = {
  statuses: {
    appReady: {
      status: BUSY,
      error: null,
    },
  },
  endpoints: null,
  environment: null,
  pulseLoaded: false,
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(appReady, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            appReady: {
              status: ERROR,
              error: action.payload,
            },
          },
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          appReady: {
            status: READY,
            error: null,
          },
        },
      };
    })
    .addCase(setInitialAppConfig, (state, action) => ({
      ...state,
      endpoints: action.payload.appConfig.endpoints,
      environment: action.payload.appConfig.environment,
    }))
    .addCase(markPulseLoaded, state => ({
      ...state,
      pulseLoaded: true,
    }))
);

export default reducer;
