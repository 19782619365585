// Third-party Libraries
import { connect } from 'react-redux';
// Our Code
import NonSpeedProblemsPage from '../pages/NonSpeedProblems/NonSpeedProblemsPage';

// TODO: map some state properties to your page
const mapStateToProps = (/* state */) => ({
  // someData: state.someData
});

// TODO: map some actionCreators to your page, please import them first.
const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(NonSpeedProblemsPage);
