// Third-party libraries
import { ofType, Epic } from 'redux-observable';
import { fromEvent, of, race, zip } from 'rxjs';
import {
  filter,
  first,
  map,
  mergeMap,
  pluck,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
// Our code
import { fetchData, getEndpoints } from './epic-helpers';
import {
  DEVICE_DETAILS_BY_FINGERPRINT_FETCH_STARTED,
  DEVICE_FINGERPRINT_FETCH_STARTED,
  DEVICE_FINGERPRINT_FETCH_COMPLETED,
  DEVICE_FINGERPRINT_POST_STARTED,
} from '../constants/action-types';
import {
  fetchDeviceDetailsByFingerprintComplete,
  fetchDeviceDetailsByFingerprintStart,
  fetchDeviceFingerprintComplete,
  postDeviceFingerprintComplete,
  postDeviceFingerprintStart,
} from '../actions/device-fingerprint';
import {
  fetchDeviceDetailsByFingerprint,
  postDeviceFingerprint,
  WurflFingerprint,
} from '../network-interfaces/device-fingerprint';
import { DEVICE_FINGERPRINT as DEVICE_FINGERPRINT_LOG_EVENT } from '../constants/log-events';
import { trackMetrics } from '../actions/logger';
import { RootState, RootAction, EpicDependencies } from '../store-types';
import { AppConfigState } from '../reducers/app-config';

declare global {
  interface Window {
    WURFL: WurflFingerprint;
  }
}

/**
 * Creates a script tag for loading the WURFL library,
 * appends it to window, and then returns the script tag
 * so that we can add "event listeners" to it later.
 * @param {Window} window
 * @param {String} DEVICE_FINGERPRINT_ENDPOINT
 * @returns {HTMLElement}
 */
function createAndAppendScript(
  document: Window['document'],
  DEVICE_FINGERPRINT_ENDPOINT: string
) {
  const script = document.createElement('script');
  script.src = DEVICE_FINGERPRINT_ENDPOINT;
  script.type = 'text/javascript';
  script.async = true;
  document.body.appendChild(script);

  return script;
}

export const fetchDeviceDetailsByFingerprintEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(DEVICE_DETAILS_BY_FINGERPRINT_FETCH_STARTED),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    mergeMap(([action, endpoints]) =>
      of(action).pipe(
        fetchData(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          fetchDeviceDetailsByFingerprint(endpoints!.DEVICE_DETAILS_ENDPOINT),
          response => fetchDeviceDetailsByFingerprintComplete(null, response),
          error => fetchDeviceDetailsByFingerprintComplete(error, null),
          action => ({
            marketName: action.payload.marketName,
            modelName: action.payload.modelName,
            formFactor: action.payload.formFactor,
            operatingSystem: action.payload.operatingSystem,
          })
        )
      )
    )
  );
};

// TODO: See if script tag can simply be added in index.html CIMSI-10082
/**
 * Loads the WURFL script which handles device detection. If the script
 * loads successfully, it dispatches the "...COMPLETED" action with the
 * device details that WURFL exposes. If an error is thrown while attempting to load,
 * "...COMPLETED" is dispatched with that error.
 * Note: It's highly unlikely that a script would emit a 'load' and 'error' event
 * @example
 * // 'load' event fires before 'error'
 *   DEVICE_FINGERPRINT_FETCH_STARTED action -S-------
 *                            'load' event ----L|
 *                           'error' event --------E|
 * DEVICE_FINGERPRINT_FETCH_COMPLETED action ----C|
 * @example
 * // 'error' event fires before 'load'
 *   DEVICE_FINGERPRINT_FETCH_STARTED action -S------
 *                            'load' event -------L|
 *                           'error' event ----E|
 * DEVICE_FINGERPRINT_FETCH_COMPLETED action ----C|
 * @param {Observable} action$ Actions dispatched by the Redux store
 * @param {Object} state$
 * @param {Object} param2 Epic dependencies
 */
export const fetchDeviceFingerprintEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { window, speedTestContext }) => {
  return action$.pipe(
    ofType(DEVICE_FINGERPRINT_FETCH_STARTED),
    first(),
    withLatestFrom(state$, (action, state) => state),
    map(speedTestContext.getAppConfig),
    map(
      (appConfig: AppConfigState) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appConfig.endpoints!.DEVICE_FINGERPRINT_ENDPOINT
    ),
    map(DEVICE_FINGERPRINT_ENDPOINT =>
      createAndAppendScript(window.document, DEVICE_FINGERPRINT_ENDPOINT)
    ),
    switchMap(scriptTag =>
      race(fromLoadEvent(scriptTag, window), fromErrorEvent(scriptTag))
    )
  );
};

/**
 * Subscribes to 'error' events that are emitted from
 * a <script />. Once the first 'error' event is emitted,
 * we unsubscribe and dispatch the DEVICE_FINGERPRINT_FETCH_COMPLETED
 * action with the payload set to the Error Object.
 *
 * This is the RxJS equivalent of:
 * const cb = function(error) {
 *   fetchDeviceFingerprintComplete(error, null);
 *   script.removeEventListener('error', cb);
 * }
 * script.addEventListener('error', cb);
 *
 * @param {Element} scriptElem A <script />
 */
function fromErrorEvent(scriptElem: HTMLScriptElement) {
  return fromEvent(scriptElem, 'error').pipe(
    first(), // unsubscribes after the first event
    map((error: any) => fetchDeviceFingerprintComplete(error, null))
  );
}

/**
 * Subscribes to 'load' events that are emitted from
 * a <script />. Once the first 'load' event is emitted,
 * we unsubscribe and dispatch the DEVICE_FINGERPRINT_FETCH_COMPLETED
 * action with the payload set to variable 'WURFL' on window.
 *
 * This is the RxJS equivalent of:
 * const cb = function() {
 *   fetchDeviceFingerprintComplete(null, window.WURFL))
 *   script.removeEventListener('load', cb);
 * }
 * script.addEventListener('load', cb);
 *
 * @param {Element} scriptElem A <script />
 */
function fromLoadEvent(scriptElem: HTMLScriptElement, window: Window) {
  return fromEvent(scriptElem, 'load').pipe(
    first(), // unsubscribes after the first event
    map(() => fetchDeviceFingerprintComplete(null, window.WURFL))
  );
}

/**
 * Initiates a POST request to the IPIE Service
 * with the session id and the fingerprint data
 * from the dispatched action.
 */
export const postDeviceFingerprintEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(DEVICE_FINGERPRINT_POST_STARTED),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    mergeMap(([action, endpoints]) =>
      of(action).pipe(
        fetchData(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          postDeviceFingerprint(endpoints!.DEVICE_FINGERPRINT_POST_ENDPOINT),
          () => postDeviceFingerprintComplete(),
          error => postDeviceFingerprintComplete(error),
          action => action.payload
        )
      )
    )
  );
};

/**
 * Starts the process of sending the collected fingerprint data to IPIE
 * once we have all the data that we need to do so. More specifically,
 * waits for the session id and the fingerprint data to have been successfully fetched
 * and loaded respectively. Once the actions indicating that both of these
 * events have occurred has been dispatched, we collect the necessary data from
 * each action and then use that data in the payload for the action
 * that will schedule the POST request.
 * @example
 * // sessionId is dispatched before deviceFingerprint
 * DEVICE_FINGERPRINT_FETCH_COMPLETED -----F
 *    DEVICE_FINGERPRINT_POST_STARTED -----P
 * @example
 * // deviceFingerprint is dispatched before sessionId
 * DEVICE_FINGERPRINT_FETCH_COMPLETED -F----
 *    DEVICE_FINGERPRINT_POST_STARTED -----P
 * @param {Observable} action$
 */
export const scheduleDeviceFingerprintPostEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  const sessionId$ = state$.pipe(
    withLatestFrom(state$, (action, state) => state),
    map(speedTestContext.getState),
    map((state: RootState) => state.test.sessionId),
    filter(value => Boolean(value)),
    first()
  );
  const deviceFingerprintFetchComplete$ = action$.pipe(
    ofType(DEVICE_FINGERPRINT_FETCH_COMPLETED),
    filter(
      (action: ReturnType<typeof fetchDeviceFingerprintComplete>) =>
        !action.error
    ),
    pluck('payload'),
    first<WurflFingerprint>()
  );

  return zip(sessionId$, deviceFingerprintFetchComplete$).pipe(
    map(([sessionId, deviceFingerprint]) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return postDeviceFingerprintStart(sessionId!, deviceFingerprint);
    })
  );
};

export const scheduleFetchDeviceDetailsByFingerprintEpic: Epic<
  RootAction,
  RootAction
> = action$ => {
  return action$.pipe(
    ofType(DEVICE_FINGERPRINT_FETCH_COMPLETED),
    filter(
      (action: ReturnType<typeof fetchDeviceFingerprintComplete>) =>
        !action.error
    ),
    pluck('payload'),
    filter(
      /*
        NOTE: It's possible that we'll only have one of the fields applicable
        to this request. It's perfectly valid to make the request with only
        one of those fields.
      */
      (payload: WurflFingerprint) =>
        payload.marketing_name !== '' ||
        payload.model_name !== '' ||
        payload.form_factor !== '' ||
        payload.advertised_device_os !== ''
    ),
    map((payload: WurflFingerprint) =>
      fetchDeviceDetailsByFingerprintStart({
        marketName: payload.marketing_name,
        modelName: payload.model_name,
        formFactor: payload.form_factor,
        operatingSystem: payload.advertised_device_os,
      })
    )
  );
};

/**
 * Log the device fingerprint with session id (session id decorates all logs)
 * to provide additional context to assist in reproducing and identifying why
 * errors are occurring.
 */
export const logDeviceFingerprintEpic: Epic<
  RootAction,
  RootAction
> = action$ => {
  return action$.pipe(
    ofType(DEVICE_FINGERPRINT_FETCH_COMPLETED),
    filter(
      (action: ReturnType<typeof fetchDeviceFingerprintComplete>) =>
        !action.error
    ),
    pluck('payload'),
    map((payload: WurflFingerprint) =>
      trackMetrics(DEVICE_FINGERPRINT_LOG_EVENT, { ...payload })
    )
  );
};
