import { createAction } from '@reduxjs/toolkit';

import { APP_FULLY_LOADED, APP_READY } from '../constants/action-types';

export const appFullyLoaded = createAction(APP_FULLY_LOADED);

/**
 * Action creator, used for provide indication, that app is ready. Action should be dispatched,
 * when all critical data calls have been completed.
 */
export const appReady = createAction(
  APP_READY,
  ({ payload, error }: { payload?: any; error?: boolean } = {}) => ({
    payload: { ...payload, location: window.location },
    error: error,
  })
);
