import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import { useLocalization } from '../../hooks/localization';

interface Props extends HTMLAttributes<HTMLDivElement> {
  planSpeed: string;
  className?: string;
}

const PlanInfo = ({
  planSpeed,
  className: parentClassNames,
  ...props
}: Props) => {
  const t = useLocalization();
  return (
    <div className={classnames(parentClassNames, '')} {...props}>
      <span aria-hidden="true">*</span>
      <span className="sm:font-700">
        {t('compareByCategory_chart_currentPlan', {
          planDownloadSpeed: planSpeed,
        })}
      </span>
    </div>
  );
};

export default PlanInfo;
