import React from 'react';
import Xb3 from './icons/xb3.png';
import Xb6 from './icons/xb6.png';
import Xb7 from './icons/xb7.png';

const gatewayIcons = {
  xb3: <img className="w-full" src={Xb3} alt="Xb3 Gateway" />,
  xb6: <img className="w-full" src={Xb6} alt="Xb6 Gateway" />,
  xb7: <img className="w-full" src={Xb7} alt="Xb7 Gateway" />,
};
const DEFAULT_GATEWAY = 'xb6';

interface Props {
  gatewayModelName: keyof typeof gatewayIcons; // this gives us 'xb3' | 'xb6' | 'xb7', based on the gatewayIcons map
}

export const GatewaySpeedTestIcon = ({ gatewayModelName }: Props) => {
  return (
    <div className="w-60">
      {gatewayIcons[gatewayModelName] || gatewayIcons[DEFAULT_GATEWAY]}
    </div>
  );
};

export default GatewaySpeedTestIcon;
