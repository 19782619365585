// Third-party Libraries
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
// Our Code
// Actions
import { speedtestOpenModal } from '../api/actions/modals';
// Selectors
import {
  getIsAdvancedSettingsDisabled,
  getOnBack,
  getRight,
  getShowBackButton,
} from '../api/selectors/view-state/header';
// Components
import Header from '../components/Header/Header';
import { ReactComponent as GearIcon } from '../components/HomePageContent/images/icon-gear.svg';
// Constants
import { HEADER_CTA_TYPES } from '../api/constants/header-ctas';
import { ADVANCED_SETTINGS } from '../api/constants/modals';
import { RootState } from '../api/store-types';
import { useLocalization } from '../hooks/localization';

const HeaderContainer = (
  props: ReturnType<typeof mapStateToProps> & { dispatch: Dispatch }
) => {
  const { isSettingsDisabled, onBack, right, showBackButton, dispatch } = props;
  const t = useLocalization();
  const AdvancedSettingsGear = () => (
    <button
      aria-label={t('advancedSettings_title')}
      onClick={() => dispatch(speedtestOpenModal(ADVANCED_SETTINGS))}
      disabled={isSettingsDisabled}
      className={classnames('block p-2', {
        'opacity-25': isSettingsDisabled,
      })}
    >
      <GearIcon style={{ width: 21, height: 21 }} />
    </button>
  );

  return (
    <Header
      onBack={onBack}
      showBackButton={showBackButton}
      right={
        right === HEADER_CTA_TYPES.ADVANCED_SETTINGS && <AdvancedSettingsGear />
      }
      title={t('siteTitle')}
      dispatch={dispatch}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  isSettingsDisabled: getIsAdvancedSettingsDisabled(state),
  onBack: getOnBack(state),
  right: getRight(state),
  showBackButton: getShowBackButton(state),
});

export default connect(mapStateToProps)(HeaderContainer);
