import React, { FunctionComponent, ReactNode, HTMLAttributes } from 'react';
import classnames from 'classnames';

type HeaderType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type Props = HTMLAttributes<HTMLDivElement> & {
  /**
   * The header text.
   */
  header: string;
  /**
   * Used to change the header level. The default is a 2. If you need an `h3` supply `3`.
   * @default 2
   */
  headerLevel?: number;
};

/* istanbul ignore next */
const SectionHeader: FunctionComponent<Props> = ({
  className: parentClassNames,
  header,
  headerLevel = 2,
  ...props
}) => {
  // PurgeCSS: h1, h2, h3, h4, h5, h6
  const HeaderElement = `h${headerLevel}` as HeaderType;

  return (
    <div
      className={classnames(
        parentClassNames,
        'flex justify-between items-baseline pb-4 pt-6'
      )}
      {...props}
    >
      <HeaderElement className="text-headline2 font-500 leading-normal tracking-wide">
        {header}
      </HeaderElement>
    </div>
  );
};

export default SectionHeader;
