import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import { ReactComponent as X } from './icons/icon-x.svg';
import { CLOSE_MODAL_BUTTON } from '../../api/constants/bids';
import { useLocalization } from '../../hooks/localization';

type HeaderType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface Props extends HTMLAttributes<HTMLElement> {
  className?: string;
  onBack?: () => void;
  onClose: () => void;
  header: string;
  headingLevel?: number;
}

const ModalHeader = ({
  className: parentClassNames,
  onBack,
  onClose,
  header,
  headingLevel = 1,
  ...props
}: Props) => {
  const t = useLocalization();
  const HeaderElement = `h${headingLevel}` as HeaderType;
  const buttonClasses =
    'flex-shrink-0 flex items-center text-neutral2-base cursor-pointer hover:text-theme1-base focus:text-theme1-base p-4';

  return (
    <header
      className={classnames(
        parentClassNames,
        'bg-grey-0 select-none border-b border-grey-3 select-none text-body1'
      )}
      {...props}
    >
      <div className="container flex items-center justify-between">
        <button
          aria-label={t('header_back')}
          className={classnames(buttonClasses, {
            'invisible pointer-events-none': !onBack,
          })}
          disabled={!onBack}
          onClick={onBack}
          role="button"
        >
          {''}
        </button>
        <HeaderElement className="flex-1 m-4 text-center truncate text-body1 text-neutral2-base">
          {header}
        </HeaderElement>
        <button
          aria-label={t('common_close')}
          className={classnames(buttonClasses, {
            'invisible pointer-events-none': !onClose,
          })}
          data-bid={CLOSE_MODAL_BUTTON}
          disabled={!onClose}
          onClick={onClose}
          type="button"
        >
          <X className="overflow-visible fill-current w-15 h-15" />
        </button>
      </div>
    </header>
  );
};

export default ModalHeader;
