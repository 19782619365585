import { createAction } from '@reduxjs/toolkit';

import { MARK_PERFORMANCE } from '../constants/action-types';
import * as performanceMarks from '../constants/performance-marks';

/**
 * Creates an action used to store information with the given `eventKey`,
 * enabling the measurement of performance metrics.
 *
 * @param {String} eventKey
 */
export const markPerformance = createAction(
  MARK_PERFORMANCE,
  (
    eventKey: UnionOf<typeof performanceMarks>,
    customMarkPayload?: object | string
  ) => ({
    payload: {
      eventKey,
      customMarkPayload,
    },
  })
);
