import classnames from 'classnames';
import React, { ReactNode } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import modalCss from './Modal.module.css';

export interface Props extends ReactModalProps {
  children: ReactNode;
  modalDataBid: string;
  transitionDuration?: number;
}

// Do something more elegant here... maybe?
ReactModal.setAppElement('#rsg-root, #app');

const Modal = ({
  className: parentClassNames,
  modalDataBid,
  isOpen,
  onRequestClose,
  transitionDuration = 250,
  ...props
}: Props) => {
  return (
    <ReactModal
      data={{ bid: modalDataBid }}
      isOpen={isOpen}
      bodyOpenClassName="font-primary overflow-hidden fixed inset-0 h-screen w-full"
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: {
          transitionDuration: `${transitionDuration}ms`,
        },
      }}
      overlayClassName={classnames(
        modalCss.overlay,
        'fixed inset-0 flex items-center justify-center'
      )}
      className={classnames(
        parentClassNames,
        modalCss.content,
        'react-root safe-reset relative flex flex-col bg-grey-0 outline-none overflow-hidden md:w-500 min-h-400 shadow'
      )}
      closeTimeoutMS={transitionDuration}
      {...props}
    />
  );
};

export default Modal;
