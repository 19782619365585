// Third-party library (ours)
import Ostrich from 'ostrich';
// Our other code
import debounce from 'lodash/debounce';
import { AppConfig } from '../../api/configureStore';

/**
 * Debouncing interval for sending logs over the wire
 */
const DEBOUNCE_INTERVAL = 1000; // ms

/**
 * Hold onto a Ostrich instance to we don't reinstantiate every time a log is sent
 */
let _ostrichMelee: Ostrich;

/**
 * Container for batched logs to be sent to Melee
 */
let _logs: { [index: string]: any }[] = [];

/**
 * Adds data to every log. Useful for mixing in data after Ostich is initialized.
 */
export const addDefault = (defaultData: { [index: string]: any }) => {
  _ostrichMelee.addDefault(defaultData);
};

/**
 * Get higher accuracy timestamp
 * @name getHighResolutionTime
 * @param {w} Window The global `window` object
 */
const getHighResolutionTime = (w: Window) => {
  return w && w.performance && w.performance.now
    ? Math.floor(w.performance.now())
    : -1;
};

/**
 * Writes a log event to our data analytics loggers.  Adds context that we need to
 * each event that flows through.
 * @name log
 * @param {String} eventName This SHOULD be a human - readable event identifier.
 * @param {Object} params Event / Application - Specific Fields.
 * @param {String} eventContext Contextual identifier.
 */
export const log = (
  eventName: string,
  params: { [index: string]: any } = {},
  eventContext?: string
) => {
  const currentTime = new Date();
  const data = {
    ec: eventContext,
    en: eventName,
    ehrts: getHighResolutionTime((global as unknown) as Window),
    ets: currentTime.getTime(),
    timeStamp: currentTime.toISOString(),
    ...params,
  };

  _logs.push(data);

  /**
   * Wait for Ostrich configuration to be ready before attempting to send logs.
   * Placing this check below `_logs.push` above allows for adding logs to be
   * batched before Ostrich has be instantiated
   */
  if (!_ostrichMelee) return;

  sendLogs();
};

/**
 * @name logToMelee
 * @param {Object} data Actual event logging data to be sent
 */
const logToMelee = (data: { [index: string]: any }) => {
  _ostrichMelee.log(data);
};

/**
 * Dispatches logs to our data analytics forwarders with a debounce at a fixed interval to
 * accommodate batching
 * @name sendLogs
 */

const sendLogs = debounce(() => {
  logToMelee([..._logs]);
  _logs = [];
}, DEBOUNCE_INTERVAL);

/**
 * Gets data from the `SET_INITIAL_APP_CONFIG` action.payload to send along with all data analytics logging. Once these properties
 * are available, we should instantiate an instance of Ostrich.
 *
 * Also, for a bit of clarity on why this is called `getMeleeLogger` when we're
 * instantiating a new instance of Ostrich -- these logs flow as such: Ostrich
 * JS Client > Melee > data analytics. Knowing that Ostrich logs to Melee is what's
 * important w/ the naming here
 *
 * @name init
 * @param {Object} params - An object containing necessary details when sending logs
 * @param {String} obj.env Current environment: development, staging, production
 * @param {String} obj.sessionId Current session id for speed test application
 * @param {Object} MELEE_CONFIG Required configuration and structured fields for Melee
 */
export const init = (
  { env, sessionId }: { env: string; sessionId: string },
  MELEE_CONFIG: AppConfig['MELEE_CONFIG']
) => {
  _ostrichMelee = new Ostrich(
    MELEE_CONFIG.APP_NAME,
    {
      appname: MELEE_CONFIG.APP_NAME,
      appVersion: MELEE_CONFIG.APP_VERSION,
      env,
      partner: MELEE_CONFIG.PARTNER,
      sessionId,
    },
    {
      destination: 'event',
      meleeCC: 'elasticsearch',
      meleeEnv: 'prod',
      meleeToken: MELEE_CONFIG.TOKEN,
      timestampKey: 't',
    }
  );
  if (_logs.length > 0) {
    sendLogs();
  }
  return _ostrichMelee;
};

export default {
  addDefault,
  init,
  log,
};
