import { goBack, push, replace, LOCATION_CHANGE } from 'connected-react-router';
import { requestRoute as _requestRoute } from './actions/navigation';
import { RootState } from './store-types';

export const CHANGE_ROUTE = LOCATION_CHANGE;
export const getAppConfig = (state: RootState) => state.appConfig;
export const getRoute = (state: RootState) => state.router.location.pathname;
export const getState = (state: RootState) => state;
export const replaceRoute = replace;
export const requestBack = goBack;
export const requestRoute = _requestRoute;
export const rootRoute = '';
export const setRoute = push;
