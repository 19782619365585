// Third-party Libraries
import { createSelector } from 'reselect';
import get from 'lodash/get';
// Our code
import { RootState } from '../../store-types';

/**
 * Returns an array of options that includes a label and value for each option`.
 */
const getOptionsAsArray = (options: []) =>
  [...options].sort().map((option: string) => ({
    label: option,
    value: option,
  }));

export const getHasBothProtocols = createSelector(
  (state: RootState) => get(state, 'test.plans.secureBaseUrlIPv4', null),
  (state: RootState) => get(state, 'test.plans.secureBaseUrlIPv6', null),
  (secureBaseUrlIPv4, secureBaseUrlIPv6) =>
    !!secureBaseUrlIPv4 && !!secureBaseUrlIPv6
);

export const getSelectedProtocol = createSelector(
  (state: RootState) => state.advancedSettings.selectedProtocol,
  getHasBothProtocols,
  (selectedProtocol, hasBothProtocols) => {
    if (selectedProtocol) {
      return selectedProtocol;
    } else if (hasBothProtocols) {
      return 'IPv6';
    }
  }
);

export const getSelectedServerLocation = createSelector(
  (state: RootState) => state.advancedSettings.selectedServerLocation,
  (state: RootState) => state.advancedSettings.previousServerLocation,
  (state: RootState) => get(state, 'test.plans.sitename', null),
  (selectedServerLocation, previousServerLocation, sitename) => {
    return selectedServerLocation || previousServerLocation || sitename;
  }
);

export const getServerLocation = createSelector(
  (state: RootState) => get(state, 'test.plans.serverLocation', []),
  serverLocationArray => getOptionsAsArray(serverLocationArray)
);
