// Third-party Libraries
import { connect } from 'react-redux';
// Our Code
import AccessibilityAnnouncer from '../components/AccessibilityAnnouncer/AccessibilityAnnouncer';
import { RootState } from '../api/store-types';

const mapStateToProps = (state: RootState) => {
  if (
    'message' in state.ui.announcement ||
    'politeness' in state.ui.announcement
  ) {
    return {
      message: state.ui.announcement.message,
      politeness: state.ui.announcement.politeness,
      type: state.ui.announcement.type,
    };
  } else {
    return {};
  }
};

const actionCreators = {};

export default connect(
  mapStateToProps,
  actionCreators
)(AccessibilityAnnouncer);
