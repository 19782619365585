// Third-party Libraries
import { createSelector } from 'reselect';
// Our code
import { RootState } from '../../store-types';

export const getProtocolVersion = createSelector(
  (state: RootState) => state.advancedSettings.useAdvancedSettingsTestData,
  (state: RootState) => state.advancedSettings.selectedProtocol,
  (state: RootState) => state.network.clientHasIPv6 || false,
  (useAdvancedSettingsTestData, selectedProtocol, clientHasIPv6) => {
    if (useAdvancedSettingsTestData && selectedProtocol) {
      return selectedProtocol;
    } else {
      return clientHasIPv6 ? 'IPv6' : 'IPv4';
    }
  }
);
