import React from 'react';
import snarkdown from 'snarkdown';
import classnames from 'classnames';

// Finds `<%= any_text %>` with or without the spaces and sets capture group to `any_text`
const REGEXP_REPLACE_TOKENS = /<%=\s*(.+?)\s*%>/gi;

const parse = (entry: string, params: { [index: string]: string }) => {
  let snarkedCopy = snarkdown(entry)
    .replace(/href="htt/gi, 'target="_blank" href="htt')
    .replace(/<script[^>]*>[\s\S]*?<\/script>/gim, ''); // Scrub anything inside a script tag

  if (Object.keys(params).length) {
    return snarkedCopy.replace(REGEXP_REPLACE_TOKENS, (m, key) => {
      return params[key] || `KEY '${key}' NOT FOUND`;
    });
  }

  return snarkedCopy;
};

export interface Props {
  className?: string;
  entry: string;
  onClick?: Function;
  params?: { [index: string]: string };
}

interface Target extends EventTarget {
  hostname: string;
  pathname: string;
}

export const Localize = ({
  className: parentClassNames,
  entry = '',
  onClick,
  params = {},
}: Props) => {
  return (
    <span
      className={classnames(parentClassNames, 'localized')}
      onClick={event => {
        const Target = event.target as Target;
        const isTargetInternal = Target.hostname === window.location.hostname;

        if (isTargetInternal && onClick) {
          event.preventDefault();
          onClick(Target.pathname)(event);
        }
      }}
      dangerouslySetInnerHTML={{
        __html: parse(entry, params),
      }}
    />
  );
};

export default Localize;
