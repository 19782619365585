import { smartFetch } from './data-helpers';

export interface PlanInfoResponse {
  downloadSpeed?: number;
}

/**
 * fetchPlanInfo provides user plan speed
 * throws an error when no sessionId is provided
 * @param {String} sessionId - sessionId for planInfo lookup
 * @returns {Promise}
 */
export function fetchPlanInfo(endpoint: string) {
  return function(sessionId?: string) {
    if (!sessionId) {
      return Promise.reject(new Error('fetchPlanInfo: invalid sessionId'));
    }

    return smartFetch<PlanInfoResponse>(`${endpoint}/${sessionId}`, {
      cache: 'default',
    });
  };
}

export default {
  fetchPlanInfo,
};
