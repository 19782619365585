import React from 'react';
import classnames from 'classnames';
import { ReactComponent as Rocket } from './images/rocket.svg';
import homePageImageStyles from './HomePageContent.module.css';
import XdsButton from '../XdsButton/XdsButton';
import { READY } from '../../api/constants/statuses';
import { START_TEST_BUTTON } from '../../api/constants/bids';
import { requestTest as requestTestAction } from '../../api/actions/request-test';
import { useLocalization } from '../../hooks/localization';

interface Props {
  deviceBestName: string;
  requestTest: Dispatch<typeof requestTestAction>;
  testPlansStatus: string;
}

const HomePageContent = ({
  deviceBestName,
  requestTest,
  testPlansStatus,
}: Props) => {
  const t = useLocalization();
  const deviceName = deviceBestName ? deviceBestName : t('home_device');

  return (
    <div className="md:text-center">
      <h2 className="pb-2 leading-tight border-stroke-theme1-base font-300 text-headline1">
        {t('home_header', { device: deviceName })}
      </h2>
      <p className="text-body1 text-neutral2-base md:max-w-4/5 md:m-auto">
        {t('home_body', { device: deviceName })}
      </p>
      <div className="flex flex-col items-center">
        <Rocket
          className={classnames('my-6 md:my-10', homePageImageStyles.image)}
        />
        <XdsButton
          tone="theme1"
          data-bid={START_TEST_BUTTON}
          onClick={() => requestTest({ useAdvancedSettingsData: false })}
          disabled={testPlansStatus !== READY}
          data-monitoring-target //target selector for monitoring https://github.comcast.com/xfi-ops/headless-endpoint
        >
          {t('home_start_test')}
        </XdsButton>
      </div>
    </div>
  );
};

export default HomePageContent;
