import React from 'react';
import TipsAndToolsContent from '../../components/TipsAndTools/TipsAndTools';
import { requestRoute as requestRouteAction } from '../../api/actions/navigation';

interface Props {
  requestRoute: Dispatch<typeof requestRouteAction>;
  compareByCategoryLink: string;
}

const TipsAndToolsPage = ({ requestRoute, compareByCategoryLink }: Props) => {
  return (
    <div className="container px-2 mt-6 md:px-4">
      <TipsAndToolsContent
        requestRoute={requestRoute}
        compareByCategoryLink={compareByCategoryLink}
      />
    </div>
  );
};

export default TipsAndToolsPage;
