// Third-party Libraries
import { connect } from 'react-redux';
import get from 'lodash/get';
// Our Code
import ResultsPage from '../pages/Results/ResultsPage';
// Actions
import { requestLatencyTest } from '../api/actions/latency-test';
import { requestRoute } from '../api/actions/navigation';
import { requestTest } from '../api/actions/request-test';
import { speedtestCloseModal, speedtestOpenModal } from '../api/actions/modals';
import {
  setServerLocation,
  setSelectedProtocol,
} from '../api/actions/advanced-settings';
// Selectors
import { getHostName } from '../api/selectors/view-state/host-name';
import { getProtocolVersion } from '../api/selectors/view-state/protocol-version';
import {
  getResultsDownloadSpeed,
  getResultsDownloadVehicle,
  getLatencySpeed,
  getUploadSpeed,
} from '../api/selectors/view-state/test-speeds';
import { getCompareByCategoryLink } from '../api/selectors/view-state/device-search';
import { getIsAdvancedSettingsModalOpen } from '../api/selectors/view-state/modals';
import {
  getHasBothProtocols,
  getSelectedProtocol,
  getSelectedServerLocation,
  getServerLocation,
} from '../api/selectors/view-state/advanced-settings';
// Types
import { RootState } from '../api/store-types';

const mapStateToProps = (state: RootState) => ({
  compareByCategoryLink: getCompareByCategoryLink(state),
  deviceBestName: state.deviceFingerprint.deviceDetails.bestName || '',
  downloadSpeedResult: getResultsDownloadSpeed(state),
  downloadSpeedResultVehicle: getResultsDownloadVehicle(state),
  fetchServerLocationStatus:
    state.advancedSettings.statuses.fetchServerLocation.status,
  gatewaySpeedTestAvailable: false, // this value comes from state in SIC
  hasBothProtocols: getHasBothProtocols(state),
  hostName: getHostName(state),
  isAdvancedSettingsModalOpen: getIsAdvancedSettingsModalOpen(state),
  latencySpeed: getLatencySpeed(state),
  protocolVersion: getProtocolVersion(state),
  selectedProtocol: getSelectedProtocol(state),
  selectedServerLocation: getSelectedServerLocation(state),
  serverLocation: getServerLocation(state),
  testPlansStatus: state.test.statuses.fetch.status,
  testResults: get(state, 'test.download.results', {}),
  uploadSpeed: getUploadSpeed(state),
});

const actionCreators = {
  requestLatencyTest,
  requestRoute,
  requestTest,
  setSelectedProtocol,
  setServerLocation,
  speedtestCloseModal,
  speedtestOpenModal,
};

export default connect(
  mapStateToProps,
  actionCreators
)(ResultsPage);
