// Third-party libraries
// TODO: Switch to 'lodash-es' - CIMSI-10109
import { ofType, Epic } from 'redux-observable';
import { from, merge, Observable, zip, Observer } from 'rxjs';
import {
  filter,
  map,
  mapTo,
  mergeMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import get from 'lodash/get';
// SpeedTestJS dependencies for downloads; webpack is handling bundling into single chunk.
import 'speed-testjs/public/lib/xmlhttprequest';
import 'speed-testjs/public/lib/download/desktopTest/algoV1';
import 'speed-testjs/public/lib/downloadHttpConcurrentProgress';
import 'speed-testjs/public/lib/statisticalCalculator';
// Our code
import {
  DOWNLOAD_TEST_DATA_COMPLETED,
  DOWNLOAD_TEST_DATA_STARTED,
  DOWNLOAD_TEST_STARTED,
  DOWNLOAD_TEST_DESKTOP_STARTED,
  DOWNLOAD_TEST_STATS_COMPLETED,
  DOWNLOAD_TEST_STATS_STARTED,
  TEST_CONFIG_POST_COMPLETED,
  SPEED_TEST_ANIMATION_LOADED,
  DOWNLOAD_TEST_DESKTOP_COMPLETED,
} from '../constants/action-types';
import {
  downloadTestStart,
  downloadTestComplete,
  downloadTestDesktopComplete,
  downloadTestDesktopStart,
  getDownloadDataComplete,
  getDownloadDataStart,
  getDownloadStatsComplete,
  getDownloadStatsStart,
  updateTestDownloadCurrent,
} from '../actions/test';
import { getEndpoints } from './epic-helpers';
import { createTestUrls } from './test-helpers';
import { RootState, RootAction, EpicDependencies } from '../store-types';
import { TestState } from '../reducers/test';

declare global {
  interface Window {
    // From speed-testjs
    downloadHttpConcurrentProgress: any;
    statisticalCalculator: any;
    algoV1: any;
  }
}

interface DownloadTestDesktopComplete {
  dataPoints: number[];
  downloadSpeed: number;
}

export interface DownloadTestError {
  status: number;
  statusText: string;
}

interface DownloadTestOptions {
  testConfig: TestState['config'];
  downloadHttpConcurrentProgress: any;
  downloadUrls: string[];
}

interface DesktopDownloadTestOptions {
  testConfig: TestState['config'];
  algoV1: any;
  downloadUrls: string[];
}

function createDownloadTestDataStream({
  testConfig,
  downloadHttpConcurrentProgress,
  downloadUrls,
}: DownloadTestOptions): Observable<RootAction> {
  const {
    DOWNLOAD_CURRENT_TESTS,
    DOWNLOAD_TIMEOUT,
    DOWNLOAD_MOVINGAVERAGE,
    DOWNLOAD_SIZE,
    DOWNLOAD_PROGRESS_INTERVAL,
    MONITOR_INTERVAL,
    IS_MOBILE_PROFILE,
  } = testConfig;

  return Observable.create((observer: Observer<RootAction>) => {
    let downloadTest = new downloadHttpConcurrentProgress(
      downloadUrls,
      'GET',
      DOWNLOAD_CURRENT_TESTS,
      DOWNLOAD_TIMEOUT,
      DOWNLOAD_TIMEOUT,
      DOWNLOAD_MOVINGAVERAGE,
      // on complete callback
      (result: number[]) => {
        observer.next(getDownloadDataComplete(null, result));
        observer.complete();
      },
      // on progress callback
      (result: number) => {
        observer.next(updateTestDownloadCurrent(result));
      },
      // on abort callback
      (result: any) => {
        observer.next(getDownloadDataComplete(result, null));
        observer.complete();
      },
      // on timeout callback
      (result: any) => {
        observer.next(getDownloadDataComplete(result, null));
        observer.complete();
      },
      // on error callback
      (result: any) => {
        observer.next(getDownloadDataComplete(result, null));
        observer.complete();
      },
      DOWNLOAD_SIZE,
      DOWNLOAD_PROGRESS_INTERVAL,
      MONITOR_INTERVAL,
      IS_MOBILE_PROFILE
    );
    downloadTest.initiateTest();
  });
}

function createDownloadTestDesktopStream({
  testConfig,
  algoV1,
  downloadUrls,
}: DesktopDownloadTestOptions): Observable<RootAction> {
  const {
    DOWNLOAD_CURRENT_TESTS,
    DOWNLOAD_TIMEOUT,
    DOWNLOAD_SIZE,
    MONITOR_INTERVAL,
  } = testConfig;

  return Observable.create((observer: Observer<RootAction>) => {
    let downloadTest = new algoV1(
      downloadUrls,
      DOWNLOAD_SIZE,
      DOWNLOAD_CURRENT_TESTS,
      DOWNLOAD_TIMEOUT,
      MONITOR_INTERVAL,
      // on progress callback
      (result: number) => {
        observer.next(updateTestDownloadCurrent(result));
      },
      // on complete callback
      (result: DownloadTestDesktopComplete) => {
        observer.next(
          downloadTestDesktopComplete(null, {
            accumulated: result.dataPoints,
            mean: result.downloadSpeed,
          })
        );
        observer.complete();
      },
      // on error callback
      (result: DownloadTestError) => {
        observer.next(downloadTestDesktopComplete(result, null));
        observer.complete();
      }
    );
    downloadTest.initiateTest();
  });
}

export const generateDownloadDataEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext, window }) => {
  return action$.pipe(
    ofType(DOWNLOAD_TEST_DATA_STARTED),
    withLatestFrom(state$, (action, state) => state),
    map(speedTestContext.getState),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    map(([{ advancedSettings, network, test }, endpoints]) => {
      let downloadUrls = createTestUrls(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        endpoints!.DOWNLOAD_TEST_URL,
        advancedSettings,
        test.plans,
        get(test, 'config.MAX_CONNECTIONS_PER_PORT'),
        network.clientHasIPv6
      );

      return {
        testConfig: test.config,
        downloadHttpConcurrentProgress: window.downloadHttpConcurrentProgress,
        downloadUrls,
      };
    }),
    mergeMap((testOptions: DownloadTestOptions) =>
      createDownloadTestDataStream(testOptions).pipe(
        takeUntil(action$.pipe(ofType(speedTestContext.CHANGE_ROUTE)))
      )
    )
  );
};

/**
 * This epic has three responsibilities.
 * 1. It gathers the information from state that
 *  we need to pass to 'statisticalCalculator'
 * 2. It kicks off the epic that manages 'statisticalCalculator'
 * 3. It manages the output of said epic by mapping it to the
 *  '...COMPELTED' action
 */
export const generateDownloadStatsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext, window }) => {
  return action$.pipe(
    ofType(DOWNLOAD_TEST_STATS_STARTED),
    withLatestFrom(state$, (action, state) => speedTestContext.getState(state)),
    mergeMap(({ test }) =>
      getDownloadStat$({
        accumulated: get(test, 'download.accumulated'),
        endSlice: get(test, 'config.DOWNLOAD_SLICE_END'),
        startSlice: get(test, 'config.DOWNLOAD_SLICE_START'),
        statisticalCalculator: window.statisticalCalculator,
      })
    )
  );
};

export const generateDownloadTestDesktopEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext, window }) => {
  return action$.pipe(
    ofType(DOWNLOAD_TEST_DESKTOP_STARTED),
    withLatestFrom(state$, (action, state) => state),
    map(speedTestContext.getState),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    map(([{ advancedSettings, network, test }, endpoints]) => {
      let downloadUrls = createTestUrls(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        endpoints!.DOWNLOAD_TEST_URL,
        advancedSettings,
        test.plans,
        get(test, 'config.MAX_CONNECTIONS_PER_PORT'),
        network.clientHasIPv6
      );

      return {
        testConfig: test.config,
        algoV1: window.algoV1,
        downloadUrls,
      };
    }),
    mergeMap((testOptions: DesktopDownloadTestOptions) =>
      createDownloadTestDesktopStream(testOptions).pipe(
        takeUntil(action$.pipe(ofType(speedTestContext.CHANGE_ROUTE)))
      )
    )
  );
};

interface StatisticalCalculatorResults {
  stats: {
    sum: number;
    mean: number;
  };
  peakValue: number;
}

/**
 * Calls speed-testJS's 'statisticalCalculator' with the array
 * of results that we got back from 'downloadHttpConcurrentProgress'
 * in order to get meaningful statistics about the data. 'statisticalCalculator'
 * takes a callback function which is fired when it's complete. We're wrapping
 * it in a Promise and then turning that Promise into an Observable so we
 * can process it with RxJS.
 */
export const getDownloadStat$ = ({
  accumulated,
  endSlice,
  startSlice,
  statisticalCalculator,
}: {
  accumulated: number[];
  endSlice: number;
  startSlice: number;
  statisticalCalculator: any;
}) =>
  from(
    new Promise((resolve /* , reject */) => {
      /**
       * 'statisticalCalculator' is being imported from speed-testJS. It is defined on 'window'.
       * To facilitate testing, we're including it as an epic dependency.
       * For more information, see their source code here:
       * https://github.com/Comcast/Speed-testJS/blob/master/public/lib/statisticalCalculator.js#L22
       */
      const calculator = new statisticalCalculator(
        /* the array of download speeds from 'downloadHttpConcurrentProgress' */
        accumulated,
        /* disable the option to use the 'interquartile range' of data (the range of
          values between the 25th and 75th percentile) to perform the calculations */
        false,
        /* 'startSlice' and 'endSlice' are percentages (expressed as decimals) indicating the
        lower and upper boundaries of the data that will be included in the calculation. Values
        below or above these boundaries will be omitted */
        startSlice,
        endSlice,
        /* callback invoked upon completion */
        (results: StatisticalCalculatorResults) => resolve(results)
      );
      calculator.getResults();
    })
  ).pipe(map(({ stats }) => getDownloadStatsComplete(null, stats.mean)));

// The epics below are only responsible for mapping one action to another action
export const scheduleDownloadTestCompleteEpic: Epic<
  RootAction,
  RootAction
> = action$ => {
  const failureScenario$ = action$.pipe(
    ofType(
      DOWNLOAD_TEST_DATA_COMPLETED,
      DOWNLOAD_TEST_DESKTOP_COMPLETED,
      DOWNLOAD_TEST_STATS_COMPLETED
    ),
    filter(
      (
        action:
          | ReturnType<typeof getDownloadDataComplete>
          | ReturnType<typeof downloadTestDesktopComplete>
          | ReturnType<typeof getDownloadStatsComplete>
      ) => action.error
    ),
    map(downloadTestComplete)
  );

  const completionScenario$ = action$.pipe(
    ofType(DOWNLOAD_TEST_DESKTOP_COMPLETED, DOWNLOAD_TEST_STATS_COMPLETED),
    filter(
      (
        action:
          | ReturnType<typeof downloadTestDesktopComplete>
          | ReturnType<typeof getDownloadStatsComplete>
      ) => !action.error
    ),
    mapTo(downloadTestComplete())
  );

  return merge(failureScenario$, completionScenario$);
};

export const scheduleDownloadTestDataStartEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(DOWNLOAD_TEST_STARTED),
    withLatestFrom(state$, (action, state) => state),
    map(speedTestContext.getState),
    map(
      ({ deviceFingerprint }) =>
        /**
         * If the device is a desktop or the device fingerprint is missing,
         * we'll start the 'desktop' test ('algoV1') with DOWNLOAD_TEST_DESKTOP_STARTED.
         * Otherwise, we'll proceed with the 'mobile' test ('downloadHttpConcurrentProgress')
         * with DOWNLOAD_TEST_DATA_STARTED.
         */
        get(deviceFingerprint, 'data.is_full_desktop', true)
          ? downloadTestDesktopStart() // start desktop test
          : getDownloadDataStart() // start mobile test
    )
  );
};

export const scheduleDownloadTestEpic: Epic<
  RootAction,
  RootAction
> = action$ => {
  const testConfigPostCompleted$ = action$.pipe(
    ofType(TEST_CONFIG_POST_COMPLETED)
  );
  const speedTestAnimationLoaded$ = action$.pipe(
    ofType(SPEED_TEST_ANIMATION_LOADED)
  );

  return zip(testConfigPostCompleted$, speedTestAnimationLoaded$).pipe(
    mapTo(downloadTestStart())
  );
};

export const scheduleDownloadTestStatsStartEpic: Epic<
  RootAction,
  RootAction
> = action$ => {
  return action$.pipe(
    ofType(DOWNLOAD_TEST_DATA_COMPLETED),
    filter(
      (action: ReturnType<typeof getDownloadDataComplete>) => !action.error
    ),
    mapTo(getDownloadStatsStart())
  );
};
