import React from 'react';
import classnames from 'classnames';
//import PropTypes from 'prop-types';
import CarouselView from '../CarouselView/CarouselView';

interface Props {
  children: JSX.Element[];
  className?: string;
  itemContainerClassName: string;
  shadow: boolean;
}

/**
 * `Carousel` provides generic base functionality to build up from.  All display logic is split off
 * into `CarouselView`. [Wrap around](https://ccp.sys.comcast.net/browse/CIMSI-9455) ,
 * [index "memory"](https://ccp.sys.comcast.net/browse/CIMSI-10007) between renders, and
 * [a11y](https://ccp.sys.comcast.net/browse/CIMSI-10006) are still to be implemented.
 */
const Carousel = (props: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  /** TODO: Separate story to add [Wrap around](https://ccp.sys.comcast.net/browse/CIMSI-9455) */
  const nextItem = () => {
    const { children } = props;
    const childCount = React.Children.count(children);
    const nextIndex = activeIndex + 1;

    if (nextIndex < childCount) {
      setActiveIndex(nextIndex);
    }
  };

  const previousItem = () => {
    const previousIndex = activeIndex - 1;

    if (previousIndex >= 0) {
      setActiveIndex(previousIndex);
    }
  };

  /**
   * Handles touch swipe behavior
   *
   * TODO: Add mouse dragging
   */
  const handleTouchStart = (
    touchStartEvent: React.TouchEvent<HTMLDivElement>
  ) => {
    const touchStart = touchStartEvent.touches[0].clientX;
    const el = touchStartEvent.currentTarget;
    // This won't change during a move so caching it here...
    const elClientWidth = el.clientWidth;
    let distance = 0;

    const touchMove = (touchMoveEvent: TouchEvent) => {
      const touch = touchMoveEvent.touches[0];

      distance = touch.clientX - touchStart;
      el.style.setProperty('--delta', `${(distance / elClientWidth) * 100}%`);
    };

    const touchEnd = () => {
      const parentEl = el.parentElement;
      if (!parentEl) return;
      const minDistanceToSwitch = parentEl.clientWidth / 4;

      if (distance <= minDistanceToSwitch * -1) {
        nextItem();
      } else if (distance >= minDistanceToSwitch) {
        previousItem();
      }
      el.style.setProperty('--delta', '0%');
      el.classList.add(classnames('duration-medium'));
      el.removeEventListener('touchmove', touchMove);
      el.removeEventListener('touchend', touchEnd);
    };
    el.classList.remove(classnames('duration-medium'));
    el.addEventListener('touchmove', touchMove);
    el.addEventListener('touchend', touchEnd);
  };

  const { children } = props;
  const childCount = React.Children.count(children);
  const isNextDisabled = activeIndex === childCount - 1;
  const isPreviousDisabled = activeIndex === 0;

  return (
    <CarouselView
      activeIndex={activeIndex}
      handleTouchStart={handleTouchStart}
      isNextDisabled={isNextDisabled}
      isPreviousDisabled={isPreviousDisabled}
      selectNextItem={nextItem}
      selectPreviousItem={previousItem}
      updateActiveIndex={setActiveIndex}
      {...props}
    >
      {children}
    </CarouselView>
  );
};

// Carousel.propTypes = {
//   className: PropTypes.string,
//   /**
//    * Classes applied to the div that frames the carousel items.  This is the container on which
//    * the box shadow is applied and where you can apply a border radius.
//    */
//   itemContainerClassName: PropTypes.string,
//   shadow: PropTypes.bool,
// };

export default Carousel;
