/**
 * @module AccessibilityAnnouncer
 */
import React from 'react';
import { getAnnouncerMessage } from '../../api/helpers/accessibility';
import { useLocalization } from '../../hooks/localization';

export type AnnouncerType =
  | 'PAGE_LOADED'
  | 'DEVICE_SUGGESTIONS'
  | 'CLEAR'
  | 'MESSAGE';

interface Props {
  message: string;
  politeness?: 'assertive' | 'off' | 'polite';
  type?: AnnouncerType;
}

/**
 * Shows a message to NVDA immediately. The final message depends on `type` parameter.
 *
 * @param {string} message the message to show to NVDA
 * @param {string} politeness [aria-live](https://www.w3.org/TR/wai-aria/#aria-live) parameter
 * @param {AnnouncerType} type this optional parameter can be used to set the announcer behaviour.
 * It is useful to keep the `i18next` logic inside components only (for performance and bundle size reason).
 * For instance, it can be:
 * - PAGE_LOADED : the Announcer will show `Page Loaded - <message>`
 * - DEVICE_SUGGESTIONS : the Announcer will show `<message> device suggestions found`
 * - CLEAR : no message is showed
 * - MESSAGE(default) : the Announcer will show the message as it is
 *
 */
export const AccessibilityAnnouncer = ({
  message = '',
  politeness = 'polite',
  type = 'MESSAGE',
}: Props) => {
  const label = getAnnouncerMessage(useLocalization(), type, message);
  return (
    <div
      id="a11yAnnouncer"
      aria-live={politeness}
      className="sr-only"
      tabIndex={-1}
    >
      {label}
    </div>
  );
};

export default AccessibilityAnnouncer;
