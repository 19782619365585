import { createAction } from '@reduxjs/toolkit';

import {
  DEVICE_DETAILS_FETCH_STARTED,
  DEVICE_DETAILS_FETCH_COMPLETED,
} from '../constants/action-types';
import { DeviceDetailsResponse } from '../network-interfaces/device-details';

export const fetchDeviceDetailsStart = createAction(
  DEVICE_DETAILS_FETCH_STARTED,
  (uuid = '') => ({
    payload: {
      uuid: uuid,
    },
  })
);

export const fetchDeviceDetailsComplete = createAction(
  DEVICE_DETAILS_FETCH_COMPLETED,
  (error: Error | null, response: DeviceDetailsResponse | null) => ({
    payload: error || response,
    error: Boolean(error),
  })
);
