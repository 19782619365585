import { createReducer } from '@reduxjs/toolkit';

import * as STATUSES from '../constants/statuses';
import { WurflFingerprint } from '../network-interfaces/device-fingerprint';
import {
  fetchDeviceFingerprintStart,
  fetchDeviceFingerprintComplete,
  postDeviceFingerprintStart,
  postDeviceFingerprintComplete,
  fetchDeviceDetailsByFingerprintStart,
  fetchDeviceDetailsByFingerprintComplete,
} from '../actions/device-fingerprint';
import { DeviceDetailsResponse } from '../network-interfaces/device-details';

const { BUSY, ERROR, READY, EMPTY } = STATUSES;

export interface DeviceFingerprintState {
  statuses: {
    fetch: {
      status: UnionOf<typeof STATUSES>;
      error: Error | null;
    };
    fetchDeviceDetails: {
      status: UnionOf<typeof STATUSES>;
      error: Error | null;
    };
    post: {
      status: UnionOf<typeof STATUSES>;
      error: Error | null;
    };
  };
  deviceDetails: {
    bestName: string | null;
    deviceId: string | null;
    expectedDownloadSpeed: string | null;
  };
  data: WurflFingerprint | null;
}

const initialState: DeviceFingerprintState = {
  statuses: {
    fetch: {
      status: EMPTY,
      error: null,
    },
    fetchDeviceDetails: {
      status: EMPTY,
      error: null,
    },
    post: {
      status: EMPTY,
      error: null,
    },
  },
  deviceDetails: {
    bestName: null,
    deviceId: null,
    expectedDownloadSpeed: null,
  },
  data: null,
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(fetchDeviceFingerprintStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        fetch: {
          status: BUSY,
          error: state.statuses.fetch.error,
        },
      },
    }))
    .addCase(fetchDeviceFingerprintComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            fetch: {
              status: ERROR,
              error: action.payload as Error,
            },
          },
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          fetch: {
            status: READY,
            error: null,
          },
        },
        data: action.payload as WurflFingerprint,
      };
    })
    .addCase(postDeviceFingerprintStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        post: {
          status: BUSY,
          error: state.statuses.post.error,
        },
      },
    }))
    .addCase(postDeviceFingerprintComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            post: {
              status: ERROR,
              error: action.payload || null,
            },
          },
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          post: {
            status: READY,
            error: null,
          },
        },
      };
    })
    .addCase(fetchDeviceDetailsByFingerprintStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        fetchDeviceDetails: {
          ...state.statuses.fetchDeviceDetails,
          status: BUSY,
        },
      },
    }))
    .addCase(fetchDeviceDetailsByFingerprintComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            fetchDeviceDetails: {
              status: ERROR,
              error: action.payload as Error,
            },
          },
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          fetchDeviceDetails: {
            status: READY,
            error: null,
          },
        },
        deviceDetails: {
          bestName: (action.payload as DeviceDetailsResponse).displayName,
          deviceId: (action.payload as DeviceDetailsResponse).id,
          expectedDownloadSpeed:
            (action.payload as DeviceDetailsResponse).downloadSpeed || null,
        },
      };
    })
);

export default reducer;
