import React, { ReactNode, MouseEvent, TouchEvent } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as ChevronIcon } from './icons/icon-arrow-right-fillable.svg';
import { useLocalization } from '../../hooks/localization';

// Add CSS variables for type-checking. Requires the React import OR `import 'csstype'`
// See declaration merging: https://www.typescriptlang.org/docs/handbook/declaration-merging.html
declare module 'csstype' {
  interface Properties {
    '--delta'?: string;
  }
}

interface CarouselButtonProps {
  children: ReactNode;
  className?: string;
  isInvisible: boolean;
  isDisabled: boolean;
  label: string;
  onClick: (event: MouseEvent) => void;
}

const CarouselButton = ({
  children,
  className: parentClassNames,
  isInvisible,
  isDisabled,
  label,
  onClick,
}: CarouselButtonProps) => (
  <button
    className={classnames(
      parentClassNames,
      'relative z-10 hidden sm:block text-neutral2-base',
      { invisible: isInvisible },
      isDisabled
        ? 'opacity-50'
        : 'hover:text-theme1-base focus:text-theme1-base'
    )}
    disabled={isDisabled}
    onClick={onClick}
  >
    <span className="sr-only">{label}</span>
    {children}
  </button>
);

interface Props {
  activeIndex: number;
  children: JSX.Element[];
  className?: string;
  handleTouchStart: (event: TouchEvent) => void;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
  itemContainerClassName: string;
  selectNextItem: () => void;
  selectPreviousItem: () => void;
  shadow: boolean;
  updateActiveIndex: (newIndex: number) => void;
}

/**
 * `CarouselView` purely handles visual render logic for the base carousel.  It is usually paired
 * with `Carousel` to receive behavior logic. When there is only one carousel item, no controls
 * are displayed, but the item is still laid out the same as if the controls were there.
 */
const CarouselView = ({
  activeIndex = 0,
  children,
  className: parentClassNames,
  handleTouchStart,
  isNextDisabled,
  isPreviousDisabled,
  itemContainerClassName,
  selectNextItem,
  selectPreviousItem,
  shadow,
  updateActiveIndex,
}: Props) => {
  const childCount = React.Children.count(children);
  const hasOneCard = childCount === 1;
  const containerWidth = childCount * 100;
  const childrenWidth = 100 / childCount;
  const t = useLocalization();

  return (
    <div className={classnames(parentClassNames)}>
      <div className="flex">
        <CarouselButton
          className="pr-4 md:pr-2"
          isInvisible={hasOneCard}
          isDisabled={isPreviousDisabled}
          label={t('common_previousSlide')}
          onClick={selectPreviousItem}
        >
          <ChevronIcon
            className="transform rotate-180 fill-current w-25 h-25"
            aria-hidden="true"
          />
        </CarouselButton>

        <div
          className={classnames(
            'flex-1 overflow-hidden',
            { shadow: shadow },
            itemContainerClassName
          )}
        >
          <div
            className="flex flex-1 transition-transform duration-medium"
            onTouchStart={hasOneCard ? undefined : handleTouchStart}
            aria-live="polite"
            style={{
              '--delta': '0%',
              width: `${containerWidth}%`,
              transform: `translate3d(calc(-${activeIndex *
                childrenWidth}% + var(--delta)), 0, 0)`,
            }}
            aria-label={t('accessibility_carouselSlides', {
              slidesCount: childCount,
            })}
          >
            {React.Children.map(children, (child, index) => {
              const { 'aria-label': ariaLabel } = child.props;

              return (
                <section
                  key={ariaLabel || index}
                  style={{
                    width: `${childrenWidth}%`,
                  }}
                  aria-hidden={activeIndex !== index}
                  className="flex items-center justify-center transition-opacity select-none duration-slow"
                >
                  {child}
                </section>
              );
            })}
          </div>
        </div>

        <CarouselButton
          className="pl-4 md:pl-2"
          isInvisible={hasOneCard}
          isDisabled={isNextDisabled}
          label={t('common_nextSlide')}
          onClick={selectNextItem}
        >
          <ChevronIcon className="fill-current w-25 h-25" aria-hidden="true" />
        </CarouselButton>
      </div>

      <ol
        aria-label={t('common_carouselItems')}
        className="flex items-center justify-center py-6 list-none"
      >
        {React.Children.map(children, ({ props }, index) => {
          return (
            <li key={props.id}>
              <button
                className={classnames(
                  'rounded-xlarge mx-1 w-10 h-10 duration-medium transition-transform',
                  {
                    'bg-fill-theme1-base': index === activeIndex,
                    'bg-grey-6': index !== activeIndex,
                    invisible: hasOneCard,
                  }
                )}
                onClick={() => updateActiveIndex(index)}
                disabled={hasOneCard}
              >
                <span className="sr-only">
                  {t('common_viewSlide', { index: index + 1 })}
                  {index === activeIndex && ' ' + t('common_currentSlide')}
                </span>
              </button>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

CarouselView.propTypes = {
  activeIndex: PropTypes.number,
  className: PropTypes.string,
  handleTouchStart: PropTypes.func,
  isNextDisabled: PropTypes.bool,
  isPreviousDisabled: PropTypes.bool,
  itemContainerClassName: PropTypes.string,
  selectNextItem: PropTypes.func,
  selectPreviousItem: PropTypes.func,
  shadow: PropTypes.bool,
  updateActiveIndex: PropTypes.func,
};

export default CarouselView;
