import { createReducer } from '@reduxjs/toolkit';

import { desktopTestConfig, mobileTestConfig } from '../constants/test-config';
import * as STATUSES from '../constants/statuses';
import {
  PostDownloadTestResponse,
  TestPlansResponse,
  PostTestConfigResponse,
} from '../network-interfaces/test';
import {
  downloadTestStart,
  downloadTestComplete,
  uploadTestStart,
  uploadTestComplete,
  testStart,
  testComplete,
  fetchTestPlansStart,
  fetchTestPlansComplete,
  postTestConfigStart,
  postTestConfigComplete,
  postDownloadTestResultsStart,
  postDownloadTestResultsComplete,
  postLatencyAndUploadTestResultsStart,
  postLatencyAndUploadTestResultsComplete,
  updateTestDownloadCurrent,
  getDownloadDataComplete,
  getDownloadStatsComplete,
  downloadTestDesktopComplete,
  updateTestUploadCurrent,
} from '../actions/test';
import { latencyTestStart, latencyTestComplete } from '../actions/latency-test';
import {
  fetchSessionIdStart,
  fetchSessionIdComplete,
} from '../actions/session-id';
import { fetchDeviceFingerprintComplete } from '../actions/device-fingerprint';
import { WurflFingerprint } from '../network-interfaces/device-fingerprint';
import { SessionIdResponse } from '../network-interfaces/session-id';

const { BUSY, EMPTY, ERROR, READY } = STATUSES;

interface Status {
  status: UnionOf<typeof STATUSES>;
  error: Error | null;
}

export interface TestState {
  statuses: {
    downloadTest: Status;
    fetch: Status;
    fetchSessionId: Status;
    latencyTest: Status;
    postTestConfig: Status;
    postDownloadTestResults: Status;
    postLatencyAndUploadTestResults: Status;
    test: Status;
    uploadTest: Status;
  };
  config: typeof desktopTestConfig | typeof mobileTestConfig;
  download: {
    current: number | null;
    accumulated: number[] | null;
    results: PostDownloadTestResponse | null;
    mean: number | null;
  };
  latency: { min: number };
  plans: TestPlansResponse | null;
  sessionId: string | null;
  testId: string | null;
  upload: {
    current: number;
    mean: number | null;
  };
}

const initialState: TestState = {
  statuses: {
    downloadTest: {
      status: EMPTY,
      error: null,
    },
    fetch: {
      status: EMPTY,
      error: null,
    },
    fetchSessionId: {
      status: EMPTY,
      error: null,
    },
    latencyTest: {
      status: EMPTY,
      error: null,
    },
    postTestConfig: {
      status: EMPTY,
      error: null,
    },
    postDownloadTestResults: {
      status: EMPTY,
      error: null,
    },
    postLatencyAndUploadTestResults: {
      status: EMPTY,
      error: null,
    },
    test: {
      status: EMPTY,
      error: null,
    },
    uploadTest: {
      status: EMPTY,
      error: null,
    },
  },
  config: desktopTestConfig,
  download: {
    current: 0,
    accumulated: null,
    results: null,
    mean: 0,
  },
  latency: { min: 0 },
  plans: null,
  sessionId: null,
  testId: null,
  upload: {
    current: 0,
    mean: 0,
  },
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(downloadTestStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        downloadTest: {
          ...state.statuses.downloadTest,
          status: BUSY,
        },
      },
    }))
    .addCase(downloadTestComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            downloadTest: {
              status: ERROR,
              error: action.payload,
            },
          },
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          downloadTest: {
            status: READY,
            error: null,
          },
        },
      };
    })
    .addCase(latencyTestStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        latencyTest: {
          ...state.statuses.latencyTest,
          status: BUSY,
        },
      },
      latency: {
        min: 0,
      },
    }))
    .addCase(latencyTestComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            latencyTest: {
              status: ERROR,
              error: action.payload,
            },
          },
          latency: {
            min: 0,
          },
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          latencyTest: {
            status: READY,
            error: null,
          },
        },
        latency: {
          min: action.payload.min,
        },
      };
    })
    .addCase(uploadTestStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        uploadTest: {
          ...state.statuses.uploadTest,
          status: BUSY,
        },
      },
    }))
    .addCase(uploadTestComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            uploadTest: {
              status: ERROR,
              error: action.payload as Error,
            },
          },
          upload: {
            ...state.upload,
            mean: 0,
          },
        };
      }

      if ('mean' in action.payload) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            uploadTest: {
              status: READY,
              error: null,
            },
          },
          upload: {
            ...state.upload,
            mean: action.payload.mean,
          },
        };
      }
    })
    .addCase(testStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        /**
         * TEST_STARTED can be dispatched from the Results page
         * when the 'Test Again' button is clicked. To prepare
         * all of the tests to be run again, we want to set them
         * to their "pretest" or "initial" state.
         */
        downloadTest: initialState.statuses.downloadTest,
        latencyTest: initialState.statuses.latencyTest,
        uploadTest: initialState.statuses.uploadTest,
        test: {
          ...state.statuses.test,
          status: BUSY,
        },
      },
      download: {
        current: 0,
        accumulated: null,
        results: null,
        mean: 0,
      },
      latency: {
        min: 0,
      },
      upload: initialState.upload,
    }))
    .addCase(testComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            test: {
              status: ERROR,
              error: action.payload,
            },
          },
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          test: {
            status: READY,
            error: null,
          },
        },
      };
    })
    .addCase(fetchSessionIdStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        fetchSessionId: {
          ...state.statuses.fetchSessionId,
          status: BUSY,
        },
      },
    }))
    .addCase(fetchSessionIdComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            fetchSessionId: {
              status: ERROR,
              error: action.payload as Error,
            },
          },
          sessionId: null,
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          fetchSessionId: {
            status: READY,
            error: null,
          },
        },
        sessionId: (action.payload as SessionIdResponse).id || null,
      };
    })
    .addCase(fetchTestPlansStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        fetch: {
          ...state.statuses.fetch,
          status: BUSY,
        },
      },
    }))
    .addCase(fetchTestPlansComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            fetch: {
              status: ERROR,
              error: action.payload as Error,
            },
          },
          plans: null,
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          fetch: {
            status: READY,
            error: null,
          },
        },
        plans: action.payload as TestPlansResponse,
      };
    })
    .addCase(postTestConfigStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        postTestConfig: {
          ...state.statuses.postTestConfig,
          status: BUSY,
        },
      },
    }))
    .addCase(postTestConfigComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            postTestConfig: {
              status: ERROR,
              error: action.payload as Error,
            },
          },
          testId: null,
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          postTestConfig: {
            status: READY,
            error: null,
          },
        },
        testId: (action.payload as PostTestConfigResponse).testId,
      };
    })
    .addCase(postDownloadTestResultsStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        postDownloadTestResults: {
          ...state.statuses.postDownloadTestResults,
          status: BUSY,
        },
      },
    }))
    .addCase(postDownloadTestResultsComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            postDownloadTestResults: {
              status: ERROR,
              error: action.payload as Error,
            },
          },
          download: {
            ...state.download,
            results: null,
          },
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          postDownloadTestResults: {
            status: READY,
            error: null,
          },
        },
        download: {
          ...state.download,
          results: action.payload as PostDownloadTestResponse,
        },
      };
    })
    .addCase(postLatencyAndUploadTestResultsStart, state => ({
      ...state,
      statuses: {
        ...state.statuses,
        postLatencyAndUploadTestResults: {
          ...state.statuses.postLatencyAndUploadTestResults,
          status: BUSY,
        },
      },
    }))
    .addCase(postLatencyAndUploadTestResultsComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          statuses: {
            ...state.statuses,
            postLatencyAndUploadTestResults: {
              status: ERROR,
              error: action.payload as Error,
            },
          },
        };
      }

      return {
        ...state,
        statuses: {
          ...state.statuses,
          postLatencyAndUploadTestResults: {
            status: READY,
            error: null,
          },
        },
      };
    })
    .addCase(fetchDeviceFingerprintComplete, (state, action) => ({
      ...state,
      config:
        /**
         * If there's an error or device fingerprint indicates it's a desktop,
         * use the test config for a desktop. Otherwise, use the test config
         * for a mobile device.
         */
        action.error ||
        (action.payload && (action.payload as WurflFingerprint).is_full_desktop)
          ? state.config
          : mobileTestConfig,
    }))
    .addCase(updateTestDownloadCurrent, (state, action) => ({
      ...state,
      download: {
        ...state.download,
        current: action.payload,
      },
    }))
    .addCase(getDownloadDataComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          download: {
            ...state.download,
            accumulated: null,
          },
        };
      }

      if ('accumulated' in action.payload) {
        return {
          ...state,
          download: {
            ...state.download,
            accumulated: action.payload.accumulated,
          },
        };
      }
    })
    .addCase(getDownloadStatsComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          download: {
            ...state.download,
            mean: 0,
          },
        };
      }

      if ('mean' in action.payload) {
        return {
          ...state,
          download: {
            ...state.download,
            /**
             * When a download test is run on a desktop, the last time that `algoV1` calls its `onProgress`
             * callback (this is the value the we use to set `current`), it passes it the mean value of the
             * accumulated results. When a test is not run on a mobile device, we use `downloadHttpConcurrentProgress`.
             * This function does not compute the mean. In order to get the 'mean' to display as the last
             * current value for desktop and mobile tests, we're manually setting `current` with the `mean`
             * value here.
             */
            current: action.payload.mean,
            mean: action.payload.mean,
          },
        };
      }
    })
    .addCase(downloadTestDesktopComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          download: {
            ...state.download,
            accumulated: null,
            mean: 0,
          },
        };
      }

      if ('accumulated' in action.payload) {
        return {
          ...state,
          download: {
            ...state.download,
            accumulated: action.payload.accumulated,
            mean: action.payload.mean,
          },
        };
      }
    })
    .addCase(updateTestUploadCurrent, (state, action) => ({
      ...state,
      upload: {
        ...state.upload,
        current: action.payload,
      },
    }))
);

export default reducer;
