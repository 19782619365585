// Third-party Libraries
import pathToRegexp from 'path-to-regexp';
import { createSelector } from 'reselect';

// Our Code
import { requestBack } from '../../actions/navigation';
import { hideDeviceSearch } from '../../actions/ui';
import { HEADER_CTA_TYPES } from '../../constants/header-ctas';
import { READY } from '../../constants/statuses';
import { Route, ROUTES } from '../../constants/routes';

import { RootState } from '../../store-types';

type MatchedRoute = [Route, UnionOf<typeof ROUTES>];

/** Returns the first matched route by RegEx If no routes match the pathname,
 * the ERROR route is returned.
 */
export const matchRoute = (pathname: string) =>
  (Object.entries(ROUTES).find(([, route]) =>
    pathname.match(pathToRegexp(route))
  ) || ['ERROR', ROUTES.ERROR]) as MatchedRoute;

/** This selector returns the first matched route RegEx. */
export const matchedRoute = createSelector(
  (state: RootState) => state.router.location.pathname,
  currentRoute => matchRoute(currentRoute)
);

const deviceSearchIsActive = createSelector(
  (state: RootState) => state.ui.showDeviceSearch,
  matchedRoute,
  (showDeviceSearch, [, matchedRoute]) => {
    switch (matchedRoute) {
      case ROUTES.COMPARE_BY_CATEGORY:
      case ROUTES.KNOWN_DEVICE:
        return showDeviceSearch;
      default:
        return false;
    }
  }
);

/**
 * This selector will return whether the Advanced Settings Gear button should be disabled.
 */
export const getIsAdvancedSettingsDisabled = createSelector(
  (state: RootState) => state.test.statuses.fetch.status,
  testPlansStatus => testPlansStatus !== READY
);

/**
 * If needed, this selector will return whatever function should be bound to the Back button.
 */
export const getOnBack = createSelector(
  deviceSearchIsActive,
  deviceSearchIsActive =>
    deviceSearchIsActive ? hideDeviceSearch : requestBack
  // In this particular case, we are overriding the back button
  // while the Device Search is active so that a user can use it to cancel the Device Search.
);

/**
 * If needed, this selector will return a HEADER_CTA_TYPES slug. That slug should match up
 * with a component in the Header container that should be passed into the `right` prop.
 */
export const getRight = createSelector(
  matchedRoute,
  (state: RootState) => state.ui.showTestingScreen,
  (
    [, matchedRoute],
    isTestingScreenVisible
  ): UnionOf<typeof HEADER_CTA_TYPES> | undefined => {
    switch (matchedRoute) {
      case ROUTES.HOME:
        // Since the Home page also contains the Testing view,
        // we need to hide the gear while a test is in progress
        if (!isTestingScreenVisible) return HEADER_CTA_TYPES.ADVANCED_SETTINGS;
        break;
      case ROUTES.RESULTS:
        return HEADER_CTA_TYPES.ADVANCED_SETTINGS;
      default:
        break;
    }
  }
);

/**
 * This selector will take the current route and return whether a back button should be visible.
 */
export const getShowBackButton = createSelector(
  matchedRoute,
  ([, matchedRoute]) => {
    switch (matchedRoute) {
      case ROUTES.HOME:
      case ROUTES.ERROR:
        return false;
      default:
        return true;
    }
  }
);
