import { smartFetch } from './data-helpers';

export interface ServerLocation {
  IPv4Address: string;
  IPv6Address: string;
  Location: string;
  Statename: string;
  Fqdn: string;
  ServerId: number;
  Sitename: string;
  cranData: {
    division: string;
    market: string;
    region: string;
  };
}

export type ServerLocationResponse = ServerLocation[];

/**
 * Gets server location data from middleware service
 * @param {string} endpoint
 * @returns {(serverLocation: string) => Promise}
 */
export function fetchServerLocation(endpoint: string) {
  return function(serverLocation: string) {
    const serverLocationURL = endpoint.replace(
      '<%serverLocation%>',
      encodeURIComponent(serverLocation)
    );

    return smartFetch<ServerLocationResponse>(serverLocationURL, {
      cache: 'default',
    });
  };
}
