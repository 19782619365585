import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import DeviceInfo from '../DeviceInfo/DeviceInfo';
import PlanInfo from '../PlanInfo/PlanInfo';
import SectionHeader from '../SectionHeader/SectionHeader';
import SimilarDevice from '../SimilarDevice/SimilarDevice';
import Link from '../Link/Link';
import { calculatePercentage } from '../../modules/known-device/known-device';
import { requestRoute as requestRouteAction } from '../../api/actions/navigation';
import { useLocalization } from '../../hooks/localization';

interface Props extends HTMLAttributes<HTMLDivElement> {
  name: string;
  maxSpeed: string;
  planSpeed: string;
  type: string;
  similarDevices: {
    title: string;
    maxDownloadSpeed: string;
    devices: {
      id: string;
      displayName: string;
      downloadSpeed: string;
      formFactor: string;
    }[];
  };
  isYourDevice: boolean;
  requestRoute: Dispatch<typeof requestRouteAction>;
}

const KnownDevice = ({
  name,
  maxSpeed,
  planSpeed,
  type,
  similarDevices,
  isYourDevice = false,
  requestRoute,
  className: parentClassNames,
  ...props
}: Props) => {
  const t = useLocalization();
  return (
    <div className={classnames(parentClassNames, 'flex flex-col')} {...props}>
      <div className="p-6 py-2 md:pb-6 md:pt-0">
        <DeviceInfo
          name={name}
          maxSpeed={maxSpeed}
          type={type}
          isYourDevice={isYourDevice}
          planSpeed={planSpeed}
        />
      </div>
      {planSpeed && (
        <PlanInfo
          planSpeed={planSpeed}
          className="inline-block px-6 py-6 mx-2 italic leading-normal tracking-wide border-t border-b-0 text-neutral2-base text-body2 border-grey-2 sm:my-6 md:hidden"
        />
      )}
      <div className="flex flex-col">
        <SectionHeader
          className="px-8 border-b border-b-0 border-grey-2 md:mx-8 md:px-0"
          header={similarDevices.title}
        />
        <div className="flex flex-col py-6 mx-8 md:flex-row md:flex-wrap md:justify-between md:py-12">
          <SimilarDevice
            borderActive
            categoryMaxSpeed={similarDevices.maxDownloadSpeed}
            name={name}
            percent={calculatePercentage(
              parseInt(maxSpeed),
              parseInt(similarDevices.maxDownloadSpeed)
            )}
            speed={maxSpeed}
            type={type}
          />
          {similarDevices.devices.map(similarDevice => (
            <SimilarDevice
              key={similarDevice.id}
              categoryMaxSpeed={similarDevices.maxDownloadSpeed}
              name={similarDevice.displayName}
              percent={calculatePercentage(
                parseInt(similarDevice.downloadSpeed),
                parseInt(similarDevices.maxDownloadSpeed)
              )}
              speed={similarDevice.downloadSpeed}
              type={similarDevice.formFactor}
            />
          ))}
        </div>
      </div>
      <div className="pt-10 pb-10 text-center bg-grey-1">
        <Link
          href="/devicespeeds"
          requestRoute={requestRoute}
          className="link text-body1"
        >
          <span className="font-700">{t('compareByCategory_lookup')}</span>
        </Link>
      </div>
    </div>
  );
};

export default KnownDevice;
