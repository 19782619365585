import React from 'react';
import classnames from 'classnames';
import { ReactComponent as PositiveSvgPath } from './icons/icon-checkmark.svg';
import { ReactComponent as NegativeSvgPath } from './icons/icon-x.svg';
import { useLocalization } from '../../hooks/localization';

interface Props {
  capabilities: Capability[];
  className?: string;
}

export interface Capability {
  check: boolean;
  position: number;
  text: string;
}

const CapabilitiesList = ({
  capabilities = [],
  className: parentClassNames,
}: Props) => {
  const t = useLocalization();

  return (
    <ul
      className={classnames(
        parentClassNames,
        'list-none p-0 grid grid-cols-2 gap-2 leading-loose mb-4'
      )}
    >
      {capabilities.map(item => {
        const isCapable = item.check;

        return (
          <li key={item.position} className="flex items-center text-body1">
            {isCapable ? (
              <PositiveSvgPath
                className="pr-1"
                style={{ width: 16, height: 16 }}
                aria-hidden="true"
              />
            ) : (
              <NegativeSvgPath
                className="pr-1"
                style={{ width: 16, height: 16 }}
                aria-hidden="true"
              />
            )}
            <span className="sr-only">
              {isCapable
                ? t('accessibility_checksPassed', { checks: '' })
                : t('accessibility_checksFailed', {
                    checks: '',
                  })}
            </span>
            {item.text}
          </li>
        );
      })}
    </ul>
  );
};

export default CapabilitiesList;
