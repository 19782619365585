import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import spinnerStyles from './Spinner.module.css';

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

let spokeStyles: string[] = [];

// numbers 1 - 12 represent the number of spokes in the spinner
for (let spokeNum = 1; spokeNum <= 12; spokeNum++) {
  spokeStyles.push(`spoke${spokeNum}`);
}

const Spinner = ({ className: parentClassNames, ...props }: Props) => {
  return (
    <div
      className={classnames(parentClassNames, spinnerStyles.spinner)}
      {...props}
    >
      {spokeStyles.map(spokeStyle => {
        return (
          <div
            className={classnames(
              spinnerStyles.spoke,
              spinnerStyles[spokeStyle]
            )}
            key={spokeStyle}
          />
        );
      })}
    </div>
  );
};

export default Spinner;
