// Third-party libraries
import get from 'lodash/get';
import { ofType, Epic } from 'redux-observable';
import { map } from 'rxjs/operators';
// Our code
import {
  makeScreenReaderAnnouncement,
  focusDeviceSearchInputCompleted,
} from '../actions/ui';
import {
  A11Y_ANNOUNCER_FOCUS,
  DEVICE_SEARCH_SET_INPUT_FOCUS_STARTED,
} from '../constants/action-types';
import { RootState, RootAction, EpicDependencies } from '../store-types';

export const setFocusEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, _state$, { document }) =>
  action$.pipe(
    ofType(A11Y_ANNOUNCER_FOCUS),
    map(state => get(state, 'payload.description')),
    map(description => {
      setAnnouncerFocus(document);
      return makeScreenReaderAnnouncement({
        message: description,
        type: 'PAGE_LOADED',
      });
    })
  );

export const focusDeviceSearchInputEpic: Epic<RootAction> = (
  action$,
  _state$,
  { document }
) =>
  action$.pipe(
    ofType(DEVICE_SEARCH_SET_INPUT_FOCUS_STARTED),
    map(() => {
      setDeviceSearchInputFocus(document);
      return focusDeviceSearchInputCompleted();
    })
  );

function setAnnouncerFocus(document: Window['document']) {
  const a11yAnnouncer: HTMLDivElement | null = document.querySelector(
    '#a11yAnnouncer'
  );

  if (a11yAnnouncer) {
    a11yAnnouncer.focus();
  }
}

function setDeviceSearchInputFocus(document: Window['document']) {
  const deviceSearchInput: HTMLDivElement | null = document.querySelector(
    '#device-search-autocomplete'
  );

  if (deviceSearchInput) {
    deviceSearchInput.focus();
  }
}
