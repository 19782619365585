import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  primaryButton: JSX.Element;
  secondaryButton: JSX.Element;
}

const ModalActionBar = ({
  className: parentClassNames,
  primaryButton,
  secondaryButton,
  ...props
}: Props) => {
  return (
    <div
      className={classnames(
        parentClassNames,
        'flex justify-around items-center'
      )}
      {...props}
    >
      {!!secondaryButton && <div>{secondaryButton}</div>}
      <div>{primaryButton}</div>
    </div>
  );
};

export default ModalActionBar;
