/**
 * @fileoverview Reusable RxJS-style "Operator"s that are useful for composing redux-observable Epics.
 *
 * TODO: Potentially remove `fetchData` and `waitForPromise` and import their equivalents
 * from `xfi-client-core/src/helpers/epic-helpers` instead.
 */
import { from, fromEvent, of, pipe } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  first,
  map,
  mergeMap,
} from 'rxjs/operators';
import { RootAction, RootState } from '../store-types';
import { AppConfigState } from '../reducers/app-config';

/**
 * An RxJS-style "Operator" that abstracts away the logic
 * necessary for fetching data via dispatched actions.
 *
 * @param {Function} fetchFunction the function which will handle making the network request
 * @param {Function} onFetchSuccess the function to invoke if the fetch is successful
 * @param {Function} onFetchFailure the function to invoke if the fetch fails
 * @param {Function} [processActionForFetchFunction] optional function for processing data from the action so it can be passed as an argument to the fetchFunction
 */
export const fetchData = <T>(
  fetchFunction: (...params: any) => Promise<T>,
  onFetchSuccess: (response: T) => RootAction,
  onFetchFailure: (error: Error) => RootAction,
  processActionForFetchFunction?: (action: RootAction) => any
) =>
  pipe(
    map((action: RootAction) => {
      if (processActionForFetchFunction) {
        return processActionForFetchFunction(action);
      }
    }),
    mergeMap(fetchArgs => from(fetchFunction(fetchArgs))),
    map(onFetchSuccess),
    catchError(rejection => of(onFetchFailure(rejection)))
  );

/**
 * Creates a `load` event observable based on the given `window`.
 *
 * @param {Object} window window object to listen for `load`
 * @returns {Object} Observable stream
 */
export const fromLoadEvent = (window: Window) =>
  fromEvent(window, 'load').pipe(first());

/**
 * An RxJS-style "Operator" that retrieves the currently loaded endpoints.
 * This operator is intended to work on state$.
 *
 * @param {Function} getAppConfig returns the current appConfig from state
 */
export const getEndpoints = (
  getAppConfig: (state: RootState) => AppConfigState
) =>
  pipe(
    map(getAppConfig),
    map((appConfig: AppConfigState) => appConfig && appConfig.endpoints),
    distinctUntilChanged()
  );

/**
 * An RxJS-style "Operator" for working with "Observables". An Observable
 * can be "piped" to waitForPromise the same way that you would pipe
 * an Observable to 'map', 'delay', 'filter' or any other Operator.
 *
 * waitForPromise returns an Observable. If the Promise returned by
 * invoking promiseFunction fullfills (i.e. resolves successfully)
 * then the returned Obserable will have the property 'resolution'
 * set to the value that the Promise resolved to. If the Promise
 * rejects, then the Observable will have the property 'rejection'
 * set to the value the Promise rejected with.
 *
 * waitForPromise accepts a callback whose areguments are the values
 * previously emitted
 * @example
 * // If the native fetch API was passed in as promiseFunction and
 * // the action {type: 'PING'} was dispatched, the argument 'values'
 * // will be {type: 'PING'}
 * action$.pipe(
 *   waitForPromise(values => fetch(values))
 * )
 *
 * @param {Function} promiseFunction a function which returns a Promise
 * @returns {Observable} Observable with the property 'resolution' or
 *   'rejection' set to the value the Promise resolved to or rejected with
 *   respectively
 */
export const waitForPromise = <T>(
  promiseFunction: (values: any) => Promise<T>
) =>
  pipe(
    mergeMap(values => from(promiseFunction(values))),
    map(resolution => ({ resolution })),
    catchError(rejection => of({ rejection }))
  );
