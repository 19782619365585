import { createAction } from '@reduxjs/toolkit';

import {
  SERVER_LOCATION_FETCH_COMPLETED,
  SERVER_LOCATION_FETCH_STARTED,
  SET_SELECTED_PROTOCOL,
  SET_SECURE_URLS,
  SET_SERVER_LOCATION,
} from '../constants/action-types';
import { ServerLocationResponse } from '../network-interfaces/advanced-settings';

export const fetchServerLocationComplete = createAction(
  SERVER_LOCATION_FETCH_COMPLETED,
  (error: Error | null, response: ServerLocationResponse | null) => ({
    payload: error || response,
    error: Boolean(error),
  })
);

export const fetchServerLocationStart = createAction(
  SERVER_LOCATION_FETCH_STARTED,
  (serverLocation: string) => ({
    payload: {
      serverLocation,
    },
  })
);

export const setSecureUrls = createAction(
  SET_SECURE_URLS,
  (
    serverLocationSecureIPv4Url: string,
    serverLocationSecureIPv6Url: string
  ) => ({
    payload: {
      serverLocationSecureIPv4Url,
      serverLocationSecureIPv6Url,
    },
  })
);

export const setSelectedProtocol = createAction(
  SET_SELECTED_PROTOCOL,
  (protocol: string) => ({
    payload: {
      protocol,
    },
  })
);

export const setServerLocation = createAction(
  SET_SERVER_LOCATION,
  (previousServerLocation: string | null, serverLocation: string) => ({
    payload: {
      previousServerLocation,
      serverLocation,
    },
  })
);
