/**
 * @fileoverview Gathers performance timestamps and other data that has already been stored using
 * `surfnperf` and `epics/performance-marks` and then calculates app loading performance metrics.
 * It requires the `surfnperf` and `surfnperf/resource-timing` modules and the `window` object as
 * Epic middleware dependencies.
 */
// Third-party libraries
import get from 'lodash/get';
import surfnperf from 'surfnperf';
// Our code
import perfHelpers from 'xfi-client-core/src/helpers/surf-n-perf-helpers';
import {
  APP_FAILURE_MARK,
  APP_FULLY_LOADED_MARK,
  APP_READY_MARK,
  APP_RENDERED_MARK,
  ERROR_MARK,
  LATENCYS_FETCH_COMPLETED_MARK,
  LOAD_EVENT_END_MARK,
  NAVIGATION_START_MARK,
  PAGE_START_MARK,
} from '../../api/constants/performance-marks';
import * as PERF_MARKS from '../../api/constants/performance-marks';

declare global {
  interface Window {
    SURF_N_PERF: any;
  }
}

interface PerfData {
  error?: string;
  initialUrl?: string;
  fullRequest?: number;
  navigationStart?: number;
  networkLatency?: number;
  networkTime?: number;
  loadEventEnd?: number;
  pageStart?: number;
  processing?: number;
  serverTime?: number;
  loading?: number;
  appReady?: number;
  status?: string;
  appFailure?: number;
  appRendered?: number;
  initialVis?: string;
  hiddenTime?: number;
  connection?: {};
}

interface SecondaryPerfData extends PerfData {
  appFullyLoaded: number;
  deviceDetailsByFingerPrintLoaded: number;
  deviceFingerprintLoaded: number;
  deviceFingerprintPosted: number;
  latencysLoaded: number;
  secondaryLoading: number;
  sessionIdLoaded: number;
  testPlansLoaded: number;
}

/**
 * Calculates primary app loading metrics based on timestamps and other data already stored using
 * {@link https://github.com/Comcast/Surf-N-Perf|Surf-N-Perf}. It expects SnP to already have
 * made the expected markings including generic marks setup in `setup.surfnperf.js` as well
 * as app specific marks like APP_READY made in `epics/performance-marks`.
 *
 * TODO: Collect data similar to `resourceTiming.getResourceTrackingData` in SIC.
 *
 * @param {Object} dependencies Injected dependencies
 * @param {Object} dependencies.resourceTiming `surfnperf/resource-timing` module
 * @param {Object} dependencies.surfnperf `surfnperf` module
 * @param {Object} dependencies.window window where `setup.surfnperf.js` attaches a `SURF_N_PERF` object
 * @return {Object} Primary load metrics for logging
 */
export function gatherPerfData({
  resourceTiming,
  surfnperf,
  window,
}: {
  resourceTiming: any;
  surfnperf: any;
  window: Window;
}) {
  let perfData: PerfData = {
    initialUrl: surfnperf.getCustom('initialUrl'),
    fullRequest: surfnperf.getFullRequestLoadTime(),
    navigationStart: surfnperf.getTimingMark(NAVIGATION_START_MARK),
    networkLatency: surfnperf.getNetworkLatency(),
    networkTime: surfnperf.getNetworkTime(),
    loadEventEnd: surfnperf.duration(
      NAVIGATION_START_MARK,
      LOAD_EVENT_END_MARK
    ), // Same as fullRequest?
    pageStart: surfnperf.getMark(PAGE_START_MARK, 'DOM'),
    processing: surfnperf.getProcessingLoadTime(),
    serverTime: surfnperf.getServerTime(),

    ...(surfnperf.getMark(APP_RENDERED_MARK) && {
      appRendered: surfnperf.duration(NAVIGATION_START_MARK, APP_RENDERED_MARK),
    }),

    ...(window &&
      window.SURF_N_PERF &&
      window.SURF_N_PERF.visibility && {
        initialVis: window.SURF_N_PERF.visibility.initialState,
        hiddenTime: surfnperf.getHiddenTime(),
      }),
  };

  if (surfnperf.getMark(APP_READY_MARK)) {
    perfData.loading = surfnperf.duration(LOAD_EVENT_END_MARK, APP_READY_MARK);
    perfData.appReady = surfnperf.duration(
      NAVIGATION_START_MARK,
      APP_READY_MARK
    );
    perfData.status = 'success';
  } else {
    // Failure Data
    perfData.loading = surfnperf.duration(
      LOAD_EVENT_END_MARK,
      APP_FAILURE_MARK
    );
    perfData.appFailure = surfnperf.duration(
      NAVIGATION_START_MARK,
      APP_FAILURE_MARK
    );
    perfData.status = 'failure';
    perfData.error = surfnperf.getCustom(ERROR_MARK);
  }

  perfHelpers.init(surfnperf, resourceTiming);
  perfData.connection = perfHelpers.getConnectionData(window);

  return perfData;
}

/**
 * Calculates secondary app loading metrics based on marks already made using
 * {@link https://github.com/Comcast/Surf-N-Perf|Surf-N-Perf}. It expects SnP to already have
 * made the expected markings including generic marks setup in `setup.surfnperf.js` as well
 * as app specific marks like APP_FULLY_LOADED made in `epics/performance-marks`.
 *
 * TODO: Collect data call metrics.
 *
 * @param {Object} dependencies Injected dependencies
 * @param {Object} dependencies.surfnperf `surfnperf` module
 * @return {Object} secondary load metrics for logging
 */
export function gatherSecondaryPerfData({ surfnperf }: { surfnperf: any }) {
  let perfData: SecondaryPerfData = {
    appFullyLoaded: surfnperf.duration(
      NAVIGATION_START_MARK,
      APP_FULLY_LOADED_MARK
    ),
    deviceDetailsByFingerPrintLoaded: surfnperf.duration(
      PERF_MARKS.DEVICE_DETAILS_BY_FINGERPRINT_FETCH_STARTED_MARK,
      PERF_MARKS.DEVICE_DETAILS_BY_FINGERPRINT_FETCH_COMPLETED_MARK
    ),
    deviceFingerprintLoaded: surfnperf.duration(
      PERF_MARKS.DEVICE_FINGERPRINT_FETCH_STARTED_MARK,
      PERF_MARKS.DEVICE_FINGERPRINT_FETCH_COMPLETED_MARK
    ),
    deviceFingerprintPosted: surfnperf.duration(
      PERF_MARKS.DEVICE_FINGERPRINT_POST_STARTED_MARK,
      PERF_MARKS.DEVICE_FINGERPRINT_POST_COMPLETED_MARK
    ),
    fullRequest: surfnperf.getFullRequestLoadTime(),
    navigationStart: surfnperf.getTimingMark(NAVIGATION_START_MARK),
    networkLatency: surfnperf.getNetworkLatency(),
    sessionIdLoaded: surfnperf.duration(
      PERF_MARKS.SESSION_ID_FETCH_STARTED_MARK,
      PERF_MARKS.SESSION_ID_FETCH_COMPLETED_MARK
    ),
    testPlansLoaded: surfnperf.duration(
      PERF_MARKS.TEST_PLANS_FETCH_STARTED_MARK,
      PERF_MARKS.TEST_PLANS_FETCH_COMPLETED_MARK
    ),

    ...(surfnperf.getMark(APP_READY_MARK) && {
      appReady: surfnperf.duration(NAVIGATION_START_MARK, APP_READY_MARK),
      secondaryLoading: surfnperf.duration(
        APP_READY_MARK,
        APP_FULLY_LOADED_MARK
      ),
    }),

    ...(surfnperf.getMark(APP_RENDERED_MARK) && {
      appRendered: surfnperf.duration(NAVIGATION_START_MARK, APP_RENDERED_MARK),
    }),

    ...(surfnperf.getMark(LATENCYS_FETCH_COMPLETED_MARK) && {
      latencysLoaded: surfnperf.duration(
        PERF_MARKS.LATENCYS_FETCH_STARTED_MARK,
        PERF_MARKS.LATENCYS_FETCH_COMPLETED_MARK
      ),
    }),
  };

  return perfData;
}

export default {
  gatherPerfData,
  gatherSecondaryPerfData,
};
