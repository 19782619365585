// Third-party Libraries
import React from 'react';
import { connect } from 'react-redux';
// Our Code
import * as LOG_EVENTS from '../api/constants/log-events';
import ErrorPageContainer from './Error';
import { GENERAL } from '../api/constants/error-types';
import { serializeError } from '../api/helpers/error';
import { setErrorType } from '../api/actions/ui';
import { trackMetrics } from '../api/actions/logger';

interface State {
  isError: boolean;
}
interface DispatchProps {
  setErrorType: Dispatch<typeof setErrorType>;
  trackMetrics: Dispatch<typeof trackMetrics>;
}
export class ErrorBoundary extends React.Component<DispatchProps, State> {
  constructor(props: DispatchProps) {
    super(props);
    this.state = { isError: false };
  }

  static getDerivedStateFromError() {
    // Updates state so the next render will show the fallback UI.
    return { isError: true };
  }

  componentDidCatch(error: Error, info: { componentStack: any }) {
    /**
     * When the error boundary is reached there is not an exact error definition
     * for "what went wrong" so it's needed to reset the error type back to the
     * default in state. This ensures the user will receive the correct error
     * message in the UI.
     */
    this.props.setErrorType(GENERAL);
    this.props.trackMetrics(LOG_EVENTS.UI_RENDER_ERROR, {
      error: serializeError(error),
      componentStack: info.componentStack,
    });
  }

  render() {
    if (this.state.isError) {
      return <ErrorPageContainer />;
    }
    return this.props.children;
  }
}
export default connect(
  null,
  { setErrorType, trackMetrics }
)(ErrorBoundary);
