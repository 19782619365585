import React from 'react';
import DeviceVsPlanSpeedContent from '../../components/DeviceVsPlanSpeed/DeviceVsPlanSpeed';
import { DEVICE_VS_PLAN } from '../../api/constants/bids';
import { requestRoute as requestRouteAction } from '../../api/actions/navigation';
import { requestTest as requestTestAction } from '../../api/actions/request-test';

interface Props {
  deviceBestName: string;
  downloadSpeedReady: boolean;
  downloadSpeedResult: string;
  planDownloadSpeed: string;
  requestTest: Dispatch<typeof requestTestAction>;
  requestRoute: Dispatch<typeof requestRouteAction>;
}

const DeviceVsPlanSpeedPage = ({
  planDownloadSpeed,
  downloadSpeedReady,
  downloadSpeedResult,
  deviceBestName,
  requestTest,
  requestRoute,
}: Props) => {
  return (
    <div className="container md:px-4" data-bid={DEVICE_VS_PLAN}>
      <DeviceVsPlanSpeedContent
        planDownloadSpeed={planDownloadSpeed}
        downloadSpeedReady={downloadSpeedReady}
        downloadSpeedResult={downloadSpeedResult}
        deviceBestName={deviceBestName}
        requestTest={requestTest}
        requestRoute={requestRoute}
      />
    </div>
  );
};

export default DeviceVsPlanSpeedPage;
