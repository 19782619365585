import { smartFetch } from './data-helpers';

export interface LatencysResponse {
  ipAddress: string;
  message: string;
}

export function fetchLatencys(url: string) {
  return smartFetch<LatencysResponse>(url);
}

export default {
  fetchLatencys,
};
