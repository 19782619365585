// Third-party Libraries
import { createSelector } from 'reselect';
import get from 'lodash/get';
import * as STATUSES from '../../constants/statuses';
import { RootState } from '../../store-types';
import * as VEHICLES from '../../constants/vehicles';

/**
 * Rounds number to a single decimal point. In order to ensure the number
 * is displayed with a single decimal point, the number is converted to
 * a String. If rounding would cause the number to be a whole number
 * (for example, 12.1234 => 12), the number is padded with ".0" at the
 * end.
 * Example: 57.052121, output: "57.1"
 * Example: 57, output: "57.0"
 * @param {Number} number
 * @returns {String}
 */
const roundToSingleDecimalPoint = (number: number) => {
  const roundedNum = Math.round(number * 10) / 10;
  const numAsString = String(roundedNum);

  return numAsString.indexOf('.') === -1 ? numAsString + '.0' : numAsString;
};

const translateSpeedToVehicle = (speed: number) => {
  if (speed <= 5) {
    return VEHICLES.BALLOON;
  } else if (speed > 5 && speed <= 25) {
    return VEHICLES.PLANE;
  } else if (speed > 25 && speed <= 50) {
    return VEHICLES.JET;
  } else if (speed > 50 && speed <= 400) {
    return VEHICLES.ROCKET;
  }
  return VEHICLES.WARP;
};

export const getCurrentDownloadSpeed = createSelector(
  (state: RootState) => get(state, 'test.download.current', 0),
  currentDownloadSpeed => roundToSingleDecimalPoint(currentDownloadSpeed)
);

export const hasResultsDownload = createSelector(
  (state: RootState) => state.test.statuses.downloadTest.status,
  resultsDownloadStatus => resultsDownloadStatus === STATUSES.READY
);

export const getResultsDownloadSpeed = createSelector(
  (state: RootState) =>
    get(state, 'test.download.results.download_speed_magnitude', 0),
  resultsDownloadSpeed => roundToSingleDecimalPoint(resultsDownloadSpeed)
);

export const getResultsDownloadVehicle = createSelector(
  (state: RootState) =>
    get(state, 'test.download.results.download_speed_magnitude', 0),
  resultsDownloadSpeed => translateSpeedToVehicle(resultsDownloadSpeed)
);

export const getCurrentUploadSpeed = createSelector(
  (state: RootState) => get(state, 'test.upload.current', 0),
  currentUploadSpeed => roundToSingleDecimalPoint(currentUploadSpeed)
);

export const getResultsUploadSpeed = createSelector(
  (state: RootState) => get(state, 'test.upload.mean', 0),
  resultsUploadSpeed => roundToSingleDecimalPoint(resultsUploadSpeed)
);

export const getUploadSpeed = createSelector(
  getCurrentUploadSpeed,
  getResultsUploadSpeed,
  (state: RootState) => state.test.statuses.uploadTest.status,
  (current, results, status) => {
    switch (status) {
      case STATUSES.BUSY:
        return {
          value: `${current} Mbps`,
          displaySpinner: true,
        };
      case STATUSES.READY:
        return {
          value: `${results} Mbps`,
          displaySpinner: false,
        };
      case STATUSES.ERROR:
        return {
          value: 'Error',
          displaySpinner: false,
        };
      default:
      case STATUSES.EMPTY:
        return {
          value: '',
          displaySpinner: true,
        };
    }
  }
);

export const getLatencySpeed = createSelector(
  (state: RootState) => state.test.latency.min,
  (state: RootState) => state.test.statuses.latencyTest.status,
  (results, status) => {
    switch (status) {
      case STATUSES.READY:
        return {
          value: `${results} ms`,
          displaySpinner: false,
        };
      case STATUSES.ERROR:
        return {
          value: 'Error',
          displaySpinner: false,
        };
      default:
      case STATUSES.EMPTY:
      case STATUSES.BUSY:
        return {
          value: '',
          displaySpinner: true,
        };
    }
  }
);
