import React from 'react';
import uuid from 'uuid/v4';
import DetailedTipsCarousel from '../../components/DetailedTipsCarousel/DetailedTipsCarousel';
import DetailedTipsCard from '../../components/DetailedTipsCard/DetailedTipsCard';
import NoDragImage from '../../components/NoDragImage/NoDragImage';
import tryAnotherDeviceImage from '../../components/DetailedTipsCarousel/images/try_another_device.svg';
import restartServiceImage from '../../components/DetailedTipsCarousel/images/restart_service_or_app.svg';
import checkProvider from '../../components/DetailedTipsCarousel/images/check_provider.svg';
import { NON_SPEED_PROBLEMS } from '../../api/constants/bids';
import { useLocalization } from '../../hooks/localization';

const NonSpeedProblems = () => {
  const t = useLocalization();
  const nonSpeedTips = [
    {
      copy: t('nonSpeedProblems_firstSection_body'),
      heading: t('nonSpeedProblems_firstSection_header'),
      id: uuid(),
      image: <NoDragImage className="w-full" src={checkProvider} />,
    },
    {
      copy: t('nonSpeedProblems_secondSection_body'),
      heading: t('nonSpeedProblems_secondSection_header'),
      id: uuid(),
      image: <NoDragImage className="w-full" src={restartServiceImage} />,
    },
    {
      copy: t('nonSpeedProblems_thirdSection_body'),
      heading: t('nonSpeedProblems_thirdSection_header'),
      id: uuid(),
      image: <NoDragImage className="w-full" src={tryAnotherDeviceImage} />,
    },
  ];

  return (
    <div data-bid={NON_SPEED_PROBLEMS}>
      <DetailedTipsCarousel className="container px-6 mt-6 md:px-4">
        {nonSpeedTips.map(tip => (
          <DetailedTipsCard key={tip.heading} {...tip} />
        ))}
      </DetailedTipsCarousel>
    </div>
  );
};

export default NonSpeedProblems;
