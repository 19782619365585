import React, { HTMLAttributes, InputHTMLAttributes } from 'react';
import { InputProps } from 'react-autosuggest';

import { DEVICE_SEARCH_STATE_INITIAL } from '../../api/constants/states';
import { DeviceSuggestion } from '../DeviceSearch/DeviceSearch';
import { ReactComponent as DeviceSearchClearInputIcon } from './images/icon-delete.svg';
import { ReactComponent as DeviceSearchSearchIcon } from './images/icon-magnifying-glass.svg';
import { setDeviceSearchInputValue as setDeviceSearchInputValueAction } from '../../api/actions/ui';
import {
  DEVICE_SEARCH_INPUT_BUTTON,
  DEVICE_SEARCH_TEXT_INPUT,
} from '../../api/constants/bids';
import { useLocalization } from '../../hooks/localization';

interface Props extends HTMLAttributes<HTMLDivElement> {
  deviceSearchUIState?: string;
  deviceSuggestionsAreVisible: boolean;
  inputProps: InputProps<any>;
  setDeviceSearchInputValue: Dispatch<typeof setDeviceSearchInputValueAction>;
}

const ARIA = {
  AUTOCOMPLETE: 'aria-autocomplete',
  CONTROLS: 'aria-controls',
  DESCRIBED_BY: 'aria-describedby',
  EXPANDED: 'aria-expanded',
  LABEL: 'aria-label',
};
const ICON_SIZE = 24;

const DeviceSearchInput = ({
  deviceSearchUIState,
  deviceSuggestionsAreVisible = false,
  inputProps,
  setDeviceSearchInputValue,
}: Props) => {
  const iconStyles = {
    height: ICON_SIZE,
    width: ICON_SIZE,
  };
  const DeviceSearchInputIcon = () => {
    const t = useLocalization();

    return deviceSearchUIState === DEVICE_SEARCH_STATE_INITIAL ? (
      <DeviceSearchSearchIcon
        className="absolute right-0 mr-4 fill-current"
        style={{ ...iconStyles, top: '50%', transform: 'translateY(-50%)' }}
      />
    ) : (
      <button
        className="absolute right-0 mr-4"
        data-bid={DEVICE_SEARCH_INPUT_BUTTON}
        aria-label={t('accessibility_clear')}
        onClick={() => setDeviceSearchInputValue('')}
        style={{ top: '50%', transform: 'translateY(-50%)' }}
      >
        <DeviceSearchClearInputIcon
          className="fill-current"
          style={iconStyles}
        />
      </button>
    );
  };

  const inputElementProps: InputProps<DeviceSuggestion> & {
    'data-bid': string;
  } = {
    ...inputProps,
    [ARIA.AUTOCOMPLETE]: 'both',
    [ARIA.CONTROLS]: inputProps.id,
    [ARIA.DESCRIBED_BY]: `instructions-${inputProps.id}`,
    [ARIA.EXPANDED]: deviceSuggestionsAreVisible,
    [ARIA.LABEL]: inputProps.placeholder,
    autoComplete: 'off',
    'data-bid': DEVICE_SEARCH_TEXT_INPUT,
    role: 'combobox',
  };

  return (
    <div className="relative">
      <input
        {...(inputElementProps as InputHTMLAttributes<HTMLInputElement>)}
      />

      {DeviceSearchInputIcon()}
    </div>
  );
};

export default DeviceSearchInput;
