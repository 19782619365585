import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import DeviceIcon from '../DeviceIcon/DeviceIcon';
import { useLocalization } from '../../hooks/localization';

interface Props extends HTMLAttributes<HTMLDivElement> {
  type: string;
  name: string;
  categoryMaxSpeed: string;
  speed: string;
  unit?: string;
  percent: number;
  borderActive?: boolean;
}

const gutterStyle = {
  background: '#F4F6F6',
  borderRadius: '6px',
  height: '6px',
};

const borderActiveStyle = {
  borderRadius: '3px',
  border: 'solid 1px rgba(40, 195, 224, 0.18)',
};

const itemBarStyle = {
  backgroundImage:
    'linear-gradient(to left, #FD628E 0%, #8170D7 49%, #24C6E0 100%)',
  backgroundRepeat: 'no-repeat',
  borderRadius: '6px',
  height: '6px',
  display: 'block',
};

const percentStyle = function(percent: number) {
  return {
    width: percent + '%',
    ...itemBarStyle,
  };
};

const titleStyle = {
  textOverflow: 'ellipsis',
};

const SimilarDevice = ({
  type,
  name,
  categoryMaxSpeed,
  speed = '0',
  unit = 'Mbps',
  percent = 0,
  borderActive,
  className: parentClassNames,
  ...props
}: Props) => {
  const t = useLocalization();
  return (
    <div
      style={borderActive ? borderActiveStyle : {}}
      className={classnames(parentClassNames, 'flex flex-row p-4 md:w-1/2')}
      {...props}
    >
      <div className="items-stretch hidden sm:flex">
        <DeviceIcon
          type={type}
          className="self-center hidden m-1 sm:inline"
          style={{ width: 50, height: 50 }}
        />
      </div>
      <div className="flex-col w-full">
        <div className="flex justify-between pt-2">
          <p
            className="relative overflow-hidden leading-normal tracking-wide text-left text-body2 font-500"
            style={titleStyle}
          >
            {name}
          </p>
          <p className="text-right text-body2">
            <span className="mr-2">{speed}</span>
            {unit}
          </p>
        </div>
        <div
          style={gutterStyle}
          className="flex w-full mt-1"
          role="img"
          aria-label={t('knownDevice_speedCompare', {
            speed,
            unit,
            categoryMaxSpeed,
          })}
        >
          <div style={percentStyle(percent)} />
        </div>
      </div>
    </div>
  );
};

export default SimilarDevice;
