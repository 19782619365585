import { createReducer } from '@reduxjs/toolkit';

import * as STATUSES from '../constants/statuses';
import {
  fetchDeviceDetailsStart,
  fetchDeviceDetailsComplete,
} from '../actions/device-details';
import { DeviceDetailsResponse } from '../network-interfaces/device-details';

const { BUSY, EMPTY, ERROR, READY } = STATUSES;

export interface DeviceDetailsState {
  status: UnionOf<typeof STATUSES>;
  error: Error | null;
  data: DeviceDetailsResponse | {};
}

const initialState: DeviceDetailsState = {
  status: EMPTY,
  error: null,
  data: {},
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(fetchDeviceDetailsStart, state => ({
      ...state,
      status: BUSY,
    }))
    .addCase(fetchDeviceDetailsComplete, (state, action) => {
      if (action.error) {
        return {
          ...state,
          status: ERROR,
          error: action.payload as Error,
        };
      }
      return {
        ...state,
        status: READY,
        error: null,
        data: action.payload as DeviceDetailsResponse,
      };
    })
);

export default reducer;
