// Third-party Libraries
import { createSelector } from 'reselect';
// Our code
import { RootState } from '../../store-types';

export const getHostName = createSelector(
  (state: RootState) => state.advancedSettings.useAdvancedSettingsTestData,
  (state: RootState) => state.advancedSettings.selectedServerLocation,
  (state: RootState) => (state.test.plans ? state.test.plans.sitename : ''),
  (useAdvancedSettingsTestData, selectedServerLocation, sitename) => {
    if (useAdvancedSettingsTestData && selectedServerLocation) {
      return selectedServerLocation;
    } else {
      return sitename;
    }
  }
);
