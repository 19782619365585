// Third-party libraries
import { ofType, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { filter, map, mergeMap, pluck, withLatestFrom } from 'rxjs/operators';
// Our code
import { fetchData, getEndpoints } from './epic-helpers';
import {
  PLAN_INFO_FETCH_STARTED,
  SESSION_ID_FETCH_COMPLETED,
} from '../constants/action-types';
import { fetchPlanInfo } from '../network-interfaces/plan-info';
import {
  fetchPlanInfoComplete,
  fetchPlanInfoStart,
} from '../actions/plan-info';
import { fetchSessionIdComplete } from '../actions/session-id';
import { RootState, RootAction, EpicDependencies } from '../store-types';

export const schedulePlanInfoFetchEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = action$ => {
  return action$.pipe(
    ofType(SESSION_ID_FETCH_COMPLETED),
    filter(
      (action: ReturnType<typeof fetchSessionIdComplete>) => !action.error
    ),
    pluck('payload', 'id'),
    map(fetchPlanInfoStart)
  );
};

export const fetchPlanInfoEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(PLAN_INFO_FETCH_STARTED),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    mergeMap(([action, endpoints]) =>
      of(action).pipe(
        fetchData(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          fetchPlanInfo(endpoints!.PLAN_INFO_ENDPOINT),
          response => fetchPlanInfoComplete(null, response),
          error => fetchPlanInfoComplete(error, null),
          action => action.payload && action.payload.sessionId
        )
      )
    )
  );
};
