// Third-party libraries
import { ofType, Epic } from 'redux-observable';
import { mergeMapTo } from 'rxjs/operators';
// Our code
import { REQUEST_TEST } from '../constants/action-types';
import { testStart } from '../actions/test';
import { showTestingScreen } from '../actions/ui';
import { ROUTES } from '../constants/routes';
import { RootState, RootAction, EpicDependencies } from '../store-types';

export const requestTestEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(REQUEST_TEST),
    mergeMapTo([
      testStart(),
      showTestingScreen(),
      speedTestContext.requestRoute({
        route: ROUTES.HOME,
      }),
    ])
  );
};
