import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

/**
 * Speed namespace, to each namespace corresponds a {namespace}.json
 */
export const SPEED = 'speed';

/**
 * This custom hook replaces the original `useTranslation` i18next hook used inside
 * every components. This avoid repeated imports of nameSpace string(`SPEED`)
 * and `useTranslation` hook in every component involved.
 *
 * @returns the translation function from i18next, with namespace already setted
 */

export const useLocalization = (): TFunction => {
  const { t } = useTranslation(SPEED);
  return t;
};
