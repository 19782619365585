import { createAction } from '@reduxjs/toolkit';

import {
  LATENCYS_FETCH_COMPLETED,
  LATENCYS_FETCH_STARTED,
} from '../constants/action-types';
import { LatencysResponse } from '../network-interfaces/latencys';

export const fetchLatencysStart = createAction(
  LATENCYS_FETCH_STARTED,
  (endpoint: string) => ({
    payload: endpoint,
  })
);

export const fetchLatencysComplete = createAction(
  LATENCYS_FETCH_COMPLETED,
  (error: Error | null, response: LatencysResponse | null) => ({
    payload: error || response,
    error: Boolean(error),
  })
);
