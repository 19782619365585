/**
 * Calculates the percentage used for speed comparisons in similar devices
 * Note:
 * Mathematical definition of a percent:
 * A percent is a ratio whose second term is 100
 * The percentage bounds are defined as [0,100]
 *
 * @name calculatePercentage
 * @param {Number} num - numerator
 * @param {Number} denom - denominator
 * @returns {Number}  - returns integer within bounds [0,100]
 */
export const calculatePercentage = (num: number, denom: number) => {
  return !isNaN(num) && !isNaN(denom) && denom > 0 && num > 0
    ? (num / denom) * 100
    : 0;
};

export default {
  calculatePercentage,
};
