// Third-party libraries
import { ofType, Epic } from 'redux-observable';
import { first, ignoreElements, tap, pluck } from 'rxjs/operators';
// Our Code
import {
  SET_INITIAL_APP_CONFIG,
  TRACK_METRICS,
} from '../constants/action-types';
import { logger } from '../../modules';
import { RootAction } from '../store-types';

/**
 * The `appConfig` object from the payload of `SET_INITIAL_APP_CONFIG` contains all
 * information required to start logging to Melee.
 * We can initialize the logger and mark this Observable complete (see `first`).
 */
export const initialize: Epic<RootAction, RootAction> = action$ => {
  return action$.pipe(
    ofType(SET_INITIAL_APP_CONFIG),
    first(),
    pluck('payload', 'appConfig'),
    tap(({ environment, sessionId, MELEE_CONFIG }) => {
      logger.init(
        {
          env: environment,
          sessionId,
        },
        MELEE_CONFIG
      );
    }),
    ignoreElements()
  );
};

export const trackMetricsEpic: Epic<RootAction, RootAction> = action$ => {
  return action$.pipe(
    ofType(TRACK_METRICS),
    pluck('payload'),
    tap(({ eventName, params, eventContext }) => {
      if (params && params.error && params.error.constructor == Object) {
        params.errorMsg = params.error.message || 'n/a';
      } else if (
        params &&
        (typeof params.error == 'string' || params.error instanceof String)
      ) {
        params.errorMsg = params.error || 'n/a';
      }
      return logger.log(eventName, params, eventContext);
    }),
    ignoreElements()
  );
};
