import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Carousel from '../Carousel/Carousel';
import DetailedTipsCard from '../DetailedTipsCard/DetailedTipsCard';

interface Props {
  children: JSX.Element[];
  className?: string;
}

/**
 * A carousel specialized to present one [`DetailedTipsCard`](/#/Components?id=detailedtipscard)
 * at a time, making it easier to take in larger amounts of information.  `DetailedTipsCarousel`
 * is usually provided one or more `DetailedTipsCard`s as its children.
 *
 * Example instances are
 * found in the [Improve Speed page](#/Pages?id=improvespeed) and the
 * [Non-Speed Tips page](#/Pages?id=nonspeedproblems).
 */
const DetailedTipsCarousel = ({
  children,
  className: parentClassNames,
}: Props) => (
  <Carousel
    className={parentClassNames}
    itemContainerClassName={'rounded-small'}
    shadow
  >
    {React.Children.map(children, child => {
      const { className: childClassNames, ...restChildProps } = child.props;

      return (
        <DetailedTipsCard
          key={restChildProps.heading}
          className={classnames(
            childClassNames,
            'self-start min-h-400 md:min-h-500 lg:min-h-250'
          )}
          aria-label={restChildProps.heading}
          {...restChildProps}
        />
      );
    })}
  </Carousel>
);

DetailedTipsCarousel.propTypes = {
  /**
   *  Expected to be `DetailedTipsCard` element(s)
   */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  className: PropTypes.string,
};

export default DetailedTipsCarousel;
