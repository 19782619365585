import React, { DetailsHTMLAttributes, SyntheticEvent } from 'react';
import classnames from 'classnames';
import resultsAccordionStyles from './ResultsAccordion.module.css';
import Accordion from '../Accordion/Accordion';
import DLRow from '../DLRow/DLRow';
import Spinner from '../Spinner/Spinner';
// Polyfill to support the details element in IE11 and Edge
import 'details-element-polyfill';
import { requestLatencyTest as requestLatencyTestAction } from '../../api/actions/latency-test';
import { useLocalization } from '../../hooks/localization';

interface ResultsAccordionProps
  extends DetailsHTMLAttributes<HTMLDetailsElement> {
  downloadSpeed: string;
  hostName: string;
  latencySpeed: {
    value: string;
    displaySpinner: boolean;
  };
  protocolVersion: string;
  uploadSpeed: {
    value: string;
    displaySpinner: boolean;
  };
  requestLatencyTest: Dispatch<typeof requestLatencyTestAction>;
}

/**
 * Uses the `value` and `displaySpinner` properties on the `props`
 * passed in to determines whether a `DLRow`'s internal `<dd>` should display
 * the `value`, the `Spinner`, or both.
 */
const DescriptionDetail = (props: {
  value: string;
  displaySpinner: boolean;
}) => {
  if (props.value && props.displaySpinner) {
    return (
      <div className="flex justify-end">
        <span className="pr-4 text-neutral2-base">{props.value}</span>
        <Spinner className="w-25 h-25" />
      </div>
    );
  }
  if (!props.value && props.displaySpinner) {
    return <Spinner className="w-25 h-25" />;
  }
  return props.value;
};

const ResultsAccordion = (props: ResultsAccordionProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [hasLatencyTestExecuted, setHasLatencyTestExecuted] = React.useState(
    false
  );
  const handleToggle = (event: SyntheticEvent) => {
    event.preventDefault();
    // isOpen will be false until the accordion is actually open
    if (!isOpen && !hasLatencyTestExecuted) {
      props.requestLatencyTest();
      setHasLatencyTestExecuted(true);
    }
    setIsOpen(!isOpen);
  };

  let {
    downloadSpeed,
    hostName,
    latencySpeed = {
      value: '',
      displaySpinner: true,
    },
    protocolVersion,
    uploadSpeed = {
      value: '',
      displaySpinner: true,
    },
  } = props;
  const t = useLocalization();
  return (
    <Accordion
      className="border-t border-b select-none border-grey-5"
      handleClick={handleToggle}
      open={isOpen}
      arrowText={
        <p className="pr-4 font-500 text-neutral2-base text-body1">
          {isOpen ? t('result_showLess') : t('result_showMore')}
        </p>
      }
      outerContent={
        <dl className="flex-1">
          <dt className="text-body1">{t('common_downloadSpeed')}</dt>
          <dd className="font-900 text-display2 md:w-175">
            {downloadSpeed}
            <span className="pl-2 text-body3 text-neutral2-base">Mbps</span>
          </dd>
        </dl>
      }
      innerContent={
        <div className="bg-grey-2">
          <dl
            className={classnames(
              'container py-6 px-4 font-500 md:font-300 md:py-8 md:px-6 lg:px-8 text-body1',
              resultsAccordionStyles.description
            )}
          >
            <DLRow
              term={t('result_uploadSpeed')}
              description={DescriptionDetail(uploadSpeed)}
            />
            <DLRow
              term={t('common_latency')}
              description={DescriptionDetail(latencySpeed)}
            />
            <DLRow term={t('common_protocol')} description={protocolVersion} />
            <DLRow term={t('common_host')} description={hostName} />
          </dl>
        </div>
      }
    />
  );
};

export default ResultsAccordion;
