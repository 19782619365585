// Third-party libraries
import { ofType, Epic } from 'redux-observable';
import { Observable, Observer } from 'rxjs';
import { map, mergeMap, withLatestFrom, takeUntil } from 'rxjs/operators';
import get from 'lodash/get';
import 'speed-testjs/public/lib/uploadHttpConcurrentProgress';
// Our code
import { UPLOAD_TEST_STARTED } from '../constants/action-types';
import {
  uploadTestComplete,
  updateTestUploadCurrent,
  UploadTestResults,
} from '../actions/test';
import { getEndpoints } from './epic-helpers';
import { createTestUrls } from './test-helpers';
import { RootState, RootAction, EpicDependencies } from '../store-types';
import { TestState } from '../reducers/test';

declare global {
  interface Window {
    // From speed-testjs
    uploadHttpConcurrentProgress: any;
  }
}

interface UploadTestParameters {
  test: TestState;
  uploadHttpConcurrentProgress: any;
  uploadUrls: string[];
  isMicrosoftBrowser: boolean;
}

function createUploadTestStream({
  test,
  uploadHttpConcurrentProgress,
  uploadUrls,
  isMicrosoftBrowser,
}: UploadTestParameters): Observable<RootAction> {
  return Observable.create((observer: Observer<RootAction>) => {
    let uploadTest = new uploadHttpConcurrentProgress(
      uploadUrls,
      'POST',
      get(test, 'config.UPLOAD_CURRENT_TESTS'),
      get(test, 'config.UPLOAD_TIMEOUT'),
      get(test, 'config.UPLOAD_TIMEOUT'),
      get(test, 'config.UPLOAD_MOVINGAVERAGE'),
      // on complete callback
      (result: UploadTestResults) => {
        observer.next(uploadTestComplete(null, result));
        observer.complete();
      },
      // on progress callback
      (result: number) => {
        observer.next(updateTestUploadCurrent(result));
      },
      // on error callback
      (result: any) => {
        observer.next(uploadTestComplete(result, null));
        observer.complete();
      },
      get(test, 'config.UPLOAD_TEST_SIZE'),
      get(test, 'plans.maxUploadSize'),
      get(test, 'config.UPLOAD_MONITOR_INTERVAL'),
      isMicrosoftBrowser
    );
    uploadTest.initiateTest();
  });
}

function isMicrosoftBrowser(browser: string) {
  return browser === 'Edge' || browser === 'IE';
}

export const uploadTestEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext, window }) => {
  return action$.pipe(
    ofType(UPLOAD_TEST_STARTED),
    withLatestFrom(state$, (action, state) => state),
    map(speedTestContext.getState),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    map(
      ([{ advancedSettings, deviceFingerprint, network, test }, endpoints]) => {
        let uploadUrls = createTestUrls(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          endpoints!.UPLOAD_TEST_URL,
          advancedSettings,
          test.plans,
          get(test, 'config.MAX_CONNECTIONS_PER_PORT'),
          network.clientHasIPv6
        );

        return {
          test,
          uploadHttpConcurrentProgress: window.uploadHttpConcurrentProgress,
          uploadUrls,
          isMicrosoftBrowser: isMicrosoftBrowser(
            get(deviceFingerprint, 'data.advertised_browser')
          ),
        };
      }
    ),
    mergeMap((testOptions: UploadTestParameters) =>
      createUploadTestStream(testOptions).pipe(
        takeUntil(action$.pipe(ofType(speedTestContext.CHANGE_ROUTE)))
      )
    )
  );
};
