// Third-party libraries
// TODO: Switch to 'lodash-es' - CIMSI-10109
import { ofType, Epic } from 'redux-observable';
import {
  delay,
  map,
  mergeMap,
  mergeMapTo,
  pluck,
  withLatestFrom,
  filter,
  mapTo,
  partition,
} from 'rxjs/operators';
import { of, merge } from 'rxjs';
// Our code
// Constants
import {
  DOWNLOAD_TEST_COMPLETED,
  TEST_CONFIG_POST_STARTED,
  TEST_PLANS_FETCH_STARTED,
  DOWNLOAD_TEST_RESULTS_POST_COMPLETED,
  DOWNLOAD_TEST_RESULTS_POST_STARTED,
  TEST_STARTED,
  TEST_COMPLETED,
  LATENCY_TEST_COMPLETED,
  LATENCY_AND_UPLOAD_TEST_RESULTS_POST_STARTED,
  UPLOAD_TEST_COMPLETED,
} from '../constants/action-types';
import { ACTION_TYPES as xfiCoreActionTypes } from 'xfi-client-core/src/constants';
import { TAB_BACKGROUNDED } from '../constants/error-types';
import { ROUTES } from '../constants/routes';
// Actions
import {
  downloadTestComplete,
  fetchTestPlansComplete,
  postLatencyAndUploadTestResultsComplete,
  postLatencyAndUploadTestResultsStart,
  postTestConfigComplete,
  postTestConfigStart,
  postDownloadTestResultsComplete,
  postDownloadTestResultsStart,
  testComplete,
  uploadTestComplete,
  uploadTestStart,
} from '../actions/test';
import { hideTestingScreen, setErrorType } from '../actions/ui';
// Everything else
import { fetchData, getEndpoints } from './epic-helpers';
import {
  isOnHomeAndTestingRoute,
  isTestInProgress,
  isTestingScreenDisplayed,
} from '../helpers/test-state';
import {
  getSessionId,
  getShowTestingScreen,
  getTestId,
} from '../helpers/state-getters';
import {
  fetchTestPlans,
  postTestConfig,
  postDownloadTestResults,
  TestResults,
} from '../network-interfaces/test';
import { createTestResultsPostPayload } from './test-helpers';
import { RootState, RootAction, EpicDependencies } from '../store-types';

const DELAY_BEFORE_REDIRECT = 2000; //ms

export const fetchTestPlansEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(TEST_PLANS_FETCH_STARTED),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    mergeMap(([action, endpoints]) =>
      of(action).pipe(
        fetchData(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          fetchTestPlans(endpoints!.TEST_PLANS_ENDPOINT),
          response => fetchTestPlansComplete(null, response),
          error => fetchTestPlansComplete(error, null)
        )
      )
    )
  );
};

export const hideTestingScreenEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(speedTestContext.CHANGE_ROUTE),
    withLatestFrom(state$),
    filter(([, state]) => {
      const speedTestState = speedTestContext.getState(state);

      return (
        getShowTestingScreen(speedTestState) &&
        !isOnHomeAndTestingRoute(
          speedTestContext.getRoute(state),
          speedTestContext.rootRoute
        )
      );
    }),
    mapTo(hideTestingScreen())
  );
};

export const postTestConfigEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(TEST_CONFIG_POST_STARTED),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    mergeMap(([action, endpoints]) =>
      of(action).pipe(
        fetchData(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          postTestConfig(endpoints!.TEST_CONFIG_POST_ENDPOINT),
          response => postTestConfigComplete(null, response),
          error => postTestConfigComplete(error, null),
          action => action.payload
        )
      )
    )
  );
};

export const postDownloadTestResultsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(DOWNLOAD_TEST_RESULTS_POST_STARTED),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    mergeMap(([action, endpoints]) =>
      of(action).pipe(
        fetchData(
          postDownloadTestResults(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            endpoints!.DOWNLOAD_TEST_RESULTS_POST_ENDPOINT
          ),
          response => postDownloadTestResultsComplete(null, response),
          error => postDownloadTestResultsComplete(error, null),
          action => action.payload
        )
      )
    )
  );
};

export const postLatencyAndUploadTestResultsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(LATENCY_AND_UPLOAD_TEST_RESULTS_POST_STARTED),
    withLatestFrom(state$.pipe(getEndpoints(speedTestContext.getAppConfig))),
    mergeMap(([action, endpoints]) =>
      of(action).pipe(
        fetchData(
          postDownloadTestResults(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            endpoints!.LATENCY_AND_UPLOAD_TEST_RESULTS_POST_ENDPOINT
          ),
          () => postLatencyAndUploadTestResultsComplete(),
          error => postLatencyAndUploadTestResultsComplete(error),
          action => action.payload
        )
      )
    )
  );
};

export const schedulePostTestConfigEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(TEST_STARTED),
    withLatestFrom(state$, (action, state) => state),
    map(speedTestContext.getState),
    pluck('test'),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    map(({ sessionId, config }) => postTestConfigStart(sessionId!, config))
  );
};

export const schedulePostDownloadTestResultsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(DOWNLOAD_TEST_COMPLETED),
    filter((action: ReturnType<typeof downloadTestComplete>) => !action.error),
    withLatestFrom(state$, (action, state) => state),
    map(speedTestContext.getState),
    map(speedTestState => {
      const { deviceFingerprint, network, test } = speedTestState;
      const testResultsPayload = createTestResultsPostPayload(
        deviceFingerprint.data,
        network,
        test
      );

      return {
        sessionId: getSessionId(speedTestState),
        testId: getTestId(speedTestState),
        testResults: testResultsPayload,
      };
    }),
    map(({ sessionId, testId, testResults }) =>
      postDownloadTestResultsStart(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        sessionId!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        testId!,
        testResults as TestResults // TODO: fix the function instead of casting
      )
    )
  );
};

export const scheduleLatencyAndUploadTestResultsPostEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(UPLOAD_TEST_COMPLETED),
    filter((action: ReturnType<typeof uploadTestComplete>) => !action.error),
    withLatestFrom(state$, (action, state) => state),
    map(speedTestContext.getState),
    map((speedTestState: RootState) => {
      const { deviceFingerprint, network, test } = speedTestState;
      const testResultsPayload = createTestResultsPostPayload(
        deviceFingerprint.data,
        network,
        test
      );

      return {
        sessionId: getSessionId(speedTestState),
        testId: getTestId(speedTestState),
        testResults: testResultsPayload,
      };
    }),
    map(({ sessionId, testId, testResults }) =>
      postLatencyAndUploadTestResultsStart(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        sessionId!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        testId!,
        testResults as TestResults // TODO: fix the function instead of casting
      )
    )
  );
};

/**
 * TEST_COMPLETED can be dispatched when DOWNLOAD_TEST_COMPLETED
 * is dispatched with an error OR if DOWNLOAD_TEST_RESULTS_POST_COMPLETED
 * with or without an error.
 */
export const scheduleTestCompleteEpic: Epic<
  RootAction,
  RootAction
> = action$ => {
  const failureScenario$ = action$.pipe(
    ofType(DOWNLOAD_TEST_COMPLETED, DOWNLOAD_TEST_RESULTS_POST_COMPLETED),
    filter(
      (
        action:
          | ReturnType<typeof downloadTestComplete>
          | ReturnType<typeof postDownloadTestResultsComplete>
      ) => action.error
    ),
    map(testComplete)
  );

  const postDownloadTestResultsComplete$ = action$.pipe(
    ofType(DOWNLOAD_TEST_RESULTS_POST_COMPLETED),
    filter(
      (action: ReturnType<typeof postDownloadTestResultsComplete>) =>
        !action.error
    ),
    mapTo(testComplete())
  );

  return merge(failureScenario$, postDownloadTestResultsComplete$);
};

// Upload Test starts when latency test is complete
export const scheduleUploadTestEpic: Epic<RootAction, RootAction> = action$ => {
  return action$.pipe(
    ofType(LATENCY_TEST_COMPLETED),
    mapTo(uploadTestStart())
  );
};

//TODO: CIMSI-10303 - We should add a step here to cancel a running test
export const testInterruptedEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(xfiCoreActionTypes.SET_APP_BACKGROUNDED),
    withLatestFrom(state$),
    filter(([, state]) => {
      const speedTestState = speedTestContext.getState(state);

      return (
        isTestInProgress(speedTestState) &&
        isTestingScreenDisplayed(
          speedTestState,
          speedTestContext.getRoute(state),
          speedTestContext.rootRoute
        )
      );
    }),
    mergeMapTo([
      setErrorType(TAB_BACKGROUNDED),
      speedTestContext.requestRoute({
        route: ROUTES.ERROR,
      }),
    ])
  );
};

export const testResultsRedirect: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  const stateWhenTestingScreenDisplayed$ = action$.pipe(
    ofType(TEST_COMPLETED),
    withLatestFrom(state$),
    filter(([, state]) => {
      const speedTestState = speedTestContext.getState(state);

      return isTestingScreenDisplayed(
        speedTestState,
        speedTestContext.getRoute(state),
        speedTestContext.rootRoute
      );
    })
  );
  const [failureScenario$, successScenario$] = partition(
    ([action]) => action.error
  )(stateWhenTestingScreenDisplayed$);

  return merge(
    failureScenario$.pipe(
      mapTo(
        speedTestContext.requestRoute({
          route: ROUTES.ERROR,
        })
      )
    ),
    successScenario$.pipe(
      delay(DELAY_BEFORE_REDIRECT),
      mapTo(
        speedTestContext.requestRoute({
          route: ROUTES.RESULTS,
        })
      )
    )
  );
};
