import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

interface Props {
  className?: string;
  copy: ReactNode;
  heading: string;
  image: ReactNode;
}

/**
 * A card designed to provide more detailed information. `DetailedTipsCard`s are designed to be
 * paired as children to [`DetailedTipsCarousel`](#/Components?id=detailedtipscarousel).
 *
 * Its `copy` prop can be either a `String` or a `React` element. Using a
 * [`NoDragImage`](#/Components?id=nodragimage) as the `image` prop will keep `<img>`s from
 * dragging when swiping the card.
 */
const DetailedTipsCard = ({
  className: parentClassNames,
  copy,
  heading,
  image,
}: Props) => {
  // Switch to a `div` when wrapping a `React` element.  A `p` should only wrap 'Phrasing content':
  // https://html.spec.whatwg.org/multipage/grouping-content.html#the-p-element
  const CopyWrapper = typeof copy === 'string' ? 'p' : 'div';

  return (
    <div className={classnames(parentClassNames, 'bg-grey-0 p-4 lg:flex')}>
      <aside className="flex items-start justify-center lg:items-center lg:inline-flex lg:w-3/5 aspect-ratio aspect-ratio--1:2-15">
        {image}
      </aside>

      <section className="pt-4 tracking-wide align-top lg:inline-block lg:w-2/5 lg:h-full md:pt-2 lg:pt-0 lg:pl-4">
        <h2 className="mb-4 leading-tight md:mb-2 text-headline1 font-300">
          {heading}
        </h2>
        <CopyWrapper className="lg:mt-0 font-300 text-body1 text-neutral2-base">
          {copy}
        </CopyWrapper>
      </section>
    </div>
  );
};

DetailedTipsCard.propTypes = {
  className: PropTypes.string,
  /** A `string` or a `React` element to be wrapped automatically in a `<p>` or a `<div>` for you */
  copy: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  heading: PropTypes.string,
  /**
   * Using [`NoDragImage`](#/Components?id=nodragimage) will keep `<img>`s from dragging  when
   * swiped
   */
  image: PropTypes.element,
};

export default DetailedTipsCard;
