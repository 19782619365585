export const ADVANCED_SETTINGS_MODAL = 'ADVANCED_SETTINGS_MODAL';
export const CANCEL_MODAL_BUTTON = 'CANCEL_BUTTON';
export const CLOSE_MODAL_BUTTON = 'CLOSE_BUTTON';
export const COMPARE_BY_CATEGORY_CONTENT = 'COMPARE_BY_CATEGORY_CONTENT';
export const DEVICE_SEARCH_ACTIVE_STATE = 'DEVICE_SEARCH_ACTIVE_STATE';
export const DEVICE_SEARCH_INPUT_BUTTON = 'DEVICE_SEARCH_INPUT_BUTTON';
export const DEVICE_SEARCH_INPUT_WRAPPER = 'DEVICE_SEARCH_INPUT_WRAPPER';
export const DEVICE_SEARCH_SUGGESTION = 'DEVICE_SEARCH_SUGGESTION';
export const DEVICE_SEARCH_TEXT_INPUT = 'DEVICE_SEARCH_TEXT_INPUT';
export const DEVICE_VS_PLAN = 'DEVICE_VS_PLAN';
export const GATEWAY_SPEED_TEST_RESULTS_PAGE_BUTTON =
  'GATEWAY_SPEED_TEST_RESULTS_PAGE_BUTTON';
export const IMPROVE_SPEED = 'IMPROVE_SPEED';
export const KNOWN_DEVICE_CONTENT = 'KNOWN_DEVICE_CONTENT';
export const LOOKUP_DEVICES_BUTTON = 'LOOKUP_DEVICES_BUTTON';
export const NON_SPEED_PROBLEMS = 'NON_SPEED_PROBLEMS';
export const RESULTS = 'RESULTS';
export const RESULTS_LINK_CTA = 'RESULTS_LINK_CTA';
export const START_TEST_BUTTON = 'START_TEST_BUTTON';
export const START_TEST_MODAL_BUTTON = 'START_TEST_MODAL_BUTTON';
