/**
 * @fileoverview Set of constants to represent event names for logging
 */
export const APP_ERROR = 'app-error';
export const DEVICE_DETAILS = 'device-details';
export const DEVICE_FINGERPRINT = 'device-fingerprint';
export const JS_ERROR = 'js-error';
export const PERF = 'perf';
export const SECONDARY_PERF = 'secondaryPerf';
export const TEST_FAILURE = 'test-failure';
export const TEST_SUCCESS = 'test-success';
export const UI_RENDER_ERROR = 'ui-render-error';
