import React, { HTMLAttributes } from 'react';

import ErrorGeneralContent from '../../components/ErrorGeneralContent/ErrorGeneralContent';
import ErrorSwitchTabContent from '../../components/ErrorSwitchTabContent/ErrorSwitchTabContent';
import { GENERAL, TAB_BACKGROUNDED } from '../../api/constants/error-types';
import { requestAppReload as requestAppReloadAction } from '../../api/actions/navigation';

export type ErrorType = typeof GENERAL | typeof TAB_BACKGROUNDED;

interface Props extends HTMLAttributes<HTMLDivElement> {
  errorType: ErrorType;
  requestAppReload: Dispatch<typeof requestAppReloadAction>;
}

const ErrorPage = ({ errorType, requestAppReload }: Props) => {
  const errorContent = {
    [GENERAL]: <ErrorGeneralContent requestAppReload={requestAppReload} />,
    [TAB_BACKGROUNDED]: (
      <ErrorSwitchTabContent requestAppReload={requestAppReload} />
    ),
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      {errorContent[errorType]}
    </div>
  );
};

export default ErrorPage;
