import React, { AnchorHTMLAttributes } from 'react';
import { requestRoute as requestRouteAction } from '../../api/speedtest-interface';

export interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  requestRoute?: Dispatch<typeof requestRouteAction>;
  href?: string;
}

const _isModifiedWithKeyEvent = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
};

const _isEventNotPrevented = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  linkProps: Props
) => {
  return (
    event.button === 0 && // ignore everything but left clicks
    !event.defaultPrevented && // onClick prevented default
    !linkProps.target && // let browser handle "target=_blank" etc.
    !_isModifiedWithKeyEvent(event) // ignore clicks with modifier keys
  );
};

const _onClick = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  linkProps: Props
) => {
  if (linkProps.onClick) linkProps.onClick(event); // execute custom onClick if present
  if (
    linkProps.requestRoute &&
    linkProps.href &&
    _isEventNotPrevented(event, linkProps)
  ) {
    event.preventDefault();
    linkProps.requestRoute({ route: linkProps.href });
  }
};

export const Link = ({
  requestRoute,
  href = '#',
  onClick,
  ...props
}: Props) => {
  return (
    <a
      href={href}
      onClick={event =>
        _onClick(event, { requestRoute, href, onClick, ...props })
      }
      {...props}
    >
      {props.children}
    </a>
  );
};

export default Link;
