import React from 'react';
import uuid from 'uuid/v4';
import DetailedTipsCarousel from '../../components/DetailedTipsCarousel/DetailedTipsCarousel';
import DetailedTipsCard from '../../components/DetailedTipsCard/DetailedTipsCard';
import NoDragImage from '../../components/NoDragImage/NoDragImage';
import bringCloserImage from '../../components/DetailedTipsCarousel/images/bring-closer-smaller.gif';
import clearWayImage from '../../components/DetailedTipsCarousel/images/clear_way.png';
import avoidInterferenceImage from '../../components/DetailedTipsCarousel/images/avoid_interference.png';
import restartGatewayImage from '../../components/DetailedTipsCarousel/images/restart_gateway.svg';
import relocateGatewayImage from '../../components/DetailedTipsCarousel/images/relocate_gateway.png';
import troubleshootingToolImage from '../../components/DetailedTipsCarousel/images/troubleshooting_tool.svg';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import XdsButton from '../../components/XdsButton/XdsButton';
import Localize from '../../components/Localize/Localize';
import { IMPROVE_SPEED } from '../../api/constants/bids';
import { requestTest as requestTestAction } from '../../api/actions/request-test';
import { useLocalization } from '../../hooks/localization';

interface Props {
  requestTest: Dispatch<typeof requestTestAction>;
}

const ImproveSpeed = ({ requestTest }: Props) => {
  const t = useLocalization();
  const improveSpeedTips = [
    {
      copy: t('improveSpeed_firstSection_body'),
      heading: t('improveSpeed_firstSection_header'),
      id: uuid(),
      image: <NoDragImage className="w-full" src={bringCloserImage} />,
    },
    {
      copy: t('improveSpeed_secondSection_body'),
      heading: t('improveSpeed_secondSection_header'),
      id: uuid(),
      image: <NoDragImage className="w-full" src={clearWayImage} />,
    },
    {
      copy: t('improveSpeed_thirdSection_body'),
      heading: t('improveSpeed_thirdSection_header'),
      id: uuid(),
      image: <NoDragImage className="w-full" src={avoidInterferenceImage} />,
    },
    {
      copy: t('improveSpeed_fourthSection_body'),
      heading: t('improveSpeed_fourthSection_header'),
      id: uuid(),
      image: <NoDragImage className="w-full" src={restartGatewayImage} />,
    },
    {
      copy: <Localize entry={t('improveSpeed_fifthSection_body')} />,
      heading: t('improveSpeed_fifthSection_header'),
      id: uuid(),
      image: <NoDragImage className="w-full" src={relocateGatewayImage} />,
    },
    {
      copy: (
        <React.Fragment>
          <p>{t('improveSpeed_sixthSection_body')}</p>
          <ExternalLink
            className="link"
            href="https://www.xfinity.com/support/repair/internet"
          >
            {t('improveSpeed_sixthSection_troubleshootingLink')}
          </ExternalLink>
        </React.Fragment>
      ),
      heading: t('improveSpeed_sixthSection_header'),
      id: uuid(),
      image: <NoDragImage className="w-full" src={troubleshootingToolImage} />,
    },
  ];

  return (
    <div className="container px-6 my-6 md:px-4" data-bid={IMPROVE_SPEED}>
      <DetailedTipsCarousel>
        {improveSpeedTips.map(tip => (
          <DetailedTipsCard key={tip.heading} {...tip} />
        ))}
      </DetailedTipsCarousel>
      <div className="mt-2 text-center">
        <XdsButton
          tone="theme1"
          onClick={() => requestTest({ useAdvancedSettingsData: false })}
        >
          {t('improveSpeed_retestSpeed')}
        </XdsButton>
      </div>
    </div>
  );
};

export default ImproveSpeed;
