// This index file let us do naming import for containers directly
// from `containers` folder, avoiding separate imports for every container
export {
  default as AccessibilityAnnouncerContainer,
} from './AccessibilityAnnouncer';
export { default as CompareByCategoryContainer } from './CompareByCategory';
export { default as DeviceVsPlanSpeedContainer } from './DeviceVsPlanSpeed';
export { default as ErrorContainer } from './Error';
export { default as ErrorBoundaryContainer } from './ErrorBoundary';
export { default as HeaderContainer } from './Header';
export { default as HomeContainer } from './Home';
export { default as ImproveSpeedContainer } from './ImproveSpeed';
export { default as KnownDeviceContainer } from './KnownDevice';
export { default as NonSpeedProblemsContainer } from './NonSpeedProblems';
export { default as ResultsContainer } from './Results';
export { default as TipsAndToolsContainer } from './TipsAndTools';
