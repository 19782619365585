import React from 'react';

import XdsButton from '../XdsButton/XdsButton';
import { requestAppReload as requestAppReloadAction } from '../../api/actions/navigation';
import { useLocalization } from '../../hooks/localization';

interface Props {
  requestAppReload: Dispatch<typeof requestAppReloadAction>;
}

const ErrorGeneralContent = ({ requestAppReload }: Props) => {
  const t = useLocalization();

  return (
    <div className="text-center">
      <h2 className="font-500 text-headline1 md:font-300">
        {t('error_general_header')}
      </h2>
      <p className="pt-8 pb-10 text-body1 text-neutral2-base md:m-auto">
        {t('error_general_body')}
      </p>
      <XdsButton
        tone="theme1"
        onClick={() => requestAppReload()}
      >
        {t('error_restartTest')}
      </XdsButton>
    </div>
  );
};

export default ErrorGeneralContent;
