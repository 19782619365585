import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { KNOWN_DEVICE_CONTENT } from '../../api/constants/bids';
import { ROUTES } from '../../api/constants/routes';
import DeviceSearch from '../../components/DeviceSearch/DeviceSearch';
import KnownDeviceContent from '../../components/KnownDevice/KnownDevice';
import Link from '../../components/Link/Link';
import { fetchDeviceSuggestionsStart as fetchDeviceSuggestionsStartAction } from '../../api/actions/device-suggestions';
import {
  blurDeviceSearchInput as blurDeviceSearchInputAction,
  focusDeviceSearchInput as focusDeviceSearchInputAction,
  hideDeviceSearch as hideDeviceSearchAction,
  showDeviceSearch as showDeviceSearchAction,
  setDeviceSearchInputValue as setDeviceSearchInputValueAction,
} from '../../api/actions/ui';
import { requestRoute as requestRouteAction } from '../../api/actions/navigation';
import { useLocalization } from '../../hooks/localization';
interface Props {
  deviceDetails: {
    displayName: string;
    downloadSpeed: string;
    formFactor: string;
    id: string;
    similarDevices: {
      maxDownloadSpeed: string;
      title: string;
      devices: {
        displayName: string;
        downloadSpeed: string;
        formFactor: string;
        id: string;
      }[];
    };
  };
  deviceSearchInputValue: string;
  deviceSearchIsActive: boolean;
  deviceSearchUIState?: string;
  deviceSuggestions: { displayName: string; id: string }[];
  deviceSuggestionsAreVisible: boolean;
  isSearchHintVisible: boolean;
  knownDeviceId: string;
  planSpeed: string;
  blurDeviceSearchInput: Dispatch<typeof blurDeviceSearchInputAction>;
  focusDeviceSearchInput: Dispatch<typeof focusDeviceSearchInputAction>;
  fetchDeviceSuggestionsStart: Dispatch<
    typeof fetchDeviceSuggestionsStartAction
  >;
  hideDeviceSearch: Dispatch<typeof hideDeviceSearchAction>;
  requestRoute: Dispatch<typeof requestRouteAction>;
  setDeviceSearchInputValue: Dispatch<typeof setDeviceSearchInputValueAction>;
  showDeviceSearch: Dispatch<typeof showDeviceSearchAction>;
}

const KnownDevicePage = ({
  blurDeviceSearchInput,
  deviceDetails,
  deviceSearchInputValue,
  deviceSearchIsActive,
  deviceSearchUIState,
  deviceSuggestions,
  deviceSuggestionsAreVisible,
  fetchDeviceSuggestionsStart,
  focusDeviceSearchInput,
  hideDeviceSearch,
  isSearchHintVisible,
  knownDeviceId,
  planSpeed,
  requestRoute,
  setDeviceSearchInputValue,
  showDeviceSearch,
}: Props) => {
  if (isEmpty(deviceDetails)) {
    return null;
  }
  const t = useLocalization();
  const compareByCategoryLink = () => (
    <Link
      className="mt-4 link text-body1"
      onClick={() => {
        setDeviceSearchInputValue('');
        hideDeviceSearch();
      }}
      href={ROUTES.COMPARE_BY_CATEGORY}
    >
      {t('knownDevice_lookup')}
    </Link>
  );

  return (
    <>
      <DeviceSearch
        blurDeviceSearchInput={blurDeviceSearchInput}
        compareByCategoryLink={compareByCategoryLink}
        deviceSearchId="device-search-autocomplete"
        deviceSearchInputValue={deviceSearchInputValue}
        deviceSearchIsActive={deviceSearchIsActive}
        deviceSearchUIState={deviceSearchUIState}
        deviceSuggestions={deviceSuggestions}
        deviceSuggestionsAreVisible={deviceSuggestionsAreVisible}
        fetchDeviceSuggestionsStart={fetchDeviceSuggestionsStart}
        focusDeviceSearchInput={focusDeviceSearchInput}
        hideDeviceSearch={hideDeviceSearch}
        isSearchHintVisible={isSearchHintVisible}
        requestRoute={requestRoute}
        setDeviceSearchInputValue={setDeviceSearchInputValue}
        showDeviceSearch={showDeviceSearch}
      />

      {!deviceSearchIsActive && (
        <div className="container mt-2 md:px-4" data-bid={KNOWN_DEVICE_CONTENT}>
          <KnownDeviceContent
            name={deviceDetails.displayName}
            maxSpeed={deviceDetails.downloadSpeed}
            type={deviceDetails.formFactor}
            similarDevices={deviceDetails.similarDevices}
            planSpeed={planSpeed}
            isYourDevice={deviceDetails.id === knownDeviceId}
            requestRoute={requestRoute}
          />
        </div>
      )}
    </>
  );
};

export default KnownDevicePage;
