import React, { HTMLAttributes, ReactNode } from 'react';
import classnames from 'classnames';
import { ReactComponent as Chevron } from './icons/icon-arrow-right-fillable.svg';
import MediaObject, {
  Props as MediaObjectProps,
} from '../MediaObject/MediaObject';

type Props = MediaObjectProps &
  Omit<HTMLAttributes<HTMLButtonElement>, 'title'> & {
    /**
     * A custom JSX icon (if you provide it), nothing if ActionIcon={null || true || false}, otherwise it
     * will default to `<Chevron />` in both cases will render at the far right of the MediaObjectButton.
     */
    ActionIcon?: ReactNode;
    /**
     * Preset sizes for the link and padding. Defaults to 'medium'
     */
    size?: 'small' | 'medium';
  };

const chevron = (
  <Chevron className="flex-shrink-0 p-1 fill-current w-25 h-25 text-neutral2-base" />
);

/* istanbul ignore next */
const MediaObjectButton = ({
  className: parentClassNames,
  children,
  headingLevel = 3,
  Icon,
  title,
  description,
  ActionIcon = chevron,
  size = 'medium',
  ...props
}: Props) => (
  <button
    className={classnames(
      parentClassNames,
      'text-left flex items-center cursor-pointer w-full',
      {
        'p-6': size === 'medium',
        'p-4': size === 'small',
      }
    )}
    {...props}
  >
    <MediaObject
      className="flex-1"
      Icon={Icon}
      title={title}
      description={description}
      headingLevel={headingLevel}
    >
      {children}
    </MediaObject>
    {!!ActionIcon && ActionIcon}
  </button>
);

export default MediaObjectButton;
