import * as ACTION_TYPES from '../../api/constants/action-types';
import * as PERF_MARKS from '../../api/constants/performance-marks';

const dataCallActionsToMarks = new Map<
  UnionOf<typeof ACTION_TYPES>,
  UnionOf<typeof PERF_MARKS>
>();

dataCallActionsToMarks.set(
  ACTION_TYPES.DEVICE_DETAILS_BY_FINGERPRINT_FETCH_STARTED,
  PERF_MARKS.DEVICE_DETAILS_BY_FINGERPRINT_FETCH_STARTED_MARK
);

dataCallActionsToMarks.set(
  ACTION_TYPES.DEVICE_DETAILS_BY_FINGERPRINT_FETCH_COMPLETED,
  PERF_MARKS.DEVICE_DETAILS_BY_FINGERPRINT_FETCH_COMPLETED_MARK
);

dataCallActionsToMarks.set(
  ACTION_TYPES.DEVICE_FINGERPRINT_FETCH_STARTED,
  PERF_MARKS.DEVICE_FINGERPRINT_FETCH_STARTED_MARK
);

dataCallActionsToMarks.set(
  ACTION_TYPES.DEVICE_FINGERPRINT_FETCH_COMPLETED,
  PERF_MARKS.DEVICE_FINGERPRINT_FETCH_COMPLETED_MARK
);
dataCallActionsToMarks.set(
  ACTION_TYPES.DEVICE_FINGERPRINT_POST_STARTED,
  PERF_MARKS.DEVICE_FINGERPRINT_POST_STARTED_MARK
);

dataCallActionsToMarks.set(
  ACTION_TYPES.DEVICE_FINGERPRINT_POST_COMPLETED,
  PERF_MARKS.DEVICE_FINGERPRINT_POST_COMPLETED_MARK
);
dataCallActionsToMarks.set(
  ACTION_TYPES.LATENCYS_FETCH_COMPLETED,
  PERF_MARKS.LATENCYS_FETCH_COMPLETED_MARK
);

dataCallActionsToMarks.set(
  ACTION_TYPES.LATENCYS_FETCH_STARTED,
  PERF_MARKS.LATENCYS_FETCH_STARTED_MARK
);

dataCallActionsToMarks.set(
  ACTION_TYPES.SESSION_ID_FETCH_STARTED,
  PERF_MARKS.SESSION_ID_FETCH_STARTED_MARK
);

dataCallActionsToMarks.set(
  ACTION_TYPES.SESSION_ID_FETCH_COMPLETED,
  PERF_MARKS.SESSION_ID_FETCH_COMPLETED_MARK
);

dataCallActionsToMarks.set(
  ACTION_TYPES.TEST_PLANS_FETCH_STARTED,
  PERF_MARKS.TEST_PLANS_FETCH_STARTED_MARK
);

dataCallActionsToMarks.set(
  ACTION_TYPES.TEST_PLANS_FETCH_COMPLETED,
  PERF_MARKS.TEST_PLANS_FETCH_COMPLETED_MARK
);

export default dataCallActionsToMarks;
