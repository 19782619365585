/**
 * @fileoverview Logs speed test(download, latency, upload) failures and successes ENCOUNTERED
 * BY END USERS, primarily in order to provide additional operational data in the form of
 * Platform Experience Metrics(PEM) that can be used for Triage.
 * Requires the `speedTestContext` as Epic middleware dependencies.
 */
// Third-party libraries
import { of, merge, zip, Observable } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  partition,
  withLatestFrom,
} from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
// Our code
import * as LOG_EVENTS from '../constants/log-events';
import {
  LATENCY_TEST_COMPLETED,
  TEST_COMPLETED,
  UPLOAD_TEST_COMPLETED,
} from '../constants/action-types';
import { serializeError } from '../helpers/error';
import { getTestId } from '../helpers/state-getters';
import { isTestingScreenDisplayed } from '../helpers/test-state';
import { trackMetrics } from '../actions/logger';
import { RootState, RootAction, EpicDependencies } from '../store-types';

const TEST_NAMES = {
  DOWNLOAD_TEST: 'Download',
  LATENCY_TEST: 'Latency',
  UPLOAD_TEST: 'Upload',
} as const;
const COMPLETION_ACTION_TO_TEST_NAME = {
  [TEST_COMPLETED]: TEST_NAMES.DOWNLOAD_TEST,
  [LATENCY_TEST_COMPLETED]: TEST_NAMES.LATENCY_TEST,
  [UPLOAD_TEST_COMPLETED]: TEST_NAMES.UPLOAD_TEST,
} as const;

type CompletionActionTypes = keyof typeof COMPLETION_ACTION_TO_TEST_NAME;

/**
 * Logs test failures and successes based on the provided failure and success
 * `[action, speedTestState]` streams.
 * NOTE: Expects Speed Test slice of state, not full app state.
 *
 * @function
 * @param {Object} failureScenarioAction$ Observable stream of test success `[action, speedTestState]`
 * @param {Object} successScenarioAction$ Observable stream of test failure `[action, speedTestState]`
 * @returns {Object} Observable stream
 */
const logTestCompletionScenarios = (
  failureScenario$: Observable<any>,
  successScenario$: Observable<any>
) => {
  return merge(
    failureScenario$.pipe(
      map(([action, speedTestState]) =>
        trackMetrics(
          LOG_EVENTS.TEST_FAILURE,
          {
            error: serializeError(action.payload),
            name:
              COMPLETION_ACTION_TO_TEST_NAME[
                action.type as CompletionActionTypes
              ],
            testId: getTestId(speedTestState),
          },
          action.type
        )
      )
    ),
    successScenario$.pipe(
      map(([action, speedTestState]) =>
        trackMetrics(
          LOG_EVENTS.TEST_SUCCESS,
          {
            name:
              COMPLETION_ACTION_TO_TEST_NAME[
                action.type as CompletionActionTypes
              ],
            payload: action.payload,
            testId: getTestId(speedTestState),
          },
          action.type
        )
      )
    )
  );
};

/**
 * Logs download test failures and successes ENCOUNTERED BY END USERS, primarily in order
 * to provide additional operational data in the form of Platform Experience Metrics(PEM).
 * NOTE: Only logs download tests that complete while the user is still on the testing
 * screen because download tests that complete in the background are never seen by the user.
 *
 * @function
 * @param {Object} action$ Observable stream of actions
 * @param {Object} state$ Observable stream of state changes
 * @param {Object} dependencies Injected dependencies
 * @param {Object} dependencies.speedTestContext Interface to work with ST within context
 * of full app
 * @returns {Object} Observable stream of actions
 */
export const logDownloadTestUserCompletionEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  const actionAndState$ = action$.pipe(
    ofType(TEST_COMPLETED),
    withLatestFrom(state$),
    filter(([, state]) =>
      isTestingScreenDisplayed(
        speedTestContext.getState(state),
        speedTestContext.getRoute(state),
        speedTestContext.rootRoute
      )
    ),
    map(([action, state]) => [action, speedTestContext.getState(state)])
  );
  const [failureScenario$, successScenario$] = partition(
    ([action]) => action.error
  )(actionAndState$);

  return logTestCompletionScenarios(failureScenario$, successScenario$);
};

/**
 * Logs latency and upload test failures and successes encountered by end users in order to provide
 * additional operational data in the form of Platform Experience Metrics(PEM).  To avoid logging
 * during the Upload speed test, both logs are sent only after the Upload test completes.
 *
 * TODO: If the logger is updated to automatically pause logging during speed tests, simplify
 * this Epic so it logs the the Latency and Upload test completions independently (i.e, not zipped).
 *
 * @function
 * @param {Object} action$ Observable stream of actions
 * @param {Object} state$ Observable stream of state changes
 * @param {Object} dependencies Injected dependencies
 * @param {Object} dependencies.speedTestContext Interface to work with ST within context
 * of full app
 * @returns {Object} Observable stream of actions
 */
export const logLatencyAndUploadTestCompletionEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return zip(
    action$.pipe(
      ofType(LATENCY_TEST_COMPLETED),
      withLatestFrom(state$),
      map(([action, state]) => [action, speedTestContext.getState(state)])
    ),
    action$.pipe(
      ofType(UPLOAD_TEST_COMPLETED),
      withLatestFrom(state$),
      map(([action, state]) => [action, speedTestContext.getState(state)])
    )
  ).pipe(
    mergeMap(latencyAndUploadTestCompletionsWithState => {
      const [
        latencyTestCompletionScenarios,
        uploadTestCompletionScenarios,
      ] = latencyAndUploadTestCompletionsWithState.map(
        testCompletionWithState => {
          // partition is not fully typed as a pipeable operator.
          // workaround: https://github.com/ReactiveX/rxjs/issues/2995#issuecomment-368202775
          const testCompletionWithState$ = of(testCompletionWithState);
          return partition(([action]) => action.error)(
            testCompletionWithState$
          );
        }
      );
      const [
        latencyFailureScenario$,
        latencySuccessScenario$,
      ] = latencyTestCompletionScenarios;
      const [
        uploadFailureScenario$,
        uploadSuccessScenario$,
      ] = uploadTestCompletionScenarios;

      return merge(
        logTestCompletionScenarios(
          latencyFailureScenario$,
          latencySuccessScenario$
        ),
        logTestCompletionScenarios(
          uploadFailureScenario$,
          uploadSuccessScenario$
        )
      );
    })
  );
};

export default {
  logDownloadTestUserCompletionEpic,
  logLatencyAndUploadTestCompletionEpic,
};
