// Third-party libraries
import { ofType, Epic } from 'redux-observable';
import { Observable, Observer, merge } from 'rxjs';
import {
  filter,
  map,
  mapTo,
  mergeMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import get from 'lodash/get';
import 'speed-testjs/public/lib/latencyHttpTest';
import 'speed-testjs/public/lib/latencyWebSocketTest';
import 'speed-testjs/public/lib/webSocket';
// Our code
import { injectSecureBaseUrl } from './test-helpers';
import {
  HTTP_LATENCY_TEST_STARTED,
  LATENCY_TEST_STARTED,
  WS_LATENCY_TEST_COMPLETED,
  WS_LATENCY_TEST_STARTED,
  HTTP_LATENCY_TEST_COMPLETED,
  REQUEST_LATENCY_TEST,
} from '../constants/action-types';
import { READY } from '../constants/statuses';
import {
  httpLatencyTestComplete,
  httpLatencyTestStart,
  latencyTestComplete,
  wsLatencyTestComplete,
  wsLatencyTestStart,
  latencyTestStart,
} from '../actions/latency-test';
import { RootState, RootAction, EpicDependencies } from '../store-types';

declare global {
  interface Window {
    // From speed-testjs
    latencyHttpTest: any;
    latencyWebSocketTest: any;
  }
}

export type HttpLatencyTestResult = {
  bandwidth: number;
  id: number;
  loaded: number;
  time: number;
}[];

export type WsLatencyTestResult = {
  time: number;
  unit: string;
}[];

/**
 * Takes an array of objects with the property `time` and returns
 * the smallest value for `time` rounded to a whole value.
 * @param {Object[]} arrOfObjs
 * @returns {Object}
 */
export const extractMinTimeAndRound = (arrOfObjs: { time: number }[]) => {
  const minTime = arrOfObjs.reduce((acc, currValue) => {
    return currValue.time < acc ? currValue.time : acc;
  }, arrOfObjs[0].time); // Initial value is the "time" of the first object in the array

  return Math.round(minTime);
};

/**
 * `runHTTPLatencyTestEpic` and `runWSLatencyTestEpic`, both listens for the
 * action signaling that a latency test should be started using HTTP or WebSockets.
 * They get the values from state that are necessary to configure the test. They
 * start the test with a constructor function provided by Speed-testJS and turn
 * the returned instance into an Observable/event stream. That instance's
 * completion and error callbacks are mapped to action creators that will
 * signal a successful completion or an error respectively.
 * Speed-testJS's `latencyWebSocketTest`, unlike `latencyHttpTest`,
 * also accepts a callback for on error and on timeout.
 */

export const runHTTPLatencyTestEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext, window }) => {
  return action$.pipe(
    ofType(HTTP_LATENCY_TEST_STARTED),
    withLatestFrom(state$, (action, state) => state),
    mergeMap(
      (state): Observable<RootAction> => {
        const speedTestState = speedTestContext.getState(state);
        const appConfig = speedTestContext.getAppConfig(state);
        /**
         * The url used by Speed-testJS to test latency speed is the same
         * url used when we load the app to determine if the browser
         * supports IPv6, `.../api/latencys`.
         */
        const latencyUrl = injectSecureBaseUrl(
          get(appConfig, 'endpoints.LATENCYS_ENDPOINT'),
          get(speedTestState, 'advancedSettings'),
          get(speedTestState, 'test.plans'),
          get(speedTestState, 'network.clientHasIPv6')
        );

        /**
         * For more information on `latencyHttpTest`, see their source code here:
         * https://github.com/Comcast/Speed-testJS/blob/master/public/lib/latencyHttpTest
         */
        return Observable.create((observer: Observer<RootAction>) => {
          const httpLatencyTest = new window.latencyHttpTest(
            latencyUrl, // the url that will be passed to the native WebSocket constructor
            10, // the number of times an http request will be made
            get(speedTestState, 'test.config.LATENCY_TIMEOUT'),
            // on complete callback
            (result: HttpLatencyTestResult) => {
              /**
               * The `result` passed to the on complete callback is an array containing each
               * intermediary `result` that was passed to the on progress callback. Here's an
               * example of the intermediary `result`
               * {
               *   bandwidth: 0.004825355047371105
               *   id: 4
               *   loaded: 55
               *   time: 91.18500000113272
               * }
               */
              observer.next(httpLatencyTestComplete(null, result));
              observer.complete();
            },
            // on progress callback
            (/* result */) => {
              /**
               * Unlike the download and upload tests, we aren't displaying intermediary
               * values for the latency test.
               */
            },
            // on abort callback
            (result: any) => {
              observer.next(httpLatencyTestComplete(result, null));
              observer.complete();
            },
            // on timeout callback
            (result: any) => {
              observer.next(httpLatencyTestComplete(result, null));
              observer.complete();
            },
            // on error callback
            (result: any) => {
              observer.next(httpLatencyTestComplete(result, null));
              observer.complete();
            }
          );

          httpLatencyTest.initiateTest();
        }).pipe(takeUntil(action$.pipe(ofType(speedTestContext.CHANGE_ROUTE))));
      }
    )
  );
};

export const runWSLatencyTestEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext, window }) => {
  return action$.pipe(
    ofType(WS_LATENCY_TEST_STARTED),
    withLatestFrom(state$, (action, state) => speedTestContext.getState(state)),
    mergeMap(
      ({ network, test }): Observable<RootAction> => {
        const latencyUrl = network.clientHasIPv6
          ? get(test, 'plans.webSocketUrlIPv6')
          : get(test, 'plans.webSocketUrlIPv4');
        /**
         * For more information on `latencyWebSocketTest`, see their source code here:
         * https://github.com/Comcast/Speed-testJS/blob/master/public/lib/latencyWebSocketTest
         */
        return Observable.create((observer: Observer<RootAction>) => {
          const wsLatencyTest = new window.latencyWebSocketTest(
            latencyUrl, // the url that will be passed to the native WebSocket constructor
            // speed-testJS doesn't actually use either of the following two arguments so these are just placeholders
            undefined,
            undefined,
            10, // the number of times the WebSocket will transmit data
            get(test, 'config.LATENCY_TIMEOUT'),
            // on complete callback
            (result: WsLatencyTestResult) => {
              /**
               * The `result` passed to the on complete callback is an array containing each
               * intermediary `result` that was passed to the on progress callback. Here's an
               * example of the intermediary `result`
               * {
               *   time: 91.18500000113272
               *   unit: 'ms'
               * }
               */
              observer.next(wsLatencyTestComplete(null, result));
              observer.complete();
            },
            // on progress callback
            (/* result */) => {
              /**
               * Unlike the download and upload tests, we aren't displaying intermediary
               * values for the latency test.
               */
            },
            // on error callback
            (result: any) => {
              observer.next(wsLatencyTestComplete(result, null));
              observer.complete();
            }
          );

          wsLatencyTest.initiateTest();
        }).pipe(takeUntil(action$.pipe(ofType(speedTestContext.CHANGE_ROUTE))));
      }
    )
  );
};

export const requestLatencyTest: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { speedTestContext }) => {
  return action$.pipe(
    ofType(REQUEST_LATENCY_TEST),
    withLatestFrom(state$, (action, state) => speedTestContext.getState(state)),
    filter((speedTestState: RootState) => {
      const isUploadComplete =
        speedTestState.test.statuses.uploadTest.status === READY;
      const isLatencyComplete =
        speedTestState.test.statuses.latencyTest.status === READY;

      // Only start the upload/latency tests if we haven't completed them yet
      return !isUploadComplete || !isLatencyComplete;
    }),
    mapTo(latencyTestStart())
  );
};

export const scheduleLatencyTest: Epic<
  RootAction,
  RootAction,
  RootState,
  EpicDependencies
> = (action$, state$, { window }) => {
  return action$.pipe(
    ofType(LATENCY_TEST_STARTED),
    map(() => {
      if ('WebSocket' in window || 'MozWebSocket' in window) {
        return wsLatencyTestStart();
      }

      return httpLatencyTestStart();
    })
  );
};

export const scheduleLatencyTestComplete: Epic<
  RootAction,
  RootAction
> = action$ => {
  /**
   * If a latency test is run with Web Sockets and there's an
   * error, we want to start a latency test using HTTP protocol.
   */
  const reschedulingScenario$ = action$.pipe(
    ofType(WS_LATENCY_TEST_COMPLETED),
    filter(({ error }) => Boolean(error)),
    mapTo(httpLatencyTestStart())
  );
  /**
   * If a latency test run with either Web Sockets or HTTP completes without an error,
   * we want to signal that the latency test is complete. The UI and IPIE both need a
   * "final" value. On completion, both of the Speed-testJS libraries we use to run tests
   * provide us with an array of objects containing the "time" of all the intermediary latency tests.
   * Unlike the upload and download tests, the "final" value needs to be calculated by us.
   * SpeedTest V1 simply returned the smallest "time" value, which is what we're doing here.
   */
  const successScenario$ = action$.pipe(
    ofType(WS_LATENCY_TEST_COMPLETED, HTTP_LATENCY_TEST_COMPLETED),
    filter(({ error }) => !error),
    map(
      (
        action:
          | ReturnType<typeof wsLatencyTestComplete>
          | ReturnType<typeof httpLatencyTestComplete>
      ) =>
        latencyTestComplete({
          error: false,
          payload: {
            min:
              action.payload instanceof Error
                ? null
                : // Narrowed in the filter
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  extractMinTimeAndRound(action.payload.result!),
          },
        })
    )
  );
  /**
   * When an HTTP test completes with an error, we pass that error along to `LATENCY_TEST_COMPLETED`.
   * Only an HTTP test can result in `LATENCY_TEST_COMPLETED` being dispatched with an error
   * since a failed Web Socket test will simply result in an HTTP test being run.
   */
  const failureScenario$ = action$.pipe(
    ofType(HTTP_LATENCY_TEST_COMPLETED),
    filter(({ error }) => Boolean(error)),
    map(action =>
      latencyTestComplete({
        payload: action.payload,
        error: action.error || false,
      })
    )
  );

  return merge(reschedulingScenario$, successScenario$, failureScenario$);
};
