import React, { useEffect, CSSProperties } from 'react';
import Autosuggest, {
  InputProps,
  OnSuggestionSelected,
  RenderSuggestionsContainerParams,
} from 'react-autosuggest';
import classnames from 'classnames';

import DeviceSearchInput from '../DeviceSearchInput/DeviceSearchInput';
import DeviceSearchMessage from '../DeviceSearchMessage/DeviceSearchMessage';
import { ROUTES } from '../../api/constants/routes';
import theme from './DeviceSearch.module.css';
import {
  DEVICE_SEARCH_INPUT_WRAPPER,
  DEVICE_SEARCH_SUGGESTION,
} from '../../api/constants/bids';
import {
  blurDeviceSearchInput as blurDeviceSearchInputAction,
  focusDeviceSearchInput as focusDeviceSearchInputAction,
  hideDeviceSearch as hideDeviceSearchAction,
  showDeviceSearch as showDeviceSearchAction,
  setDeviceSearchInputValue as setDeviceSearchInputValueAction,
} from '../../api/actions/ui';
import { fetchDeviceSuggestionsStart as fetchDeviceSuggestionsStartAction } from '../../api/actions/device-suggestions';
import { requestRoute as requestRouteAction } from '../../api/actions/navigation';
import { useLocalization } from '../../hooks/localization';

const KEYS = {
  ENTER: 'Enter',
  TAB: 'Tab',
};

export interface DeviceSuggestion {
  displayName: string;
  id: string;
}

interface Props {
  blurDeviceSearchInput: Dispatch<typeof blurDeviceSearchInputAction>;
  compareByCategoryLink: () => JSX.Element;
  deviceSearchId: string;
  deviceSearchInputValue: string;
  deviceSearchIsActive: boolean;
  deviceSearchUIState?: string;
  deviceSuggestions: DeviceSuggestion[];
  deviceSuggestionsAreVisible: boolean;
  fetchDeviceSuggestionsStart: Dispatch<
    typeof fetchDeviceSuggestionsStartAction
  >;
  focusDeviceSearchInput: Dispatch<typeof focusDeviceSearchInputAction>;
  hideDeviceSearch: Dispatch<typeof hideDeviceSearchAction>;
  isSearchHintVisible: boolean;
  requestRoute: Dispatch<typeof requestRouteAction>;
  setDeviceSearchInputValue: Dispatch<typeof setDeviceSearchInputValueAction>;
  showDeviceSearch: Dispatch<typeof showDeviceSearchAction>;
}

const DeviceSearch = ({
  blurDeviceSearchInput = () => {},
  compareByCategoryLink,
  deviceSearchId = 'device-search-autocomplete',
  deviceSearchInputValue = '',
  deviceSearchIsActive = false,
  deviceSearchUIState,
  deviceSuggestions = [],
  deviceSuggestionsAreVisible,
  fetchDeviceSuggestionsStart,
  focusDeviceSearchInput = () => {},
  hideDeviceSearch,
  isSearchHintVisible,
  requestRoute,
  setDeviceSearchInputValue,
  showDeviceSearch,
}: Props) => {
  const t = useLocalization();
  const DeviceSearchAutosuggest = Autosuggest as {
    new (): Autosuggest<DeviceSuggestion>;
  };
  useEffect(() => {
    focusDeviceSearchInput();
  }, []);
  const inputProps: InputProps<DeviceSuggestion> = {
    className: 'border-1 border-grey-8 p-4 rounded-small w-full text-body1',
    id: deviceSearchId,
    onChange: (event, { newValue }) => {
      if (!deviceSearchIsActive && newValue.length > 0) {
        showDeviceSearch();
      }
      setDeviceSearchInputValue(newValue);
    },
    onBlur: blurDeviceSearchInput,
    onFocus: () => {
      deviceSearchInputValue.length > 0 && showDeviceSearch();
    },
    onKeyDown: event => {
      switch (event.key) {
        case KEYS.ENTER: {
          if (!isSearchHintVisible) return;
          const firstDeviceSuggestion = deviceSuggestions[0];
          navigateToKnownDevice(firstDeviceSuggestion);
          break;
        }
        case KEYS.TAB:
          hideDeviceSearch();
          break;
        default:
          break;
      }
    },
    placeholder: t('compareByCategory_deviceSearch_placeholder'),
    value: deviceSearchInputValue,
  };

  const navigateToKnownDevice = (device: DeviceSuggestion) => {
    const knownDeviceRoute = ROUTES.KNOWN_DEVICE.replace(':id', device.id);

    requestRoute({ route: knownDeviceRoute });

    resetDeviceSearch();
  };

  const onSuggestionSelected: OnSuggestionSelected<DeviceSuggestion> = (
    event,
    { suggestion }
  ) => {
    navigateToKnownDevice(suggestion);
  };

  const resetDeviceSearch = () => {
    setDeviceSearchInputValue('');
    focusDeviceSearchInput();
    hideDeviceSearch();
  };

  useEffect(() => {
    const deviceSearchWrapper = document.querySelector(
      `#${deviceSearchId}-input-wrapper`
    );
    if (!deviceSearchWrapper) return;
    const originalContainer = deviceSearchWrapper.firstChild as HTMLElement;

    originalContainer.removeAttribute('role');
    originalContainer.removeAttribute('aria-haspopup');
    originalContainer.removeAttribute('aria-owns');
    originalContainer.removeAttribute('aria-expanded');
  });

  useEffect(() => {
    const deviceSearchContainer = document.querySelector(
      '#device-search-suggestions-container'
    );
    if (!deviceSearchContainer) return;
    const deviceSearchListbox = deviceSearchContainer.querySelector<
      HTMLElement
    >('[role="listbox"]');

    if (deviceSearchListbox) {
      deviceSearchListbox.setAttribute('id', deviceSearchId);
      deviceSearchListbox.setAttribute('tabindex', '-1');
    }
  }, [deviceSearchId]);

  return (
    <>
      <div
        className={classnames({
          'border-b-1 border-grey-5': deviceSearchIsActive,
        })}
      >
        <div className="container flex flex-wrap items-center p-4 md:py-6 lg:py-6">
          <div
            className="w-full"
            id={`${deviceSearchId}-input-wrapper`}
            data-bid={DEVICE_SEARCH_INPUT_WRAPPER}
          >
            <DeviceSearchAutosuggest
              inputProps={inputProps}
              getSuggestionValue={({ displayName }) => displayName}
              highlightFirstSuggestion={false}
              onSuggestionsClearRequested={() => {}}
              onSuggestionsFetchRequested={({ value }) => {
                fetchDeviceSuggestionsStart(value);
              }}
              onSuggestionSelected={onSuggestionSelected}
              renderInputComponent={inputProps => (
                <div className="relative">
                  <DeviceSearchInput
                    deviceSearchUIState={deviceSearchUIState}
                    deviceSuggestionsAreVisible={deviceSuggestionsAreVisible}
                    inputProps={inputProps}
                    setDeviceSearchInputValue={setDeviceSearchInputValue}
                  />
                  {isSearchHintVisible && (
                    <DeviceSearchHint
                      deviceSearchInputValue={deviceSearchInputValue}
                      hint={deviceSuggestions[0]}
                    />
                  )}
                </div>
              )}
              renderSuggestion={DeviceSearchSuggestion}
              renderSuggestionsContainer={DeviceSearchSuggestionsContainer}
              suggestions={deviceSuggestions}
              theme={theme}
            />
          </div>
        </div>
      </div>

      <span className="hidden" id={`instructions-${deviceSearchId}`}>
        {t('compareByCategory_deviceSearch_resultInstructions')}
      </span>

      {deviceSearchIsActive && (
        <DeviceSearchMessage
          compareByCategoryLink={compareByCategoryLink}
          deviceSearchUIState={deviceSearchUIState}
        />
      )}
    </>
  );
};

interface DeviceSuggestionProps {
  displayName: string;
}

const DeviceSearchSuggestion = ({ displayName }: DeviceSuggestionProps) => (
  <div
    className="px-4 py-6 border-b-1 border-grey-5"
    data-bid={`${DEVICE_SEARCH_SUGGESTION}-${
      displayName ? displayName.replace(/ /g, '_') : ''
    }`}
  >
    <span className="font-500 text-body2">{displayName}</span>
  </div>
);

interface DeviceSearchSuggestionsContainer
  extends RenderSuggestionsContainerParams {
  containerProps: RenderSuggestionsContainerParams['containerProps'] & {
    style: CSSProperties;
  };
}

const DeviceSearchSuggestionsContainer = ({
  children,
  containerProps,
}: DeviceSearchSuggestionsContainer) => {
  return (
    <div
      className="absolute w-full mt-4 border-transparent border-t-1 md:mt-6"
      ref={containerProps.ref}
      style={{ ...containerProps.style, left: 0 }}
    >
      <div
        className="container mw-auto"
        id="device-search-suggestions-container"
      >
        {children}
      </div>
    </div>
  );
};

export const DeviceSearchHint = ({
  deviceSearchInputValue,
  hint,
}: {
  deviceSearchInputValue: string;
  hint: { displayName: string };
}) => {
  const hintedValue = hint.displayName.substring(
    deviceSearchInputValue.length,
    hint.displayName.length
  );
  return (
    <div
      aria-hidden={true}
      className="absolute top-0 p-4 border-transparent border-1"
      tabIndex={-1}
    >
      <span className="invisible">{deviceSearchInputValue}</span>
      <span className="inline-block pr-1 whitespace-pre bg-grey-4">
        {hintedValue}
      </span>
    </div>
  );
};

export default DeviceSearch;
